import React from 'react';
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";

import '../css/style.css';

import Header from '../partials/Header';
import HeroHome from '../partials/HeroHome';
import FeaturesHome from '../partials/Features';
import FeaturesBlocks from '../partials/FeaturesBlocks';
import Testimonials from '../partials/Testimonials';
import Newsletter from '../partials/Newsletter';
import Footer from '../partials/Footer';
// import Banner from '../partials/Banner';



function Home() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">

      <Helmet>
				<title>{`Next Scene`}</title>
			</Helmet>

      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow">

        {/*  Page sections */}
        <HeroHome />
        <FeaturesHome />
        {/* <FeaturesBlocks /> */}
        {/*  <Testimonials /> */}

       
        {/* <Newsletter /> */}


      </main>

      {/* <Banner /> */}

      {/*  Site footer */}
      {/* <Footer /> */}
      {/* Copyrights note */}
      {/* Bottom area */}
      <div className="flex items-center text-center justify-center h-16 md:h-20">
          
          
            
        <div className='text-xl font-light'>
        Next Scene is currently in closed beta.
        
        </div>
  
        <div className='text-xl font-light ml-1.5 '>
         Don't have access yet?
        </div>

        
        <a href={`https://docs.google.com/forms/d/e/1FAIpQLSdSaW60eaY-kQUgbxPq7INgW_DndRGmJpBYpSTxAT2Mc5C_Qg/viewform`} target="_blank" className="text-white ml-1.5 p-1.5 pr-2 pl-2  bg-sky-600 hover:bg-sky-500 rounded-2xl">
                  <span>Join Waitlist</span>
                  
        </a>

        
       
      </div>
      
      <div className="md:flex md:items-center md:justify-between py-3 md:py-3 border-t border-gray-200">
      
        

        {/* Social links */}
       

        {/* Copyrights note */}
        <div className="text-md text-gray-600 mr-12 ml-auto"> © StoryWave 2023</div>


      </div>

    </div>
  );
}

export default Home;