import React, { Component } from 'react'
import { computed } from 'mobx'
import { observer, inject } from 'mobx-react'
import TextareaAutosize from 'react-textarea-autosize';
import DropdownMenu from './Dropdown'
import '../css/style-sheet.css'
import styled from 'styled-components'

import {
	FilmIcon,
} from '@heroicons/react/outline'


import TheatersOutlinedIcon from '@mui/icons-material/TheatersOutlined';
import MovieFilterIcon from '@mui/icons-material/MovieFilter';
import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import ListAltIcon from '@mui/icons-material/ListAlt';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';

import { Dropdown, Container, Col } from "@nextui-org/react";



const countWords = (str) => {
	let wordCount = 0;
	let match = str.match(/\b[-\w']+\b/gi);
	if (match) {
		wordCount = match.length;
	}
	return wordCount;
}

const tools = [
	{
		title: "Opening Scene",
		displayTitle: "Opening Scene",
		icon: TheatersOutlinedIcon,
		description: "Get started with your script"
	},
	{
		title: "Generate Scene",
		displayTitle: "Generate Scene",
		icon: MovieFilterIcon,
		description: "The next scene of your script"
	},
	{
		title: "Chapter to Scene",
		displayTitle: "Chapter to Scene",
		icon: AutoStoriesOutlinedIcon,
		description: "Convert prose into a scene"
	},
	{
		title: "Idea to Outline",
		displayTitle: "Outline",
		icon: FormatListBulletedIcon,
		description: "Generate a 3 act outline"
	},
	{
		title: "Idea to Beat Sheet",
		displayTitle: "Beat Sheet",
		icon: ListAltIcon,
		description: "Generate a beat sheet"
	},
	{
		title: "Character Arc",
		displayTitle: "Character Arc",
		icon: PeopleAltOutlinedIcon,
		description: "Explore a character in depth"
	},
	
/* 	{
		title: "Inline Feedback",
		displayTitle: "Inline Feedback",
	},
	{
		title: "Directorial Notes",
		displayTitle: "Directorial Notes",
	}, */


];





@inject("store")
@observer
class EntryPrompt extends Component {

	constructor(props) {
		super(props);
		this.state = { selectedKey: null,
					   isScreenWidthLessThan1280: window.innerWidth < 1280, 
		};
		this.setSelectedKey = this.setSelectedKey.bind(this);
		this.textareaRef = React.createRef();
		this.handleResize = this.handleResize.bind(this);

	}


	setSelectedKey(key) {
		console.log(key)
		this.props.store.selectedTool = key
		this.setState({ selectedKey: key });
	}


	onChange = async (e) => {
		const { prompt } = this.props;
		const value = e.target.value;

		this.setState({
			textareaValue: value
		});

		prompt.value = value;
		this.props.store.error = "";
		this.props.store.currentInput = value
	};


	handlePaste = async (e) => {
		this.props.store.currentInput = "";

	};



	handleFileUpload = (event) => {
		const file = event.target.files[0];
		const reader = new FileReader();
		reader.onload = (e) => {
			this.props.prompt.value = e.target.result;
		};
		reader.readAsText(file);
	};


	componentDidMount() {
		window.addEventListener('resize', this.handleResize);
	  }
	
	  componentWillUnmount() {
		window.removeEventListener('resize', this.handleResize);
	  }
	
	  handleResize() {
		this.setState({
			isScreenWidthLessThan1280: window.innerWidth < 1280,
		});
	  }
	


	@computed get currentNumberOfWords() {
		let maxWords = 1000;
		let currentWords = countWords(this.props.prompt.value);

		if (this.props.maxWords) {
			maxWords = this.props.maxWords
		}

		return { wordString: `${currentWords} / ${maxWords} words`, aboveMax: currentWords > maxWords }
	}

	@computed get toolList() {
		let keepers = []
		for (let i = 0; i < tools.length; i++) {
			if (tools[i].title !== this.props.title) {
				keepers.push(tools[i])
			}
		}
		return keepers


	}

	



	render() {

		let hidden = false

		this.props.store.toolInput = this.props.value

		const { isScreenWidthLessThan1280 } = this.state;


		return (
			<div className={`${isScreenWidthLessThan1280 ? 'mr-1' : 'mr-4'} bg-[#fafafc] text-gray-700 font-light align-bottom md:rounded-xl text-left overflow-hidden transform transition-all sm:align-middle transition  ${hidden ? "hidden" : ""}`}>

				<div className={`${isScreenWidthLessThan1280 ? 'mt-3' : 'mt-0'}`}>



				</div>

				<div className="entry-prompt px-1 py-1 mt-0 ">


					<div className="flex items-center">

						<div>
					
							<div className={`${isScreenWidthLessThan1280 ? 'mt-0' : 'mt-0 ml-2'}  text-left` }>
								<div  className= {`${isScreenWidthLessThan1280 ? 'text-md'  : 'text-2xl' }  font-light text-blue-900` } style={{ fontFamily: 'Bangers' }}>

									{ isScreenWidthLessThan1280 && this.props.promptMeta.title === "Chapter → Scene"  ?  "Chapt. → Scene" : this.props.promptMeta.title}

								</div>

							</div>
				
						</div>



						<div className="absolute mb-0.5 right-0">
							<Container>
								<Col >
									<Dropdown>
										<Dropdown.Button flat size="sm" color="gradient" auto ghost css={{ tt: "capitalize" }}>
											Switch Tool
										</Dropdown.Button>
										<Dropdown.Menu aria-label="Static Actions" color="primary"
											items={this.toolList}
											onAction={this.setSelectedKey}
										
										>
											{(item) => ( 
												
												
												<Dropdown.Item
												withDivider = {item.title === "Idea to Outline" ? true 	: item.title === "Idea to Beat Sheet" && this.toolList[2].title !== "Idea to Outline" && this.toolList[3].title !== "Idea to Outline" ? true : false}
													key={item.title}
													description={item.description}
													icon={
														<item.icon
														  size={22}
														  fill="var(--nextui-colors-secondary)"
														/>
													}
												>
													{item.title}
												</Dropdown.Item>
											
											)}
										
										</Dropdown.Menu>
									</Dropdown>
								</Col>
							</Container>
						</div>



					</div>


					<div className={`${this.props.size ? "mt-2" : "mt-2"}`}>

						{this.props.prompt.title && <label
							htmlFor={this.props.prompt.attr}
							className="text-gray-600 font-medium text-md">
							{this.props.prompt.title}
						</label>}

						<div className="relative flex flex-col">

							{this.props.prompt.options ? null : <div className={`text-xs absolute ${this.currentNumberOfWords.aboveMax ? "text-red-500" : "text-sky-700"}  font-bold z-70 bottom-0 left-1 select-none pointer-events-none transition-all top px-2 ""}`}>
								{this.currentNumberOfWords.wordString}
							</div>}



							{this.props.prompt.type === "textarea" && <TextareaAutosize
								ref={this.textareaRef}
								minRows={8.5}
								maxRows={8.5}
								name={this.props.prompt.attr}
								id={this.props.prompt.attr}
								{...this.props.prompt}
								value={this.props.value}
								className={`shadow-inner outline-none focus:outline-none text-${this.props.size || "md"} bg-white rounded-md ${this.props.size ? "px-2 py-2" : "px-4 py-4"} placeholder:text-[#7a8287] text-gray-800  font-regular min-w-full border-2 border-gray-500 font-regular focus:border-slate-500 focus:border ${this.props.store.error ? "border-red-400" : "border-gray-300"} font-regular mt-2 mb-5 `}
								onChange={this.onChange}
								onPaste={this.handlePaste}


							/>}
						</div>

						{(this.props.prompt.label || this.props.store.error) && <div
							htmlFor={this.props.prompt.attr}
							className={`${this.props.store.error ? "text-red-400" : "text-gray-400"} text-s transition-all line mb-1`}>
							{this.props.store.error || this.props.prompt.label}
						</div>}
					</div>
					{this.props.children}
				</div>
			</div>

		)
	}
}





export default EntryPrompt;