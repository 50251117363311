import styled, { css, keyframes } from 'styled-components';

export const Loader = ({ active, hidden, ...props }) => <Spinner active={active} {...props} >
<Bounce active={active} hidden={hidden} ></Bounce>
<BounceTwo active={active} hidden={hidden} ></BounceTwo>
<BounceThree active={active} hidden={hidden} ></BounceThree>
<BounceFour active={active} hidden={hidden} ></BounceFour>
</Spinner>

//   width: 2.5em;
// height: 2.5em;
const Spinner = styled.div`
  
    position: relative;
    display:inline-block;
    ${props => !props.active ? css`
      display:none;
    ` : null}
`;

const bounce = keyframes`
  0%, 100% {
    transform: scale(0.0) rotate(0deg);
    -webkit-transform: scale(0.0) rotate(0deg);
  }


  50% {
    transform: scale(1.0) rotate(180deg);
      -webkit-transform: scale(1.05) rotate(180deg);
  }
`;

const Bounce = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #8c10eb;
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-animation: ${bounce} 7s infinite ease-in-out;
    animation: ${bounce} 10s infinite ease-in-out;
`;

const BounceTwo = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 20%;
    background-color: #723ff2;
    opacity: 0.7;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-animation: ${bounce} 6.0s infinite ease-in-out;
    animation: ${bounce} 4s infinite ease-in-out;
    animation-delay: -8.0s;
`;

const BounceThree = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #e8fff5;
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-animation: ${bounce} 3.0s infinite ease-in-out;
    animation: ${bounce} 5s infinite ease-in-out;
    animation-delay: -7.0s;
`;

const BounceFour = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 35%;
    background-color: #3d84f6 ;
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-animation: ${bounce} 3.0s infinite ease-in-out;
    animation: ${bounce} 6s infinite ease-in-out;
    animation-delay: -6.0s;
`;


 

export default Loader