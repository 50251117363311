
import { observer, inject } from 'mobx-react'

import { $generateHtmlFromNodes, $generateNodesFromDOM } from '@lexical/html';
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  REDO_COMMAND,
  UNDO_COMMAND,
  SELECTION_CHANGE_COMMAND,

  FORMAT_ELEMENT_COMMAND,
  $getSelection,
  $isRangeSelection,
  $getRoot,
  CLEAR_HISTORY_COMMAND,
  COMMAND_PRIORITY_HIGH,
  SHORTCUTS_COMMAND
} from "lexical";
import { $isLinkNode, TOGGLE_LINK_COMMAND } from "@lexical/link";
import {
  $isParentElementRTL,
  $wrapNodes,
  $isAtNodeEnd
} from "@lexical/selection";
import { $getNearestNodeOfType, mergeRegister } from "@lexical/utils";
import {
  $isListNode,
  ListNode
} from "@lexical/list";
import {
  $createHeadingNode,
  $isHeadingNode,
  $createTextNode,
  $createParagraphNode,

} from "@lexical/rich-text";

import "../../css/lexical-scene.css";


import Tooltip from '@mui/material/Tooltip';

import CameraIcon from '@mui/icons-material/Camera';

import ViewListRoundedIcon from '@mui/icons-material/ViewListRounded';

import InterpreterModeIcon from '@mui/icons-material/InterpreterMode';

import UploadIcon from '@mui/icons-material/Upload';
import DownloadIcon from '@mui/icons-material/Download';

import LyricsIcon from '@mui/icons-material/Lyrics';


import styled from 'styled-components';

const GradientText = styled.span`
  background-image: linear-gradient(to left, #be2ad1, #8114fc);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;




const LowPriority = 1;


const classNameToHTag = {
  "editor-text-heading": "h1",
  "editor-text-action": "h2",
  "editor-text-character": "h3",
  "editor-text-parens": "h4",
  "editor-text-dialogue": "h5",
  "editor-text-transition": "h6"
}




function Divider() {
  return <div className="divider" />;
}

function getSelectedNode(selection) {
  const anchor = selection.anchor;
  const focus = selection.focus;
  const anchorNode = selection.anchor.getNode();
  const focusNode = selection.focus.getNode();
  if (anchorNode === focusNode) {
    return anchorNode;
  }
  const isBackward = selection.isBackward();
  if (isBackward) {
    return $isAtNodeEnd(focus) ? anchorNode : focusNode;
  } else {
    return $isAtNodeEnd(anchor) ? focusNode : anchorNode;
  }
}


function parseFinalDraftXML(xml) {
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(xml, "text/xml");
  const paragraphs = xmlDoc.getElementsByTagName("Paragraph");
  const result = [];

  for (let i = 0; i < paragraphs.length; i++) {
    const paragraph = paragraphs[i];
    const type = paragraph.getAttribute("Type");
    let text = getTextFromNode(paragraph);
    text = restoreXmlChars(text);
    let headingTag;
    if ((["Scene Heading","Action","Character", "Parenthetical", "Dialogue", "Transition", "New Act", "Shot" ].includes(type) ) && text !== "") { 
      if (type === "Scene Heading" || type === "New Act" || type === "Shot") {
      headingTag = "h1"
    } else if (type === "Action") {
      headingTag = "h2"
    } else if (type === "Character") {
      headingTag = "h3"
    } else if (type === "Parenthetical") {
      headingTag = "h4"
    } else if (type === "Dialogue") {
      headingTag = "h5"
    } else if (type === "Transition") {
      headingTag = "h6"
    }                                                                                                                               
      result.push({ headingTag, text });
    }
  }

  function getTextFromNode(node) {
    let text = "";
    for (let i = 0; i < node.childNodes.length; i++) {
      const child = node.childNodes[i];
      if (child.nodeType === Node.ELEMENT_NODE && child.tagName === "Text") {
        text += child.textContent.trim();
      } else {
        text += getTextFromNode(child);
      }
    }
    return text;
  }

  return result;
}


function convertToXml(arr) {
  let xml = `<?xml version="1.0" encoding="UTF-8"?>
  <FinalDraft DocumentType="Script" Template="No" Version="4">
    <Content>`;
  arr.forEach((element) => {
    xml += `
      <Paragraph Type="${element.type}">
        <Text>${element.text}</Text>
      </Paragraph>`;
  });
  xml += `
    </Content>
  </FinalDraft>`;
  return xml;
}

function replaceInvalidXmlChars(text) {
  return text.replace(/[<>&'"]/g, function(char) {
    switch (char) {
      case '<':
        return '&lt;';
      case '>':
        return '&gt;';
      case '&':
        return '&amp;';
      case "'":
        return '&apos;';
      case '"':
        return '&quot;';
      default:
        return char;
    }
  });
}


function restoreXmlChars(text) {
  return text.replace(/&lt;/g, '<')
             .replace(/&gt;/g, '>')
             .replace(/&amp;/g, '&')
             .replace(/&apos;/g, "'")
             .replace(/&quot;/g, '"');
}

const tagToLineType = {
  h1: "Scene Heading",
  h2 : "Action",
  h3: "Character",
  h4: "Parenthetical",
  h5: "Dialogue",
  h6: "Transition",
  }


export const ToolbarPlugin = inject('store')(observer(({store}) => {



  const [editor] = useLexicalComposerContext();
  const toolbarRef = useRef(null);
  const [canUndo, setCanUndo] = useState(false);
  const [canRedo, setCanRedo] = useState(false);
  const [blockType, setBlockType] = useState("paragraph");
  const [selectedElementKey, setSelectedElementKey] = useState(null);
  const [showBlockOptionsDropDown, setShowBlockOptionsDropDown] = useState(
    false
  );

  const [isRTL, setIsRTL] = useState(false);
  const [isLink, setIsLink] = useState(false);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [isStrikethrough, setIsStrikethrough] = useState(false);



  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      const element =
        anchorNode.getKey() === "root"
          ? anchorNode
          : anchorNode.getTopLevelElementOrThrow();
      const elementKey = element.getKey();
      const elementDOM = editor.getElementByKey(elementKey);
      if (elementDOM !== null) {
        setSelectedElementKey(elementKey);
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode);
          const type = parentList ? parentList.getTag() : element.getTag();
          setBlockType(type);
        } else {
          const type = $isHeadingNode(element)
            ? element.getTag()
            : element.getType();
          setBlockType(type);

        }
      }
      // Update text format
      setIsBold(selection.hasFormat("bold"));
      setIsItalic(selection.hasFormat("italic"));
      setIsUnderline(selection.hasFormat("underline"));
      setIsStrikethrough(selection.hasFormat("strikethrough"));
      setIsRTL($isParentElementRTL(selection));

      // Update links
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true);
      } else {
        setIsLink(false);
      }
    }
  }, [editor]);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        (_payload, newEditor) => {
          updateToolbar();
          return false;
        },
        LowPriority
      ),
      editor.registerCommand(
        CAN_UNDO_COMMAND,
        (payload) => {
          setCanUndo(payload);
          return false;
        },
        LowPriority
      ),
      editor.registerCommand(
        CAN_REDO_COMMAND,
        (payload) => {
          setCanRedo(payload);
          return false;
        },
        LowPriority
      )
    );
  }, [editor, updateToolbar]);



/*   const formatDialogue = ({store}) => {
    editor.update(() => {
   const selection = $getSelection();
      console.log(selection)
      const anchorNode = selection.anchor.getNode();
      anchorNode.setStyle('   font-family  : Arial  ;  color    :   red   ;top     : 50px'); 
      const selection = $getSelection();

      const focusNode = selection.focus.getNode();
      const element =
        focusNode.getKey() === "root"
          ? focusNode
          : focusNode.getTopLevelElementOrThrow();
      const elementKey = element.getKey();

      const elementDOM = editor.getElementByKey(elementKey);
      console.log(elementDOM.classList)
      elementDOM.classList.value = ''
      console.log(elementDOM.className)
      elementDOM.style.textAlign = '';
      elementDOM.classList.add('editor-text-dialogue', 'ltr');
      editor.focus();


    })
      store.currentScriptLineType = 'dialogue'
      console.log(store.currentScriptLineType)

  }

*/


const formatSceneHeadingLine = ({store}) => {
  if (blockType !== "h1") {
    editor.update(() => {
      const selection = $getSelection();

      let text = selection.getTextContent()

      if (text) {
        if (text[0] === "(" && text[text.length - 1] === ")") {
          text = text.substring(1, text.length - 1)
          selection.removeText()
          selection.insertText(text)

        }
      } else {
        const focusNode = selection.focus.getNode();
        const element =
          focusNode.getKey() === "root"
            ? focusNode
            : focusNode.getTopLevelElementOrThrow();
        const elementKey = element.getKey();

        const elementDOM = editor.getElementByKey(elementKey);

        text = elementDOM.textContent
        if (text) {

          if (text[0] === "(" && text[text.length - 1] === ")") {
            text = text.substring(1, text.length - 1)
            selection.anchor.offset = 0
            selection.focus.offset = text.length+2
            selection.deleteLine()
            selection.insertText(text)
          }


        }
      }
      if ($isRangeSelection(selection)) {
        $wrapNodes(selection, () => $createHeadingNode("h1"));
      }
    });
    store.currentScriptLineType = 'scene heading'
  }

};

const formatActionLine = ({store}) => {
  if (blockType !== "h2") {
    editor.update(() => {
      const selection = $getSelection();

      let text = selection.getTextContent()

      if (text) {
        if (text[0] === "(" && text[text.length - 1] === ")") {
          text = text.substring(1, text.length - 1)
          selection.removeText()
          selection.insertText(text)

        }
      } else {
        const focusNode = selection.focus.getNode();
        const element =
          focusNode.getKey() === "root"
            ? focusNode
            : focusNode.getTopLevelElementOrThrow();
        const elementKey = element.getKey();

        const elementDOM = editor.getElementByKey(elementKey);

        text = elementDOM.textContent
        if (text) {

          if (text[0] === "(" && text[text.length - 1] === ")") {
            text = text.substring(1, text.length - 1)
            selection.anchor.offset = 0
            selection.focus.offset = text.length+2
            selection.deleteLine()
            selection.insertText(text)
          }


        }
      }

      if ($isRangeSelection(selection)) {
        $wrapNodes(selection, () => $createHeadingNode("h2"));
      }
    });
    store.currentScriptLineType = 'action'
  }

};

const formatCharacterLine = ({store}) => {
  if (blockType !== "h3") {
    editor.update(() => {
      const selection = $getSelection();

      let text = selection.getTextContent()

      if (text) {
        if (text[0] === "(" && text[text.length - 1] === ")") {
          text = text.substring(1, text.length - 1)
          selection.removeText()
          selection.insertText(text)

        }
      } else {
        const focusNode = selection.focus.getNode();
        const element =
          focusNode.getKey() === "root"
            ? focusNode
            : focusNode.getTopLevelElementOrThrow();
        const elementKey = element.getKey();

        const elementDOM = editor.getElementByKey(elementKey);

        text = elementDOM.textContent
        if (text) {

          if (text[0] === "(" && text[text.length - 1] === ")") {
            text = text.substring(1, text.length - 1)
            selection.anchor.offset = 0
            selection.focus.offset = text.length+2
            selection.deleteLine()
            selection.insertText(text)
          }


        }
      }

      if ($isRangeSelection(selection)) {
        $wrapNodes(selection, () => $createHeadingNode("h3"));
      }
    });
    store.currentScriptLineType = 'character'
  }

};

const formatParensLine = ({store}) => {
  if (blockType !== "h4") {
    editor.update(() => {
      const selection = $getSelection();
      const focusNode = selection.focus.getNode();
      const element =
        focusNode.getKey() === "root"
          ? focusNode
          : focusNode.getTopLevelElementOrThrow();
      const elementKey = element.getKey();
      
      const elementDOM = editor.getElementByKey(elementKey);

      let text = selection.getTextContent()

      if (text) {
        //selection.anchor.offset = selection.anchor.offset - (selection.anchor.offset + 1);
        selection.removeText()
        console.log(selection.getTextContent())
        selection.insertText("(" + text + ")")
        console.log(selection.getTextContent())

      } else {
        const focusNode = selection.focus.getNode();
        const element =
          focusNode.getKey() === "root"
            ? focusNode
            : focusNode.getTopLevelElementOrThrow();
        const elementKey = element.getKey();

        const elementDOM = editor.getElementByKey(elementKey);

        text = elementDOM.textContent
        
        if (text) {
          
          selection.anchor.offset = 0
          selection.focus.offset = text.length
          
          selection.deleteLine()
          selection.insertText("(" + text + ")")
          
        } else {
          selection.insertText("()")
        }

      }

      $wrapNodes(selection, () => $createHeadingNode("h4"));

      selection.focus.offset = selection.focus.offset - 1;
      selection.anchor.offset = selection.anchor.offset - 1;

      
    });
    store.currentScriptLineType = 'parens'
  }

};




const formatDialogueLine = ({store}) => {
  if (blockType !== "h5") {
    editor.update(() => {
      const selection = $getSelection();

      let text = selection.getTextContent()

      if (text) {
        if (text[0] === "(" && text[text.length - 1] === ")") {
          text = text.substring(1, text.length - 1)
          selection.removeText()
          selection.insertText(text)

        }
      } else {
        const focusNode = selection.focus.getNode();
        const element =
          focusNode.getKey() === "root"
            ? focusNode
            : focusNode.getTopLevelElementOrThrow();
        const elementKey = element.getKey();

        const elementDOM = editor.getElementByKey(elementKey);

        text = elementDOM.textContent
        if (text) {

          if (text[0] === "(" && text[text.length - 1] === ")") {
            text = text.substring(1, text.length - 1)
            selection.anchor.offset = 0
            selection.focus.offset = text.length+2
            selection.deleteLine()
            selection.insertText(text)
          }


        }
      }

      if ($isRangeSelection(selection)) {
        $wrapNodes(selection, () => $createHeadingNode("h5"));
      }
    });
    store.currentScriptLineType = 'dialogue'
  }

};


const formatTransitionLine = ({store}) => {
  if (blockType !== "h6") {
    editor.update(() => {
      const selection = $getSelection();

      let text = selection.getTextContent()

      if (text) {
        if (text[0] === "(" && text[text.length - 1] === ")") {
          text = text.substring(1, text.length - 1)
          selection.removeText()
          selection.insertText(text)

        }
      } else {
        const focusNode = selection.focus.getNode();
        const element =
          focusNode.getKey() === "root"
            ? focusNode
            : focusNode.getTopLevelElementOrThrow();
        const elementKey = element.getKey();

        const elementDOM = editor.getElementByKey(elementKey);

        text = elementDOM.textContent
        if (text) {

          if (text[0] === "(" && text[text.length - 1] === ")") {
            text = text.substring(1, text.length - 1)
            selection.anchor.offset = 0
            selection.focus.offset = text.length+2
            selection.deleteLine()
            selection.insertText(text)
          }


        }
      }

      if ($isRangeSelection(selection)) {
        $wrapNodes(selection, () => $createHeadingNode("h6"));
      }
    });
    store.currentScriptLineType = 'transition'
  }

};





const importFile = ()  => {
  readTextFileFromSystem(text => {

   let linesArray = []
   for (let line of parseFinalDraftXML(text)) {
    linesArray.push({"children":[{"detail":0,"format":0,"mode":"normal","style":"","text":line["text"],"type":"text","version":1}],"direction":"ltr","format":"","indent":0,"type":"heading","version":1,"tag":line["headingTag"]})
   }
   
   const json = {"root":{"children":linesArray,"direction":"ltr","format":"","indent":0,"type":"root","version":1}}
  
    
    const editorState = editor.parseEditorState(
      JSON.stringify(json)
    )
    
    editor.setEditorState(editorState)
    
    editor.dispatchCommand(CLEAR_HISTORY_COMMAND, undefined) 
  })
}

function readTextFileFromSystem(callback) {
  const input = document.createElement("input")
  input.type = "file"
  input.accept = ".fdx"
  input.addEventListener("change", event => {
    const target = event.target

    if (target.files) {
      const file = target.files[0]
      const reader = new FileReader()
      reader.readAsText(file, "UTF-8")


      editor.focus()

      reader.onloadend = readerEvent => {
        if (readerEvent.target) {
          const content = readerEvent.target.result
          callback(content)
        }
      }
    }
  })
  input.click()
}




/* 
  const importScript = ({store}) => {

    const fileInput = document.createElement('input');
    fileInput.type = 'file';

    // Add an event listener to the file input
    fileInput.addEventListener('change', (event) => {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        const scriptContent = reader.result;
        // Set a variable to the file content
        console.log(scriptContent)
        editor.update(() => {
          
          store.scriptJSON = scriptContent
      
        });

      };
      reader.readAsText(file);
    });

    // Trigger the file input dialog
    fileInput.click();


  }
 */


  const exportScript = ({store}) => {

    editor.update(() => {


      const link = document.createElement('a');

      let title = store.scriptText.split('\n')[0]
      // limit title to 5 words
      title = title.split(" ").slice(0,5).join(" ")



     /*  console.log(editor.getEditorState().toJSON()["root"]["children"][0]["children"][0]["text"])
      if (editor.getEditorState().toJSON()["root"]["children"][1]["children"][0]) {
      console.log(editor.getEditorState().toJSON()["root"]["children"][1]["children"][0])
      }
      console.log(editor.getEditorState().toJSON()["root"]["children"][5].type) */


      let extractedLines = []
      let lineType = ""
      let lineText = ""
      
      for (let line of editor.getEditorState().toJSON()["root"]["children"]) {
        if (line.children[0]) {
          if (line.type === "paragraph") {
            lineType = "Action"
            lineText = line.children[0]["text"];
            lineText = replaceInvalidXmlChars(lineText)
          } else if (line.type === "heading") {
            lineType = tagToLineType[line.tag]
            lineText = line.children[0]["text"];
            lineText = replaceInvalidXmlChars(lineText)
          }

          extractedLines.push({"type":lineType, "text":lineText})
        
        } 

        

      } 

      const outputXML = convertToXml(extractedLines)

      link.setAttribute('download', title + ".fdx");
      link.setAttribute('href', 'data:text/xml;charset=utf-8,' + encodeURIComponent(outputXML));
      document.body.appendChild(link);

      // simulate a click on the link to trigger the download
      link.click();

      // remove the link from the DOM
      document.body.removeChild(link);


    });

  }






  /*   
      
      const alignHeadingsLeft = () => {
        const elements = document.querySelectorAll('editor-heading-h1');
        elements.forEach(element => {
          element.style.textAlign = 'left';
          
        });
        
      };
  
  
      const convertToParagraph = () => {
        const elements = document.querySelectorAll('h1');
        elements.forEach(element => {
          const textAlign = element.style.textAlign;
          if (textAlign === 'right' || textAlign === 'center') {
            element.classList.remove('editor-heading-h1');
            element.classList.add('editor-paragraph');
          }
        });
      };
   */

  const formatElement = (alignment) => {

    editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, alignment);
  };



  editor.registerCommand(
    SHORTCUTS_COMMAND,
    (event) => {
      event.preventDefault()
      console.log("123")
    },
    COMMAND_PRIORITY_HIGH,
  )


  const screenWidth = window.innerWidth;

  return (
    <>


      { !store.voiceMode ?

        <div className="toolbar" ref={toolbarRef}>

<div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", flexShrink:0, width: "100%"}}>


      <button
        onClick={() => {
          formatSceneHeadingLine({store});
          

        }}
        className={"toolbar-item spaced" + (store.currentScriptLineType === "scene heading" ? " active" : "")}
        aria-label="Scene Heading"
        
      >
        <span>&nbsp;</span>
        <i className="format scene-heading" />
        <span className="text pl-1">{store.showKeyboardCommands ? "⌘ 1" : "Scene"}</span>
      </button>


      <button
        onClick={() => {
          formatActionLine({store});

        }}
        className={"toolbar-item spaced" + (store.currentScriptLineType === "action" ? " active disabled" : "")}
        aria-label="Action"
       
      >
        <span>&nbsp;</span>
        <i className="format left-align-scene" />
        <span className="text pl-1">{store.showKeyboardCommands ? "⌘ 2" : "Action"}</span>
      </button>

      <button
        onClick={() => {
          formatCharacterLine({store});
        }}
        className={"toolbar-item spaced" + (store.currentScriptLineType === "character" ? " active" : "")}
        aria-label="Character"
      

      >
        <span>&nbsp;</span>
        <i className="format center-align-scene" />
        <span className="text pl-1">{store.showKeyboardCommands ? "⌘ 3" : "Character"}</span>
      </button>


      <button
        onClick={() => {
          formatParensLine({store});


        }}
        className={"toolbar-item spaced" + (store.currentScriptLineType === "parens" ? " active" : "")}
        aria-label="Paranthetical"
       
      >
        <span>&nbsp;</span>
        <i className="format parantheses" />
        <span className="text pl-1">{store.showKeyboardCommands ? "⌘ 4" : "Parens"}</span>
      </button>


      <button
        onClick={() => {

          formatDialogueLine({store});


        }}
        className={"toolbar-item spaced" + (store.currentScriptLineType === "dialogue" ? " active" : "")}
        aria-label="Dialogue"
      >
        <span>&nbsp;</span>
        <i className="format dialogue" />
        <span className="text pl-1">{store.showKeyboardCommands ? "⌘ 5" : "Dialogue"}</span>
      </button>

      <button
        onClick={() => {
          formatTransitionLine({store});
        }}
        className={"toolbar-item spaced" + (store.currentScriptLineType === "transition" ? " active" : "")}
        aria-label="Transition"
      >
        <span>&nbsp;</span>
        <i className="format right-align-scene" />
        <span className="text pl-1">{store.showKeyboardCommands ? "⌘ 6" : "Transition"}</span>
      </button>
      <Divider />

      <button style={{color:"#717478", paddingLeft: "2px", paddingRight: "2px"}}
        onClick={() => {
          importFile({store});

        }}
        className="toolbar-item format upload"
        aria-label="Import"
      >
     
     <Tooltip title="Import Final Draft script" >
        <UploadIcon style={{height:"22px", width: "22px", display: "flex", flex: 1,}} />
        </Tooltip>
      </button>{importFile}

      <button style={{color:"#717478", paddingLeft: "2px", paddingRight: "2px",}}
        onClick={() => {
          exportScript({store});

        }}
        className="toolbar-item format download"
        aria-label="Export"
        
      >
        <Tooltip title="Export to Final Draft format" >
        <DownloadIcon style={{height:"22px", width: "22px", display: "flex", flex: 1,}}/>
        </ Tooltip >
      </button>{" "}

      <Divider />

      <button
style={{color: "#909399", paddingLeft: "4px", paddingRight: "4px", }}
        //disabled={!canUndo}
        onClick={() => {
          //editor.dispatchCommand(UNDO_COMMAND);
          store.wasFocused = true;
          store.runFeaturesTour = false;

          if (store.showTableOfContents) {
            store.showTableOfContents = false;
            if (!store.focusMode) {
            store.closeOutput = false;
            }
            
          } 
          else {
            store.closeOutput = true;
            store.showTableOfContents = true;
          }

        }}
        className={"toolbar-item spaced contents" + (store.showTableOfContents ? " active" : "")}
        aria-label="Undo"
      >

        <Tooltip title="Table of Contents">
        <ViewListRoundedIcon style={{height:"22.5px", width: "22.5px", color:"#6c4191", display: "flex", flex: 1,}} />
        </Tooltip>
      </button>

      
      <button
        style={{color: "#909399", paddingLeft: "4px", paddingRight: "4px"}}
        //disabled={!canUndo}
        onClick={() => {
          //editor.dispatchCommand(UNDO_COMMAND);
          store.wasFocused = true;
          store.runFeaturesTour = false;

          if (store.focusMode) {
            store.focusMode = false;
            if (!store.showTableOfContents) {
            store.closeOutput = false;
            }
            
          } 
          else {
            store.closeOutput = true;
            store.focusMode = true;
          }
        }}
        className={"toolbar-item spaced focus-mode" + (store.focusMode ? " active" : "")}
        aria-label="Undo"
      >
        <Tooltip title="Focus Mode">
        <CameraIcon style={{height:"22px", width: "22px", color: "#443da1", display: "flex", flex: 1,}} />
        </Tooltip>
      </button>


      <button
        style={{color: "#909399", paddingLeft: "4px", paddingRight: "4px"}}
        onClick={() => {
          
          store.wasFocused = true;
          store.runFeaturesTour = false;

          if (store.voiceMode) {
            store.voiceMode = false;
            if (!store.showTableOfContents && !store.focusMode) {
            store.closeOutput = false;
            }
            
          } 
          else {
            store.closeOutput = true;
            //store.focusMode = false;
            store.showTableOfContents = false;
            store.voiceMode = true;
          }
        }}
        className={"toolbar-item spaced table-read " + (store.voiceMode ? " active" : "")}
        aria-label="Undo"
      >
        <Tooltip title="Table Read Mode">
        <InterpreterModeIcon style={{height:"24.5px", width: "24.5px", color: "#3d4ddb", display: "flex", flex: 1, }}
         />
         </Tooltip>
      </button>
      </div>
      </div>


: !store.showTableRead ?
<div style={{display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", width: "100%"}}>


<span style={{ color: "#3645c9", marginLeft: "6px", marginBottom: "7px", marginTop: "5px", fontSize: "16.7px" }} className="text">
  {"Select some text to start a new "}
  <span style={{ fontFamily: 'Coal', fontSize: "16px" }}>Table Read</span>
  </span>
  <span style={{ color: "#5a575c", marginLeft: "6px", marginBottom: "7px", marginTop: "5px", fontSize: "14px" }} >
  {["matt@storywave.co","test@testerteststesting.com"].includes(store.profile.email)  ? "" : "(limited to 1000 words during Beta)"}
  </span>

<button
        style={{color: "#909399", paddingLeft: "7px", paddingRight: "7px", paddingTop: "3px", paddingBottom: "3px", marginBottom: "7px", marginTop: "5px", marginLeft: "auto"}}
        onClick={() => {

     
          store.voiceMode = false;
          store.showTableRead = false;
          if (!store.showTableOfContents && !store.focusMode) {
            store.closeOutput = false;
          }
            
      
        }}
        className={"toolbar-item spaced focus-mode rounded-xl hover:bg-indigo-100 hover:drop-shadow hover:shadow active:shadow-inner active:shadow-indigo-400" + (store.voiceMode ? " active" : "")}
        title="Exit"
        aria-label="Undo"
      >
        
        <InterpreterModeIcon style={{height:"26px", width: "26px", color: "#3d4ddb"}}/>
        <span style={{color: "#3d4ddb", marginLeft: "6px",}} className="text">{"Exit"}</span>

        
      </button> 
      </div>
      

:

<div style={{display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", width: "100%"}}>

<span style={{color: "#3d4ddb", marginLeft: "6px", marginBottom: "7px", marginTop: "5px", fontSize: "16.3px"}} className="text">{!store.readCompleted? "Generating Table Read ..." : <span><span>Table Read complete</span><span className="text-slate-600 text-[14.5px]"> (click Saved Reads to replay)</span></span>}</span>
{store.readCompleted ? <button
        style={{color: "#909399", paddingLeft: "7px", paddingRight: "7px", paddingTop: "4px", paddingBottom: "3px", marginBottom: "7px", marginTop: "5px", marginRight: "10px", marginLeft: "auto"}}
        onClick={() => {

     
          store.showTableRead = false;
          store.voiceMode = true;
            
      
        }}
        className={"toolbar-item spaced focus-mode border border-fuchsia-500 rounded-xl hover:bg-fuchsia-50 hover:drop-shadow hover:shadow active:shadow-inner active:shadow-indigo-400" + (store.voiceMode ? " active" : "")}
        title="Exit"
        aria-label="Undo"
      >
        
        <LyricsIcon style={{height:"25px", width: "25px", color: "#b002bf"}}/>
        <span style={{color: "#b002bf", marginLeft: "6px",}} className="text">{"Start New Read"}</span>

        
      </button> 
      
    : null}


<button
        style={{color: "#909399", paddingLeft: "7px", paddingRight: "7px", paddingTop: "3px", paddingBottom: "3px", marginBottom: "7px", marginTop: "5px", marginLeft: store.readCompleted ? "0px" : "auto"}}
        onClick={() => {

     
          store.voiceMode = false;
          store.showTableRead = false;
          if (!store.showTableOfContents && !store.focusMode) {
            store.closeOutput = false;
          }
            
      
        }}
        className={"toolbar-item spaced focus-mode border border-blue-400 rounded-xl hover:bg-indigo-50 hover:drop-shadow hover:shadow active:shadow-inner active:shadow-indigo-400" + (store.voiceMode ? " active" : "")}
        title="Exit"
        aria-label="Undo"
      >
        
        <InterpreterModeIcon style={{height:"26px", width: "26px", color: "#3d4ddb"}}/>
        <span style={{color: "#3d4ddb", marginLeft: "6px",}} className="text">{"Exit"}</span>

        
      </button> 
      </div>

}












      </>

  )
}))

export default ToolbarPlugin;
