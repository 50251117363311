import React, { Component } from 'react';
import { observer, inject, } from 'mobx-react'
import { reaction } from 'mobx';

import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';

import Loader from './Loader'

@inject('store')
@observer
class VideoListTitleBelow extends Component {

    constructor(props) {
        super(props);
        this.state = {
            itemData: ["empty"],
        };
        this.mounted = false;

        // Initialize a MobX reaction to listen for changes to isVideoLoading
        this.imageLoadingReaction = reaction(
            () => this.props.store.isVideoLoading,
            isLoading => {
                if (!isLoading) {
                    this.refreshVideos();
                }
            }
        );
    }


    componentDidMount() {
        this.mounted = true;
        this.init();
    }

    componentWillUnmount() {
        this.mounted = false;
        this.imageLoadingReaction();
    }


    init = async () => {
        this.refreshVideos()
    }


    refreshVideos = async () => {
        let response = await this.props.store.api.post('/user/videos/view', { documentId: window.location.href.split('/').pop() })
        if (response.data.length > 0) {
            //console.log( response.data)
            const imageData = [...response.data]
            const itemData = imageData.map(({ videoURL, prompt, deleted }) => ({ videoURL, prompt, deleted }))

            //console.log(itemData)

            this.setState({ itemData });



        } else {
            this.setState({ itemData: [] });
        }

    }




    render() {

        const { itemData } = this.state;

        return (
            <div>
                {

                    itemData[0] === "empty" ? null

                        :

                        itemData.length < 1 && !this.props.store.isVideoLoading ?

                            <div className="pl-2 text-slate-800"><div className="pt-0">You haven't generated any videos yet</div> <br></br> <div>- Use the menu that pops up when you select text in your Script for generating</div> <br></br> <div>- Videos work best with short text, so input is limited to 10 words (min of 3 words) </div> <br></br> <div>- Note that generation can sometimes take 4-5 mins if the model needs to boot up first</div> <br></br> <br></br><div>Check out the example below of "a spaceship entering hyperdrive": </div>
                                <br></br>
                                <div className='ml-5 mb-12'>
                                    <video width="450" height="450" controls loop className='rounded-md shadow-md shadow-neutral-500'>
                                        <source src={`https://replicate.delivery/pbxt/kycCPe58wQ0EZiBfonksiRgz8Dq46oBlNe71VTXlDjVK6UgiA/out.mp4`} type="video/mp4" />
                                    </video>
                                </div>
                            </div>

                            :
                            <ImageList sx={{ maxWidth: 1500, maxHeight: '81vh' }}>
                                {this.props.store.isVideoLoading ? <div className="ml-2 mt-4">
                                <div>generating, this might take a few mins...</div>
                                <br></br>
                                    <Loader active={this.props.store.isVideoLoading} className="ml-32 w-10 h-10" />
                                    
                                </div>  : <>

                                </>}
                                {this.props.store.videoError ? <div className="ml-2 mt-4">
                                <div>Sorry, it looks like something went wrong.</div>
                                <br></br>
                                <div>Try generating again.</div>
                                    
                                </div>  : <>

                                </>}
                                {itemData.map((item) => (
                                    <ImageListItem key={item.videoURL} sx={{ marginBottom: '30px', ':hover': { '& .MuiImageListItemBar-root': { opacity: 1 } } }}>

                                        <video width="512" height="512" controls loop className='rounded-md shadow-md shadow-neutral-500'>
                                            <source src={`${item.videoURL}`} type="video/mp4" />
                                        </video>
                                        <ImageListItemBar className="rounded-t-md"
                                            subtitle={<div>{item.prompt}</div>}
                                            position="top"
                                            sx={{ maxWidth: 512, opacity: 0 }}
                                        />
                                    </ImageListItem>
                                ))}
                            </ImageList>
                }
            </div>
        );
    }
}

/* const itemData = [
    {
        vid: 'https://replicate.delivery/pbxt/2xc34h32X3okKF83xeD42HGKqFTom3GX8fT4EllQU323D81QA/out.mp4',
        text: 'a car driving through San Francisco at night on a tuesday morning while there is a full moon',
    },
    {
        vid: 'https://replicate.delivery/pbxt/KpoU7nyWEEbsItfa8qqYynB4zea5erEOv6vWtlWC8tDID4rhA/out.mp4',
        text: 'a dance contest on mars',
    },
    {
        vid: 'https://replicate.delivery/pbxt/2xc34h32X3okKF83xeD42HGKqFTom3GX8fT4EllQU323D81QA/out.mp4',
        text: 'a car driving through San Francisco at night on a tuesday morning while there is a full moon',
    },
    {
        vid: 'https://replicate.delivery/pbxt/KpoU7nyWEEbsItfa8qqYynB4zea5erEOv6vWtlWC8tDID4rhA/out.mp4',
        text: 'a dance contest on mars',
    },
];
 */
export default VideoListTitleBelow;