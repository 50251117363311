import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

const obj = {

	title: "Idea to Outline",
	desc: "Get a five act structured outline from an initial plot idea",
	category: "generateOutline",
	Icon: FormatListBulletedIcon,
	// tags: [],
	permissions: ['user'],
	
	fromColor: "purple-500",
	toColor: "blue-500",

	to: "/ai/editor",
	api: "/outline",

	output: {
		title: "",
		desc: "",
		Icon: false,
		color: "blue",
	},

	button:{
		title: "Generate Outline",
	},

	prompts: [{
		title:"3 Act Outline",
		desc: "Describe your screenplay idea in a few sentences and get a three act structured outline",
		// n: 1,
		prompts: [{ 
				title: "", 
				attr: "content",  
				value: "", 
				placeholder: "Briefly describe your idea and get back a three act structured outline", 
				label: "",
				type: "textarea",
				minWords: 5,
				maxWords: 75,
				required: true,
				error: "",
				example: "The Sun is over 4.5 billion years old and has a temperature of around 10,000 degrees Fahrenheit. The Sun's light reaches the Earth in eight minutes. Coronal mass ejections are gases on the Sun's surface erupt, shooting far out into space.",
			},
		],
		example: {
			 output: "",
			//outputs: [
			//	"The sun is very old, over 4.5 billion years",
			//	"At 10,000 degrees, sun is also very hot",
			//	"Gasses called coronal mass ejections erupt from the sun",
			//],
			//Icon: RefreshIcon,
			color: "blue",
		}
	}]
		
}

export default obj

