import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import logo from '../../Components/Assets/circle-logo3.png'


const Logo = () => (
	< img src={logo} className="brightness-100" style={{ height: 27, width: 27}}></img>

)


function Header() {

  const [top, setTop] = useState(true);

  // detect whether user has scrolled the page down by 10px 
  useEffect(() => {
    const scrollHandler = () => {
      window.pageYOffset > 10 ? setTop(false) : setTop(true)
    };
    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, [top]);

  return (
    <header className={`fixed w-full z-30 md:bg-opacity-90 transition duration-300 ease-in-out ${!top && 'bg-white backdrop-blur-sm shadow-lg'}`}>
      <div className="max-w-6xl h-16 mx-auto px-5 sm:px-6">
        <div className="flex items-center justify-between h-16 md:h-20">
          
        <div>
          <div className="mt-6 mr-[8px] mb-[47px] rounded-full ">
           <Logo/>
          </div>
        </div> 
          
          {/* Site branding */}
          <div className="flex-shrink-0 mr-4">


            <div>


              <div>
                <div style={{ fontFamily: 'Coal' }} className="brightness-100 mb-[10px] text-[28.5px] md:py-1 font-light relative text-transparent bg-clip-text bg-gradient-to-l from-blue-800 to-sky-700 inline-block h-14" >Next Scene</div>


              </div>
            </div>
          </div>

          {/* Site navigation */}
          <nav className="flex flex-grow">
            <ul className="mt-0.5 flex flex-grow justify-end flex-wrap items-center">
              <li>
                <Link to="/signup" className="text-lg font-medium text-gray-600 hover:text-sky-500 px-5 py-3 flex items-center transition duration-150 ease-in-out">Sign in</Link>
              </li>
              <li>
              <a href={`https://docs.google.com/forms/d/e/1FAIpQLSdSaW60eaY-kQUgbxPq7INgW_DndRGmJpBYpSTxAT2Mc5C_Qg/viewform`} target="_blank" className="text-white bg-sky-600 hover:bg-sky-500 ml-3 p-2 rounded-2xl">
                  <span>Join Waitlist</span>
                  
                </a>
              </li>
            </ul>

          </nav>

        </div>
      </div>
    </header>
  );
}

export default Header;
