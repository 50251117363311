import React, { Component } from 'react';
import {
    PencilIcon,
    ClockIcon,
    DuplicateIcon,
    LightBulbIcon,
} from '@heroicons/react/outline'

import { Helmet } from "react-helmet";

import Header from '../Components/Header'
import Body2, { Grid, Col } from '../Components/Body2'
import Button4 from '../Components/Button4'
import Button2 from '../Components/Button2'
import Output from '../Components/Output'
import Countdown from 'react-countdown';
import { withRouter } from 'react-router-dom'

import { runInAction, action, reaction, observable, makeObservable, computed, } from 'mobx'
import { observer, inject, } from 'mobx-react'

import EntryPrompt from '../Components/EntryPrompt'
import WritingPartner from '../Components/WritingPartner'
import EntryN from '../Components/EntryN'


import CustomizedSwitch from '../Components/Switch'
import VerticalTabs from '../Components/VerticalTabs';

import LexicalNotes from '../Components/Notes'
import LexicalScene from '../Components/Script'

import SummaryAccordions from '../Components/AccordionSummary'
import FeedbackAccordions from '../Components/AccordionFeedback'
import MemoryAccordions from '../Components/AccordionMemory'




import DrawerMenu from '../Components/DrawerMenu'
import DrawerMenuTableRead from '../Components/DrawerMenuTableRead'

import { Readable } from 'stream';

import { createParser } from "eventsource-parser"

import fetch from "node-fetch"
import { ConstructionOutlined } from '../../node_modules/@mui/icons-material/index';

import BasicDemo from '../Components/JoyrideDemo';

import  ImageListTitleBar from '../Components/ImageList'

import VideoListTitleBelow from '../Components/VideoList';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ButtonMUI from '@mui/material/Button';

import VoiceList from '../Components/VoiceList';
import CharacterList from '../Components/CharacterList';

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';



const countWords = (str) => {
    let wordCount = 0;
    let match = str.match(/\b[-\w']+\b/gi);
    if (match) {
        wordCount = match.length;
    }
    return wordCount;
}

// code to see if string contains any letters
function hasLetters(str) {
    return str.toLowerCase() != str.toUpperCase();
}


function extractOddAndEvenQuotes(str) {
	const oddQuotes = [];
	const evenQuotes = [];

	const quoteRegex = /"([^"]*)"/g;
	let match;
	let index = 1;

	while ((match = quoteRegex.exec(str)) !== null) {
		if (index % 2 === 0) {
			evenQuotes.push(match[1]);
		} else {
			oddQuotes.push(match[1]);
		}
		index++;
	}

	return {
		oddQuotes,
		evenQuotes
	};
}

console.log(localStorage.getItem('totalDocs'))

@inject('store')
@observer
class Tool extends Component {

    toolValue = {}


    @observable error = ""

    @observable outputs = []


    @observable date = Date.now()
    countdown = []




    constructor(props) {
        super(props)
        makeObservable(this)

        this.state = {
            screenWidth: window.innerWidth,
          };

        this.init();

        // the code below clears the text area/currentInput when switching tools (remove to persist text between tools)
        /* reaction(
            () => this.toolValue,
            (toolValue) => {
            console.log(toolValue.prompts[0].prompts[0].value)
            this.props.store.currentInput = toolValue.prompts[0].prompts[0].value;
            }
        );
            */
    }


    componentDidMount() {
        window.addEventListener('resize', this.handleWindowSizeChange);
      }
    
      componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
      }
    
      handleWindowSizeChange = () => {
        this.setState({ screenWidth: window.innerWidth });
      };



    currentPrompt = 0


    handleCurrentPrompt = (val) => {
        this.currentPrompt = val

    }

    handleUploadScript = (event) => {
        var element = document.querySelector(".format.upload");

        // Trigger a click event on the element
        var event = new MouseEvent('click', {
        'view': window,
        'bubbles': true,
        'cancelable': true
        });
        element.dispatchEvent(event);

    }


    //@action handlePromptChange = (attr, value) => {
    //    const prompts = [...this.prompts];
    //    const currentPrompt = this.currentPrompt;
    //    const promptIndex = this.prompts.findIndex((p) => p.attr === attr);
    //    prompts[promptIndex] = { ...prompts[promptIndex], value };
    //    this.props.store.currentInput = value;
    //  };


    init = async () => {
        this.setInitialScriptState()
        this.setInitialNotesState()
        this.runFeaturesTour = localStorage.getItem('totalDocs') !== "1" ? true : false

    }

    setInitialNotesState = async () => {

        let response = await this.props.store.api.post('/user/notes/view', { documentId: window.location.href.split('/').pop() })
        if (response.data.length > 0) {
            const notesState = [...response.data]
            this.props.store.initialNotesState = notesState[0].notes
        }

    }

    setInitialScriptState = async () => {

        if (localStorage.getItem('script') !== "null") {
            //console.log(localStorage.getItem('script'))
            this.props.store.initialScriptState = localStorage.getItem('script');
        } else {
            this.props.store.initialScriptState = undefined;

        }

        if (localStorage.getItem('uploadScript') === "true") {
            this.props.store.uploadScript = true;
            localStorage.setItem('uploadScript', "false")

        }

    }


    @computed get prompts() {

        if (this.props.store.mode === "chat") {
            this.promptsValue[0].prompts[0].value = ""
        }
        this.promptsValue = [...this.tool.prompts];

        return this.promptsValue

    }


    @computed get tool() {
        let title;
        let prevInput = this.props.store.toolInput
        if (this.props.store.selectedTool === null) {
            title = "Opening Scene";
        } else {
            title = this.props.store.selectedTool;
        }


        runInAction(() => {
            //console.log(this.toolValue)
            //console.log(this.props.store.mode)
            this.toolValue = this.props.store.getToolByTitle(title);
            this.toolValue.prompts[0].prompts[0].value = prevInput



        });

        return this.toolValue;
    }


    @computed get isGenerateButtonDisabled() {

        if (this.props.store.loading) {
            return true
        }

        return false
    }

    @computed get disabled() {

        if (this.prompts[this.currentPrompt].prompts[0].value.length < 1) {
            return true
        }
        // this.prompts[this.currentPrompt].prompts[promptIndex].value
        return false
    }


    checkPromptLength = (mode) => {

        let shouldReturn = false

        //console.log(mode)


        this.prompts[this.currentPrompt].prompts.forEach((prompt, promptIndex) => {
            let promptLength = countWords(prompt.value);
            if (mode === "tools" && prompt.minWords) {
                if (promptLength < prompt.minWords) {
                    shouldReturn = true
                    this.props.store.streaming = false
                    this.props.store.error = `This feature requires at least ${prompt.minWords} words`;
                }
            } else if (mode === "chat") {
                if (promptLength < 3) {
                    shouldReturn = true
                    this.props.store.streaming = false
                    this.props.store.error = `Sorry, I need a little more to work with (at least 3 words). `;

                }
            }
            if (mode === "tools" && prompt.maxWords) {
                if (promptLength > prompt.maxWords) {
                    shouldReturn = true
                    this.props.store.streaming = false
                    this.props.store.error = `The maximum number of words allowed for this feature is ${prompt.minWords}`;
                }
            }
        })

        return shouldReturn
    }



    checkOutput = (output) => {
        if (output) {
            output = output.replace(/^\s+|\s+$/g, '')
            // output = output.replace(/\s{2,}/g, ' ')
        }
        return output
    }



    onGenerateClick = async () => {

        try {

            this.props.store.scriptWriteOutput = ""

            this.props.store.closeOutput = false

            this.props.store.error = ""
            this.props.store.output = ""
            this.code = ``
            this.outputs = []
            this.props.store.loading = true
            this.generating = true
            this.props.store.streaming = true
            this.props.store.scriptWriteOutputShow = "";



            let isChat = this.props.store.mode === "chat"
            const chatAPI = "/writingPartner"

            //console.log(this.props.store.mode)
            let checkPromptLength = this.checkPromptLength(this.props.store.mode)
            if (checkPromptLength) {
                this.props.store.loading = false
                return false
            }

            let postObj = {}


            this.prompts[this.currentPrompt].prompts.forEach((prompt) => {
                postObj[prompt.attr] = prompt.value
            })


            postObj.currentPrompt = this.prompts[this.currentPrompt].title
            if (this.prompts[this.currentPrompt].n) {
                postObj.n = this.prompts[this.currentPrompt].n
            }

            this.props.store.openaiInput = postObj.content.trim()

            postObj.user = this.props.store.profile._id
            postObj.email = this.props.store.profile.email

            let response;
            if (isChat) {

                postObj.model = 'gpt-3.5-turbo'

                if (this.props.store.sceneContext) {

                    postObj.model = 'gpt-3.5-turbo-16k'
                    
                    // only use the first 5000 words of the script as context and the first 100 words of the user input
                    postObj.content = this.props.store.scriptText.split(/\s+/).slice(0, 5000).join(" ") + "\n\n" + postObj.content.split(/\s+/).slice(0, 100).join(" ");
                
                }
                /* console.log(postObj)
                response = await this.props.store.api
                .post(chatAPI, postObj) */


                response = await fetch(this.props.store.baseURL_AI + chatAPI, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(postObj),
                });

                this.props.store.apiSource = chatAPI
                this.props.store.generationSourceCategory = "chat"

            } else {

                if (this.tool.api == "/generateScene") {
                    const last250Words = this.props.store.scriptText.split(/\s+/).slice(-250).join(" ")
                    postObj.content = { "scriptContext": last250Words, "prompt": postObj.content }

                }

                response = await fetch(this.props.store.baseURL_AI + this.tool.api, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(postObj),
                });

                this.props.store.apiSource = this.tool.api
                this.props.store.generationSourceCategory = this.tool.category

            }





            /* if (!response.ok) {
                throw new Error(response.statusText);
            } */

            // This data is a ReadableStream
            const data = response.body;
            if (!data) {
                //console.log(response)
                return;
            }
            this.props.store.loading = false
            this.props.store.streaming = true
            const reader = data.getReader();
            const decoder = new TextDecoder();


            let done = false;
            /* let inQuotes = false;
            let keyValCount = 1;
            let currentLineType = "";
            let lineText = "";
            let seenOpeningParen = false; */
            let currentLine = "";
            let tempLine = "";


            let lastUpdateTime = Date.now(); // initialize lastUpdateTime variable with the current time

            while (!done) {
                const { value, done: doneReading } = await reader.read();
                //console.log(this.props.store.streaming)
                done = doneReading;
                
                const chunkValue = decoder.decode(value);



                // check if more than 15 seconds have passed since last update
                if (Date.now() - lastUpdateTime > 15000) {
                    console.error("No update received for the last 15 seconds");
                    this.props.store.generationSourceCategory = "error"
                    this.props.store.scriptWriteOutput = "Sorry, it appears an error occurred. Please try generating again."
                    this.props.store.streaming = false

                    done = true;
                }
                lastUpdateTime = Date.now(); // update lastUpdateTime variable with the current time




                //console.log(chunkValue)

                if (this.tool.category !== "generateScreenplay") {

                    this.props.store.scriptWriteOutput += chunkValue;


                } else {

                    this.props.store.scriptWriteOutput += chunkValue;



                    if (!chunkValue.includes("}")) {
						currentLine += chunkValue

					} else {
						if (chunkValue.includes("{")) {
							tempLine = "{" + chunkValue.split("{")[1];
							currentLine += chunkValue.split("{")[0];
						} else {
							tempLine = "";
							currentLine += chunkValue
						}

						let { oddQuotes, evenQuotes } = extractOddAndEvenQuotes(currentLine)

						for (let i = 0; i < oddQuotes.length; i++) {

							//console.log(currentLine)
							let lineType = oddQuotes[i]
							//console.log(lineType)
							let lineText = evenQuotes[i]
							//console.log(lineText)

							if (lineType && lineText) {

								if (lineType === "Character Name" || lineType === "Character") {
									this.props.store.scriptWriteOutputShow += "\t\t\t\t";
								} else if (lineType === "Parenthetical") {
									this.props.store.scriptWriteOutputShow += "\t\t";
								} else if (lineType === "Dialogue") {
									this.props.store.scriptWriteOutputShow += "\t";
								} 

								this.props.store.scriptWriteOutputShow += lineText;

								if (lineType === "Character Name" || lineType === "Character" || lineType === "Parenthetical") {
									this.props.store.scriptWriteOutputShow += "\n";
								} else {
									this.props.store.scriptWriteOutputShow += "\n\n";
								}

							}
						}

						currentLine = tempLine;



					}






                    /* 

                    if (seenCurlyBraces) {
                        console.log("curly braces seen!!!!")

                        if (inQuotes) {
                            inQuotes = false;
                            if (keyValCount % 2 === 0) {

                                if (currentLineType === "Parenthetical") {

                                    if (!lineText.endsWith(")")) {
                                        lineText = lineText + ")";
                                    }
                                }
                                
                                // removing "FADE OUT" won't work here beacause across multiple streaming chunks
                                if (lineText !== "FADE OUT" && lineText !== "FADE OUT.") {
                                    this.props.store.scriptWriteOutputShow += lineText.replace("}","").replace("{","").trim()
                                    seenOpeningParen = false;
                                }

                                
                                if (currentLineType === "Character Name" || currentLineType === "Character" || currentLineType === "Parenthetical") {
                                    this.props.store.scriptWriteOutputShow += "\n"
                                } else {
                                    this.props.store.scriptWriteOutputShow += "\n\n"
                                }
                                

                            }

                           
                        }
                        
                        keyValCount = 1;
                        seenCurlyBraces = false;
                        lineText = "";
                        
                    } else if (inQuotes) {
                        if (chunkValue.includes('"')) {
                            if ((chunkValue.match(/"/g) || []).length < 2) {
                                inQuotes = false;
                            }
                            if (keyValCount % 2 === 0) {

                                lineText = chunkValue.split('"')[0];
                                lineText = lineText


                                if (currentLineType === "Parenthetical") {

                                    if (!lineText.endsWith(")")) {
                                        lineText = lineText + ")";
                                    }
                                }

                                // removing "FADE OUT" won't work here beacause across multiple streaming chunks
                                if (lineText !== "FADE OUT" && lineText !== "FADE OUT.") {
                                    this.props.store.scriptWriteOutputShow += lineText
                                    seenOpeningParen = false;
                                }

                              
                                if (currentLineType === "Character Name" || currentLineType === "Character" || currentLineType === "Parenthetical") {
                                    this.props.store.scriptWriteOutputShow += "\n"
                                } else {
                                    this.props.store.scriptWriteOutputShow += "\n\n"
                                }
                            

                            }
                            else {
                                currentLineType += chunkValue.split('"')[0];
                            }

                            keyValCount++;

                        } else if (keyValCount % 2 === 0) {
                            lineText = chunkValue;
                            seenCurlyBraces = lineText.includes("{") || lineText.includes("}")
                            if (currentLineType === "Parenthetical" && !seenOpeningParen) {
                                if (lineText.trim().startsWith("(")) {
                                    seenOpeningParen = true;
                                } else if (hasLetters(lineText)) {
                                    lineText = "(" + lineText;
                                    seenOpeningParen = true;
                                }
                            }
                            if (!seenCurlyBraces) {
                                this.props.store.scriptWriteOutputShow += lineText
                            }
                        } else {
                            currentLineType += chunkValue;
                        }


                    } else if (chunkValue.includes('"')) {
                        inQuotes = true;

                        if (keyValCount % 2 === 0) {
                            if (!seenCurlyBraces) {
                                if (currentLineType === "Character Name" || currentLineType === "Character") {
                                    this.props.store.scriptWriteOutputShow += "\t\t\t\t"
                                } else if (currentLineType === "Parenthetical") {
                                    this.props.store.scriptWriteOutputShow += "\t\t"
                                }
                            }
                            lineText = chunkValue.split('"')[1]
                            seenCurlyBraces = lineText.includes("{") || lineText.includes("}")
                            lineText = lineText
                            if (currentLineType === "Parenthetical") {
                                if (lineText.startsWith("(")) {
                                    seenOpeningParen = true;
                                } else if (hasLetters(lineText)) {
                                    lineText = "(" + lineText;
                                    seenOpeningParen = true;
                                }
                            }
                            if (!seenCurlyBraces) {
                                this.props.store.scriptWriteOutputShow += lineText;
                            }
                        } else {
                            currentLineType = "";
                            currentLineType = chunkValue.split('"')[1];
                        }

                    } */
                }





            }


            this.props.store.streaming = false


            /* if(response.data.output){
                  this.props.store.output = this.checkOutput(response.data.output)
              }
  
              if(response.data.code){
                  this.code = response.data.code
              } 
  
              if(response.data.outputs){
                  this.outputs = response.data.outputs
              }
  
              
              
              this.date = Date.now()
               this.countdown.forEach(countdown => {
                  if(countdown){
                      countdown.stop()
                      countdown.start()
                  }
              })
              */






        } catch (error) {
            console.log(error)

            this.props.store.loading = false
            this.props.store.streaming = false
        }
    }


    /* generateVoice = async () => {

        this.props.store.generateVoiceReady = false


        const audioContext = new (window.AudioContext || window.webkitAudioContext)();
      
        const playAudio = async (item) => {

          
        let postObj = {}
    

          const audioElement = new Audio();
          // audioElement.controls = true;
          document.body.appendChild(audioElement);

          const characterName = item[0]
          console.log(characterName)
          const content = item[1]
          const nodeKey = item[2]
  
          const voiceId = this.props.store.characterToVoiceID[characterName]

          postObj["content"] = content
          postObj["voiceId"] = voiceId


          console.log(postObj)
  
      
          const response = await fetch(this.props.store.baseURL_AI + '/voiceGen', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'audio/mpeg',
            },
            body: JSON.stringify(postObj)
          });


          if (response.ok) {
            const audioData = await response.arrayBuffer();
            const audioBuffer = await audioContext.decodeAudioData(audioData);
      
            // Create an AudioBufferSourceNode
            const sourceNode = audioContext.createBufferSource();
            sourceNode.buffer = audioBuffer;
      
            // Connect the sourceNode to the destination (audio output)
            sourceNode.connect(audioContext.destination);
      
            // Start playing the audio
            sourceNode.start();
      
            // Wait for the audio to finish playing
            await new Promise(resolve => {
              sourceNode.onended = resolve;
            });
      
            // Remove the audio element from the document
            audioElement.parentNode.removeChild(audioElement);
          } else {
            console.error('Error streaming audio:', response.status);
          }
        };
      
        // Iterate through the contentArray sequentially
        for (let item of this.props.store.selectedVoiceLines) {
          await playAudio(item);
        }
      };

 */


    render() {

        // required for mobx to pick up deeply nested value 
        const currentValue = this.prompts[this.currentPrompt].prompts[0].value

        //console.log(this.runFeaturesTour)

        const { screenWidth } = this.state;
        const startValue = this.props.store.voiceMode && screenWidth > 1280 ? 3 : this.props.store.voiceMode && screenWidth > 1280 ? 2: this.props.store.voiceMode &&  screenWidth > 1060 ? 2: this.props.store.voiceMode ? 1 : !this.props.store.focusMode && !this.props.store.showTableOfContents ? 2 : this.props.store.loading ? 1 : screenWidth < 1280 && this.props.store.showTableOfContents ? 2 : screenWidth < 1425 && this.props.store.showTableOfContents ? 4 :  this.props.store.showTableOfContents ? 3 : screenWidth < 1280 && (!this.props.store.scriptWriteOutput || this.props.store.closeOutput) ? 1 : !this.props.store.scriptWriteOutput || this.props.store.closeOutput || this.props.store.showTableOfContents ? 3 : 1;

       /*  if (this.props.store.generateVoiceReady) {
            this.generateVoice()
            console.log(this.props.store.selectedVoiceLines)
            console.log(this.props.store.characterToVoiceID)

        } */

        return (

            <>
                <Helmet>
                    <title>{`Next Scene - Editor`}</title>
                </Helmet>
          

                <Body2 className={this.props.store.voiceMode && !this.props.store.showTableRead ? `pb-3 bg-gradient-to-b from-[#fafafc] via-[#fafafc] to-indigo-50` : this.props.store.voiceMode  ? `bg-gradient-to-b from-[#fafafc] via-[#fafafc] to-indigo-50`: ``}>


                    <Grid >
                    {this.props.store.uploadScript ?
                    <Dialog
        open={this.props.store.uploadScript}
        onClose={this.handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        
        modal={false}
      >
        <DialogTitle id="alert-dialog-title">
          {"Proceed to upload a .fdx file?"}
        </DialogTitle>
        <DialogActions>
          <ButtonMUI variant="outlined" onClick={() => {this.props.store.uploadScript=false; this.handleUploadScript()}}>Yes</ButtonMUI>
          <ButtonMUI variant="outlined" onClick={() => this.props.store.uploadScript=false} color = "secondary" autoFocus>
            Nevermind
          </ButtonMUI>
        </DialogActions>
      </Dialog>  
      
      : null}

   

                        { ( this.props.store.voiceMode || this.props.store.showTableRead ) && !this.props.store.selectVoicesMode && this.props.store.readCompleted ? 

                                <div className="ml-0">
                                <div className="container flex px-0 md:px-0 flex select-none">

                                    <DrawerMenuTableRead />

                                </div>
                                </div>
                        
                        :

                        !this.props.store.focusMode  && !this.props.store.showTableOfContents  && !this.props.store.voiceMode  ?
                            <Col span="1" start="1">

                                {this.props.store.runFeaturesTour ? <BasicDemo /> : this.runFeaturesTour && !this.props.store.wasFocused ?
                                    <BasicDemo /> : null}

                                <div className="ml-0">
                                    <div className="container flex px-0 md:px-0 flex select-none">

                                        <DrawerMenu/>

                                    </div>
                                </div>




                                <VerticalTabs classname="ml-4"></VerticalTabs>


                            </Col>

                            :

                            !this.props.store.focusMode && !this.props.store.voiceMode  && this.props.store.showTableOfContents ?

                            <div className="ml-0">
                                    <div className="container flex px-0 md:px-0 flex select-none">

                                        <DrawerMenu />

                                    </div>
                                </div>



                                : null}
                        {(this.props.store.selectedDocType === "Script" ||  this.props.store.selectedDocType === "Notes") && !this.props.store.selectVoicesMode ? 
                        <Col span="8" start={startValue}>
                            {this.props.store.selectedDocType === "Script" ?
                                /*<Editor 
                                       
                                       source={this.tool}
                                       insertText = {this.props.store.insertText}
                               />
                               */
                                <div className={`editor-script ${this.props.store.showTableOfContents || this.props.store.voiceMode || this.props.store.showTableRead  ? "ml-0" : "ml-3"} whitespace-pre-wrap mt-3 flex-grow-1 overflow-y-comp`} style={{ overflowX: 'hidden' }}><LexicalScene /></div>

                                :  <div className='ml-3 whitespace-pre-wrap mt-3 flex-grow-1 overflow-y-comp' style={{ overflowX: 'hidden' }}><LexicalNotes /></div>
                        }
                            </Col>

                        : 
                        <Col span="10" start={startValue}>

                              {  this.props.store.selectedDocType === "Summary" ? <div className='mt-24 border  mb-10 py-3 mr-6 md:ml-[3vh] lg:ml-[4vh] xl:ml-[9vh] rounded-2xl bg-white shadow-md shadow-blue-400'> <div className=' ml-3 mr-3  '> <SummaryAccordions items={this.props.store.savedSummaryItems} /> </div>  </div>
                                        : this.props.store.selectedDocType === "Feedback" ? <div className='mt-24 border  mb-10 py-3 mr-6 md:ml-[3vh] lg:ml-[4vh] xl:ml-[9vh] rounded-2xl bg-white shadow-md shadow-cyan-600'> <div className=' ml-3 mr-3  '> <FeedbackAccordions items={this.props.store.savedFeedbackItems} /> </div>  </div>
                                            : this.props.store.selectedDocType === "Memory" ? <div className='mt-24 border  mb-10 py-3 mr-6 md:ml-[3vh] lg:ml-[4vh] xl:ml-[9vh] rounded-2xl bg-white shadow-md shadow-blue-300'> <div className=' ml-3 mr-3  '>  <MemoryAccordions items={this.props.store.savedMemoryItems} /> </div> </div>
                                                : this.props.store.selectedDocType === "Images" ? <div className='mt-24 md:ml-[1vh] lg:ml-[2vh] xl:ml-[12vh] 2xl:ml-[25vh] mr-0'><ImageListTitleBar className='mt-24' />  </div>
                                                    : this.props.store.selectedDocType === "Videos" ? <div className='mt-24 md:ml-[1vh] lg:ml-[4vh] xl:ml-[18vh] 2xl:ml-[28vh] mr-0'><VideoListTitleBelow className='mt-24' />  </div>
                                                    : null}
                        </Col>
                        }


                        {this.props.store.selectVoicesMode ?

                        <Col span="1" start="1">
                            <div className="container flex px-0 md:px-0 flex select-none">

                            <div
                                    onClick={() => this.props.store.selectVoicesMode = false}
                                    activeClassName="bg-gray-100 hover:bg-gray-200 text-gray-800 transition"
                                    className="active:shadow-inner active:shadow-purple-200 bg-white border border-indigo-400 rounded-r-xl drop-shadow shadow-md shadow-gray-300 text-md flex py-1 px-1 lg:py-2 lg:px-1 w-16 cursor-pointer hover:bg-indigo-100  font-medium transition justify-center items-center"
                                    >
                                    < ArrowBackIosNewIcon/>
                                    <div/>
                            </div>
                            </div>
                            </Col>
                            : null
                        }

                        {this.props.store.selectVoicesMode ?


                            <Col span="12" start={1}>


                                <div className="flex flex-row  pb-32 justify-center justify-items-center">
                            
                                    <VoiceList /> 

                                <div className="ml-[6vw]" />

                            
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                
                                    <CharacterList />
    
                                </div>
                
                
                            </div>
                            </Col>
                            : null
                    
                        }
                       

                        {!this.props.store.focusMode && !this.props.store.voiceMode &&  !this.props.store.showTableOfContents  && !["Summary", "Feedback", "Memory", "Images", "Videos"].includes(this.props.store.selectedDocType) ?

                            <Col span="6" start="10" >
                                <div className="mt-4">
                                    <CustomizedSwitch ></CustomizedSwitch>
                                </div>

                                <div>
                                    {this.props.store.mode === "chat" ?
                                        <div>
                                            {this.prompts.map((prompt, index) =>

                                                <WritingPartner
                                                    output={this.props.store.output}
                                                    title={""}
                                                    promptMeta={prompt}
                                                    prompt={prompt.prompts[0]}
                                                    key={index}
                                                    index={index}
                                                    disabled={this.disabled}
                                                    currentPrompt={index}
                                                    maxWords={this.prompts[0].prompts[0].maxWords}
                                                    onChange={this.handlePromptChange}
                                                    value={this.props.store.currentInput}
                                                >



                                                    <div className="md:flex place-content-center ">
                                                        <Countdown
                                                            date={this.date}
                                                            renderer={props =>
                                                                <Button4
                                                                    title={""}
                                                                    disabled={props.total || this.isGenerateButtonDisabled || this.props.store.streaming}
                                                                    Icon={LightBulbIcon}
                                                                    onClick={this.onGenerateClick}
                                                                />}
                                                        />
                                                        <EntryN
                                                            prompts={this.prompts}
                                                            currentPrompt={this.currentPrompt}
                                                        />
                                                    </div>




                                                </WritingPartner>

                                            )}
                                        </div>
                                        :
                                        <div>
                                            {this.prompts.map((prompt, index) =>

                                                <EntryPrompt
                                                    title={this.tool.title}
                                                    promptMeta={prompt}
                                                    prompt={prompt.prompts[0]}
                                                    key={index}
                                                    index={index}
                                                    disabled={this.disabled}
                                                    currentPrompt={index}
                                                    maxWords={this.prompts[0].prompts[0].maxWords}
                                                    onChange={this.handlePromptChange}
                                                    value={this.props.store.currentInput}
                                                >



                                                    <div className="md:flex place-content-center ">
                                                        <Countdown
                                                            date={this.date}
                                                            renderer={props =>
                                                                <Button4
                                                                    title={""}
                                                                    disabled={props.total || this.isGenerateButtonDisabled || this.props.store.streaming}
                                                                    Icon={this.tool.Icon}
                                                                    onClick={this.onGenerateClick}
                                                                />}
                                                        />
                                                        <EntryN
                                                            prompts={this.prompts}
                                                            currentPrompt={this.currentPrompt}
                                                        />
                                                    </div>




                                                </EntryPrompt>

                                            )}
                                        </div>
                                    }
                                </div>
                                {!this.props.store.closeOutput  ?
                                    <Output
                                        mode={this.props.store.mode}
                                        title={this.tool.output.title}
                                        desc={this.tool.output.desc}

                                        Icon={this.props.store.mode === "chat" ? LightBulbIcon : this.tool.output.Icon || this.tool.Icon}
                                        fromColor={this.tool.fromColor}
                                        toColor={this.tool.toColor}

                                        loading={this.props.store.loading}
                                        streaming={this.props.store.streaming}
                                        output={this.props.store.scriptWriteOutput ? this.props.store.scriptWriteOutput : this.props.store.output}
                                        outputs={this.outputs}
                                        code={this.code}
                                        language={this.language}

                                        outputsColor={this.tool.output.color}
                                        OutputsIcon={this.props.store.mode === "chat" ? LightBulbIcon : this.tool.output.Icon}
                                    />

                                    : null}


                            </Col>

                            : !this.props.store.closeOutput  && !["Summary", "Feedback", "Memory", "Images", "Videos"].includes(this.props.store.selectedDocType) ?
                                <Col span="8" start="9" >
                                    <Output
                                        mode={this.props.store.mode}
                                        title={this.tool.output.title}
                                        desc={this.tool.output.desc}

                                        Icon={this.props.store.mode === "chat" ? LightBulbIcon : this.tool.output.Icon || this.tool.Icon}
                                        fromColor={this.tool.fromColor}
                                        toColor={this.tool.toColor}

                                        loading={this.props.store.loading}
                                        streaming={this.props.store.streaming}
                                        output={this.props.store.scriptWriteOutput}
                                        outputs={this.outputs}
                                        code={this.code}
                                        language={this.language}

                                        outputsColor={this.tool.output.color}
                                        OutputsIcon={this.props.store.mode === "chat" ? LightBulbIcon : this.tool.output.Icon}
                                    />
                                </Col>

                                : null
                        }



                    </Grid>
                </Body2>
            </>
        )
    }
}



export default withRouter(Tool)