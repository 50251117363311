import { observer, inject } from 'mobx-react'

import { useLayoutEffect } from 'react'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import {
  createCommand,
  $getSelection,
  $isRangeSelection,
  $getNodeByKey,
  $getRoot,
} from 'lexical'

import {
  $createHeadingNode,

} from "@lexical/rich-text";

import {
  $wrapNodes,

} from "@lexical/selection";

import {
  $moveCharacter,
} from '@lexical/selection';


function findIndex(arr, n) {
  let index = arr.indexOf(n);
  if (index !== -1) {
    return index;
  }
  for (let i = arr.length - 1; i >= 0; i--) {
    if (arr[i] < n) {
      return i;
    }
  }
  return -1; // n is smaller than all values in the array
}

  // code to see if string contains any letters
  function hasLetters(str) {
    return str.toLowerCase() != str.toUpperCase();
  }




export const KeyboardShortcutsPlugin = inject('store')(observer(({ store }) => {
  const [editor] = useLexicalComposerContext()

  const generateAutowrite = async () => {

    store.openaiInput = "";
    store.closeOutput = false;
    store.scriptWriteOutput = "";
    store.scriptWriteOutputShow = "";
    //store.loading = false;
    store.output = "";
    store.loading = true;
    store.streaming = true

    editor.getEditorState().read(() => {

      const selection = $getSelection();

      const selectedText = selection.getTextContent()

      store.selectedTextScript = selectedText


      const focusNode = selection.focus.getNode();

      const startKey = focusNode.getKey()

      const root = $getRoot();
      const children = root.getChildren();
      const allKeys = children.map(x => x.getKey())

      // Find the index of the largest integer in the array that is less than or equal to n
      const index = findIndex(allKeys, startKey)

      let earlierKeys = []
      // If all integers in the array are greater than or equal to n, return an empty array
      if (index === 0 || index === -1) {
        // leave earlierKeys as empty array
      } else if (index > 0) {
        // Otherwise, slice the array to keep only the integers before the first one that is greater than or equal to n
        earlierKeys = allKeys.slice(0, index);
      } else {
        earlierKeys = allKeys
      }

      let contextText = "";
      for (let i = earlierKeys.length - 1; i >= 0; i--) {
        contextText = $getNodeByKey(earlierKeys[i]).getTextContent().trim() + " " + contextText;
        if (contextText.split(/\s+/).length >= 200) {
          break;
        }
      }

      store.selectedTextScript = contextText.split(/\s+/).slice(-210).join(" ") + " " + focusNode.getTextContent()

      console.log(store.selectedTextScript)

    })


    store.openaiInput = store.selectedTextScript;

    const targetAPI = "/autowrite"
    const postObj = { content: store.selectedTextScript, currentPrompt: '' }
    postObj.user = store.profile._id
    postObj.source = "autowrite shortcut"

    const response = await fetch(store.baseURL_AI + targetAPI, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(postObj),
    });

    const data = response.body;
    if (!data) {
      console.log(response)
      return;
    }
    store.loading = false;
    store.generationSourceCategory = undefined;
    store.apiSource = targetAPI;
    store.generationSourceCategory = "generateScreenplay"

    const reader = data.getReader();
    const decoder = new TextDecoder();

    let done = false;
    let inQuotes = false;
    let keyValCount = 1;
    let currentLineType = "";
    let lineText = "";
    let seenOpeningParen = false;
    let seenCurlyBraces = false;

    while (!done) {
      const { value, done: doneReading } = await reader.read();
      done = doneReading;
      const chunkValue = decoder.decode(value);


        store.scriptWriteOutput += chunkValue;

        if (seenCurlyBraces) {
          console.log("curly braces seen!!!!")
          console.log("keyValCount:")
          console.log(keyValCount)
          console.log("inQuotes:")
          console.log(inQuotes)
          console.log("chunkValue:")
          console.log(chunkValue)
          console.log("--------------------")
        }


        if (inQuotes) {
          if (chunkValue.includes('"')) {
            inQuotes = false;
            if (keyValCount % 2 === 0) {

              lineText = chunkValue.split('"')[0];
              lineText = lineText.trim();
              seenCurlyBraces = lineText.includes("{") ||  lineText.includes("}")

              if (currentLineType === "Parenthetical") {

                if (!lineText.endsWith(")")) {
                  lineText = lineText + ")";
                }
              }
              if (!seenCurlyBraces) {
                store.scriptWriteOutputShow += lineText
                seenOpeningParen = false;
              }

              if (!seenCurlyBraces) {
                if (currentLineType === "Character Name" || currentLineType === "Character" || currentLineType === "Parenthetical") {
                  store.scriptWriteOutputShow += "\n"
                } else {
                  store.scriptWriteOutputShow += "\n\n"
                }
              }

            }
            else {
              currentLineType += chunkValue.split('"')[0];
            }

            keyValCount++;

          } else if (keyValCount % 2 === 0) {
            lineText = chunkValue;
            seenCurlyBraces = lineText.includes("{") ||  lineText.includes("}")
            if (currentLineType === "Parenthetical" && !seenOpeningParen) {
              if (lineText.trim().startsWith("(")) {
                seenOpeningParen = true;
              } else if (hasLetters(lineText)) {
                lineText = "(" + lineText;
                seenOpeningParen = true;
              }
            }
            if (!seenCurlyBraces) {
              store.scriptWriteOutputShow += lineText
            }
          } else {
            currentLineType += chunkValue;
          }


        } else if (chunkValue.includes('"')) {
          inQuotes = true;

          if (keyValCount % 2 === 0) {
            if (!seenCurlyBraces) {
              if (currentLineType === "Character Name" || currentLineType === "Character") {
                store.scriptWriteOutputShow += "\t\t\t\t"
              } else if (currentLineType === "Parenthetical") {
                store.scriptWriteOutputShow += "\t\t"
              }
            }
            lineText = chunkValue.split('"')[1]
            seenCurlyBraces = lineText.includes("{") ||  lineText.includes("}")
            lineText = lineText.trim();
            if (currentLineType === "Parenthetical") {
              if (lineText.startsWith("(")) {
                seenOpeningParen = true;
              } else if (hasLetters(lineText)) {
                lineText = "(" + lineText;
                seenOpeningParen = true;
              }
            }
            if (!seenCurlyBraces) {
            store.scriptWriteOutputShow += lineText;
            }
          } else {
            currentLineType = "";
            currentLineType = chunkValue.split('"')[1];
          }


      }

    }

    store.streaming = false

  }

  useLayoutEffect(() => {
    const onKeyDown = (event) => {

      if (event.metaKey && event.which === 49) {
        event.preventDefault()
        editor.update(() => {
          editor.focus()
          const selection = $getSelection()

          if (!$isRangeSelection(selection)) {
            return false
          }

          let text = selection.getTextContent()

          if (text) {
            if (text[0] === "(" && text[text.length - 1] === ")") {
              text = text.substring(1, text.length - 1)
              selection.removeText()
              selection.insertText(text)

            }
          } else {
            const focusNode = selection.focus.getNode();
            const element =
              focusNode.getKey() === "root"
                ? focusNode
                : focusNode.getTopLevelElementOrThrow();
            const elementKey = element.getKey();

            const elementDOM = editor.getElementByKey(elementKey);

            text = elementDOM.textContent
            if (text) {

              if (text[0] === "(" && text[text.length - 1] === ")") {
                console.log(text)
                text = text.substring(1, text.length - 1)
                console.log(text)
                selection.anchor.offset = 0
                selection.focus.offset = text.length + 2
                console.log(selection.getTextContent())
                selection.deleteLine()
                selection.insertText(text)
              }


            }

            if (!$isRangeSelection(selection)) {
              return false
            }

            $wrapNodes(selection, () => $createHeadingNode("h1"));
          }
        })


      } else if (event.metaKey && event.which === 50) {
        event.preventDefault()
        editor.update(() => {
          editor.focus()
          const selection = $getSelection()

          if (!$isRangeSelection(selection)) {
            return false
          }

          let text = selection.getTextContent()

          if (text) {
            if (text[0] === "(" && text[text.length - 1] === ")") {
              text = text.substring(1, text.length - 1)
              selection.removeText()
              selection.insertText(text)

            }
          } else {
            const focusNode = selection.focus.getNode();
            const element =
              focusNode.getKey() === "root"
                ? focusNode
                : focusNode.getTopLevelElementOrThrow();
            const elementKey = element.getKey();

            const elementDOM = editor.getElementByKey(elementKey);

            text = elementDOM.textContent
            if (text) {

              if (text[0] === "(" && text[text.length - 1] === ")") {
                console.log(text)
                text = text.substring(1, text.length - 1)
                console.log(text)
                selection.anchor.offset = 0
                selection.focus.offset = text.length + 2
                console.log(selection.getTextContent())
                selection.deleteLine()
                selection.insertText(text)
              }


            }

            if (!$isRangeSelection(selection)) {
              return false
            }
          }
          $wrapNodes(selection, () => $createHeadingNode("h2"));
        })

      } else if (event.metaKey && event.which === 51) {
        event.preventDefault()
        editor.update(() => {
          editor.focus()
          const selection = $getSelection()

          if (!$isRangeSelection(selection)) {
            return false
          }

          let text = selection.getTextContent()

          if (text) {
            if (text[0] === "(" && text[text.length - 1] === ")") {
              text = text.substring(1, text.length - 1)
              selection.removeText()
              selection.insertText(text)

            }
          } else {
            const focusNode = selection.focus.getNode();
            const element =
              focusNode.getKey() === "root"
                ? focusNode
                : focusNode.getTopLevelElementOrThrow();
            const elementKey = element.getKey();

            const elementDOM = editor.getElementByKey(elementKey);

            text = elementDOM.textContent
            if (text) {

              if (text[0] === "(" && text[text.length - 1] === ")") {
                console.log(text)
                text = text.substring(1, text.length - 1)
                console.log(text)
                selection.anchor.offset = 0
                selection.focus.offset = text.length + 2
                console.log(selection.getTextContent())
                selection.deleteLine()
                selection.insertText(text)
              }


            }

            if (!$isRangeSelection(selection)) {
              return false
            }
          }
          $wrapNodes(selection, () => $createHeadingNode("h3"));
        })

      } else if (event.metaKey && event.which === 52) {
        event.preventDefault()
        editor.update(() => {
          editor.focus()
          const selection = $getSelection()

          if (!$isRangeSelection(selection)) {
            return false
          }


          let text = selection.getTextContent()

          if (text) {
            //selection.anchor.offset = selection.anchor.offset - (selection.anchor.offset + 1);
            selection.removeText()
            selection.insertText("(" + text + ")")

          } else {
            const focusNode = selection.focus.getNode();
            const element =
              focusNode.getKey() === "root"
                ? focusNode
                : focusNode.getTopLevelElementOrThrow();
            const elementKey = element.getKey();

            const elementDOM = editor.getElementByKey(elementKey);

            text = elementDOM.textContent
            if (text) {
              selection.anchor.offset = 0
              selection.focus.offset = text.length

              selection.deleteLine()
              selection.insertText("(" + text + ")")

            } else {
              selection.insertText("()")
            }

          }

          $wrapNodes(selection, () => $createHeadingNode("h4"));

          selection.focus.offset = selection.focus.offset - 1;
          selection.anchor.offset = selection.anchor.offset - 1;
        })


      } else if (event.metaKey && event.which === 53) {

        event.preventDefault()
        editor.update(() => {
          editor.focus()
          const selection = $getSelection()

          if (!$isRangeSelection(selection)) {
            return false
          }

          let text = selection.getTextContent()

          if (text) {
            if (text[0] === "(" && text[text.length - 1] === ")") {
              text = text.substring(1, text.length - 1)
              selection.removeText()
              selection.insertText(text)

            }
          } else {
            const focusNode = selection.focus.getNode();
            const element =
              focusNode.getKey() === "root"
                ? focusNode
                : focusNode.getTopLevelElementOrThrow();
            const elementKey = element.getKey();

            const elementDOM = editor.getElementByKey(elementKey);

            text = elementDOM.textContent
            if (text) {

              if (text[0] === "(" && text[text.length - 1] === ")") {
                console.log(text)
                text = text.substring(1, text.length - 1)
                console.log(text)
                selection.anchor.offset = 0
                selection.focus.offset = text.length + 2
                console.log(selection.getTextContent())
                selection.deleteLine()
                selection.insertText(text)
              }


            }

            if (!$isRangeSelection(selection)) {
              return false
            }
          }
          $wrapNodes(selection, () => $createHeadingNode("h5"));
        })


      } else if (event.metaKey && event.which === 54) {
        event.preventDefault()
        editor.update(() => {
          editor.focus()
          const selection = $getSelection()

          if (!$isRangeSelection(selection)) {
            return false
          }

          let text = selection.getTextContent()

          if (text) {
            if (text[0] === "(" && text[text.length - 1] === ")") {
              text = text.substring(1, text.length - 1)
              selection.removeText()
              selection.insertText(text)

            }
          } else {
            const focusNode = selection.focus.getNode();
            const element =
              focusNode.getKey() === "root"
                ? focusNode
                : focusNode.getTopLevelElementOrThrow();
            const elementKey = element.getKey();

            const elementDOM = editor.getElementByKey(elementKey);

            text = elementDOM.textContent
            if (text) {

              if (text[0] === "(" && text[text.length - 1] === ")") {
                text = text.substring(1, text.length - 1)
                selection.anchor.offset = 0
                selection.focus.offset = text.length + 2
                selection.deleteLine()
                selection.insertText(text)
              }


            }

            if (!$isRangeSelection(selection)) {
              return false
            }
          }
          $wrapNodes(selection, () => $createHeadingNode("h6"));
        })

      } else if (event.metaKey && event.altKey) {
        if (store.showKeyboardCommands) {
          store.showKeyboardCommands = false

        } else {
          store.showKeyboardCommands = true
        }

      } else if (event.metaKey && event.ctrlKey) {

        if (store.focusMode) {
          store.focusMode = false;
          store.closeOutput = false;
        } else {
          store.closeOutput = true;
          store.focusMode = true;

        }

      } else if (event.metaKey && event.which === 39) {

        generateAutowrite()



      }


    }




    return editor.registerRootListener((rootElement, prevRootElement) => {
      if (prevRootElement !== null) {
        prevRootElement.removeEventListener('keydown', onKeyDown)
      }
      if (rootElement !== null) {
        rootElement.addEventListener('keydown', onKeyDown)
      }
    })
  }, [editor])

  return null
}))




