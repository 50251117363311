import ListAltIcon from '@mui/icons-material/ListAlt';


const obj = {

	title: "Idea to Beat Sheet",
	desc: "Get a detailed beat sheet from an initial plot idea",
	category: "generateOutline",
	Icon: ListAltIcon,
	// tags: [],
	permissions: ['user'],
	
	fromColor: "purple-500",
	toColor: "blue-500",

	to: "/ai/editor",
	api: "/beatsheet",

	output: {
		title: "",
		desc: "",
		Icon: false,
		color: "blue",
	},

	button:{
		title: "Generate Beat Sheet",
	},

	prompts: [{
		title:"Beat Sheet",
		desc: "Describe your screenplay idea in a few sentences and get a detailed beat sheet",
		// n: 1,
		prompts: [{ 
				title: "", 
				attr: "content",  
				value: "", 
				placeholder: "Briefly describe your screenplay idea and get back a detailed beat sheet", 
				label: "",
				type: "textarea",
				minWords: 5,
				maxWords: 75,
				required: true,
				error: "",
				example: "The Sun is over 4.5 billion years old and has a temperature of around 10,000 degrees Fahrenheit. The Sun's light reaches the Earth in eight minutes. Coronal mass ejections are gases on the Sun's surface erupt, shooting far out into space.",
			},
		],
		example: {
			 output: "",
			outputs: [
				"The sun is very old, over 4.5 billion years",
				"At 10,000 degrees, sun is also very hot",
				"Gasses called coronal mass ejections erupt from the sun",
			],
			//Icon: RefreshIcon,
			color: "blue",
		}
	}]
		
}

export default obj

