import React, { useRef } from "react";
import {
  LightningBoltIcon,
} from '@heroicons/react/outline'

function Button({ title, onClick, Icon, disabled, generating, children }) {
  const buttonRef = useRef(null);


  return (
    <button
      ref={buttonRef}
      className={generating || disabled ?
        `select-none py-0 h-6.5 xl:h-7 px-5 border-2 border-gray-300 bg-gray-400 disabled hover:to-gray-700 text-white  rounded-2xl flex md:inline-flex font-medium text-l cursor-pointer md:mx-0 transition` 
        :
        `generate-button mt-1 py-0 h-9.5 xl:h-9.5  py-0.5 px-4 shadow-sm border border-slate-100 hover:shadow-md hover:drop-shadow hover:shadow-slate-500  select-none bg-sky-600 hover:bg-indigo-600 focus:shawdow-none active:shadow-inner  text-white  rounded-full flex md:inline-flex font-medium text-l cursor-pointer md:mx-0 transition`}
      onClick={generating || disabled ? null : onClick}>
      {generating ? "generating script ..." : <div className="flex items-center">
        <Icon style={{ width: "2.2rem", height: "1.57rem" }} />
        <span className="ml-1 text-[17.2px] ">Generate Script</span>
      </div>}
      {children}
    </button>
  );
}

export default Button;