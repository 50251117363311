import React, { useEffect, useState, useRef } from 'react'
import { Helmet } from "react-helmet";


import DrawerMenuNovelAdapter from './Components/DrawerMenuNovelAdapter'

import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { autorun } from "mobx";
import { observer, inject } from 'mobx-react'

import TextareaAutosize from 'react-textarea-autosize';

import Button from './Components/Button3'

import fetch from "node-fetch"

import { RecursiveCharacterTextSplitter } from "langchain/text_splitter"




import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';


import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';



import Tooltip from '@mui/material/Tooltip';
import { NavLink } from 'react-router-dom'


import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import ButtonMUI from '@mui/material/Button';


import styled from 'styled-components';

const SceneHeading = styled.div`
  padding-top: 24px;

`;

const ActionLine = styled.div`
  padding-right: 1in;
  padding-top: 12px;
`;

const CharacterLine = styled.div`
  padding-left: 2.3in;
  padding-top: 12px;
`;

const ParensLine = styled.div`
  padding-left: 1.9in;
`;

const DialogueLine = styled.div`
  padding-left: 1.1in;
  padding-right: 2in;
`;

const TransitionLine = styled.div`
  padding-left:4.5in;
  paddding-righ:0.5in;
  padding-top: 12px;
`;


// code to see if string contains any letters
function hasLetters(str) {
	return str.toLowerCase() != str.toUpperCase();
}


function countWords(text) {
	// Use a regular expression to match word boundaries
	const regex = /\b\w+\b/g;

	// Match all the words in the text
	const matches = text.match(regex);

	// Return the number of matches
	return matches ? matches.length : 0;
}


function getTitle(input) {
	// Remove newline breaks before the first word
	const sanitizedInput = input.trimStart();

	// Find the first newline break or the end of the string
	const newlineIndex = sanitizedInput.indexOf('\n');
	const endIndex = newlineIndex !== -1 ? newlineIndex : sanitizedInput.length;

	// Extract the words
	const words = sanitizedInput.substring(0, endIndex).split(' ');

	// Return the first 8 words or all the words if less than 8
	return words.slice(0, 8).join(' ');
}






function extractOddAndEvenQuotes(str) {
	const oddQuotes = [];
	const evenQuotes = [];

	const quoteRegex = /"([^"]*)"/g;
	let match;
	let index = 1;

	while ((match = quoteRegex.exec(str)) !== null) {
		if (index % 2 === 0) {
			evenQuotes.push(match[1]);
		} else {
			oddQuotes.push(match[1]);
		}
		index++;
	}

	return {
		oddQuotes,
		evenQuotes
	};
}


function LinearProgressWithLabel(props) {
	return (
		<Box sx={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'center' }}>
			<Box sx={{ maxWidth: 550, width: '40%', mr: 1 }}>
				<LinearProgress variant="determinate" {...props} />
			</Box>
			<Box sx={{ minWidth: 35 }}>
				<Typography variant="body2" color="text.secondary">{`${Math.round(
					props.value,
				)}%`}</Typography>
			</Box>
		</Box>
	);

}




function extractLinesFromJSON(output) {
	let flattenedArray = [];

	try {
		const objects = output.match(/{[^}]+}/g);
		for (const obj of objects) {
			try {
				const cleanObj = JSON.parse(obj);
				const entries = Object.entries(cleanObj);
				flattenedArray.push(...entries);
			} catch (error) {
				console.error('Error processing JSON object:', error);
				console.error('JSON object:', obj);
			}
		}
	} catch (error) {
		console.error('Error matching JSON objects:', error);
	}

	return flattenedArray;
}


function convertToXml(arr) {
	let xml = `<?xml version="1.0" encoding="UTF-8"?>
	<FinalDraft DocumentType="Script" Template="No" Version="4">
	  <Content>`;

	arr.forEach((line) => {
		let lineType = line[0];
		let lineText = line[1];
		lineText = replaceInvalidXmlChars(lineText);
		if (lineType === "Character Name") {
			lineType = "Character";
		}
		xml += `
		<Paragraph Type="${lineType}">
		  <Text>${lineText}</Text>
		</Paragraph>`;
	});
	xml += `
	  </Content>
	</FinalDraft>`;
	return xml;
}


function parseFinalDraftXML(xml) {
	const parser = new DOMParser();
	const xmlDoc = parser.parseFromString(xml, "text/xml");
	const paragraphs = xmlDoc.getElementsByTagName("Paragraph");
	const result = [];

	for (let i = 0; i < paragraphs.length; i++) {
		const paragraph = paragraphs[i];
		const type = paragraph.getAttribute("Type");
		let text = getTextFromNode(paragraph);
		text = restoreXmlChars(text);
		let headingTag;
		if ((["Scene Heading", "Action", "Character", "Parenthetical", "Dialogue", "Transition", "New Act", "Shot"].includes(type)) && text !== "") {
			if (type === "Scene Heading" || type === "New Act" || type === "Shot") {
				headingTag = "h1"
			} else if (type === "Action") {
				headingTag = "h2"
			} else if (type === "Character") {
				headingTag = "h3"
			} else if (type === "Parenthetical") {
				headingTag = "h4"
			} else if (type === "Dialogue") {
				headingTag = "h5"
			} else if (type === "Transition") {
				headingTag = "h6"
			}
			result.push({ headingTag, text });
		}
	}

	function getTextFromNode(node) {
		let text = "";
		for (let i = 0; i < node.childNodes.length; i++) {
			const child = node.childNodes[i];
			if (child.nodeType === Node.ELEMENT_NODE && child.tagName === "Text") {
				text += child.textContent.trim();
			} else {
				text += getTextFromNode(child);
			}
		}
		return text;
	}

	return result;
}




function replaceInvalidXmlChars(text) {
	return text.replace(/[<>&'"]/g, function (char) {
		switch (char) {
			case '<':
				return '&lt;';
			case '>':
				return '&gt;';
			case '&':
				return '&amp;';
			case "'":
				return '&apos;';
			case '"':
				return '&quot;';
			default:
				return char;
		}
	});
}


function restoreXmlChars(text) {
	return text.replace(/&lt;/g, '<')
		.replace(/&gt;/g, '>')
		.replace(/&amp;/g, '&')
		.replace(/&apos;/g, "'")
		.replace(/&quot;/g, '"');
}



function initRandomDocID() {
	return new Promise(resolve => {
		let result = '';
		const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		const charactersLength = characters.length;
		for (var i = 0; i < 16; i++) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength));
		}

		resolve(result);
	});
}


export const ConvertBook = inject('store')(observer(({ store }) => {

	const [value, setValue] = useState('');
	const [output, setOutput] = useState("");
	const [progress, setProgress] = useState(0);
	const [generating, setGenerating] = useState(false);
	const [currentWordCount, setCurrentWordCount] = useState(0);
	const [openWordCountDialog, setOpenWordCountDialog] = useState(false);
	const [openFinishedDialog, setOpenFinishedDialog] = useState(false);
	const [showExportButtons, setShowExportButtons] = useState(false);
	const [switchedToSavedScript, setSwitchedToSavedScript] = useState(false);
	const [intervalId, setIntervalId] = useState(null);

	const initialAdaptationId = window.location.href.split('/').pop();
	store.currentAdaptationId = initialAdaptationId;



	const retrieveOutput = async () => {
		let currentAdaptationId = store.currentAdaptationId;
			let response = await store.api.post('/user/adaptation/view', { adaptationId: currentAdaptationId })
				if (response.data.length > 0) {
					
					setSwitchedToSavedScript(true);
			
					const adaptation = [...response.data]
					const adaptationData = adaptation
						.map(({ completedChunks, totalChunks }) => {

							const percent = Math.round((completedChunks / totalChunks) * 100);

							return {
								percent
							};
						});

					setProgress(adaptationData[0].percent)
					
				
				const lines = await getGeneratedLines(currentAdaptationId);

				const allLines = lines.map((line, index) => {

					if (line[0] === "Scene Heading") {
						return <SceneHeading>{line[1]}</SceneHeading>
					}
					if (line[0] === "Action") {
						return <ActionLine>{line[1]}</ActionLine>
					}
					else if (line[0] === "Character" || line[0] === "Character Name") {
						return <CharacterLine>{line[1]}</CharacterLine>
					}
					else if (line[0] === "Parenthetical") {
						return <ParensLine>{line[1]}</ParensLine>
					}
					else if (line[0] === "Dialogue") {
						return <DialogueLine>{line[1]}</DialogueLine>
					}

					else if (line[0] === "Transition") {
						return <TransitionLine>{line[1]}</TransitionLine>
					}

					return <div>{line[1]}</div>
				});

				setOutput(allLines);

			}
	  };


	React.useEffect(() => {
	const disposer1 = autorun(async () => {
			retrieveOutput()
			if (initialAdaptationId !== store.currentAdaptationId) {
				setSwitchedToSavedScript(true)
			}
	});

		// Cleanup function to stop the autorun when the component unmounts
		return () => disposer1();
		}, []);



	



	React.useEffect(() => {
		const disposer2 = autorun(async () => {

			

			if (switchedToSavedScript && progress < 100) {
				console.log('running interval')
				const intervalId = setInterval(retrieveOutput, 5000); // 5-second interval
				return () => {
				  clearInterval(intervalId); // Cleanup function to stop the interval when the component unmounts or progress reaches 100
				};
			  }
			});
			// Cleanup function to stop the autorun when the component unmounts
			return () => disposer2();
		  }, [switchedToSavedScript, progress]);



	React.useEffect(() => {
	if (progress >= 100) {
		setShowExportButtons(true);
	}
	else { setShowExportButtons(false); }
	}, [progress]);

	const handleFileUpload = (event) => {
		const file = event.target.files[0];
		const reader = new FileReader();
		reader.onload = (e) => {
			setValue(e.target.result);
			setCurrentWordCount(countWords(e.target.result))
		};
		reader.readAsText(file);
	};


	const elementRef = useRef(null);

	const ScrollToElement = () => {

		const prevOutput = useRef('');

		useEffect(() => {
			if (elementRef.current) {
				const { scrollHeight, scrollTop, clientHeight } = elementRef.current;
				const isScrolledToBottom = scrollHeight - scrollTop === clientHeight;

				if (isScrolledToBottom) {
					elementRef.current.scrollIntoView({ behavior: "smooth" });
				}
			}
		}, [output]);

		useEffect(() => {
			prevOutput.current = output;
		});


		return (
			<>
			</>
		);


	};


	const LinearWithValueLabel = ({ progress }) => {

		return (
			<Box sx={{ width: '100%' }}>

				<LinearProgressWithLabel value={progress > 1 ? progress : 1} />
			</Box>
		);
	}

	const downloadFinalDraftFile = async (adaptationId) => {

		const lines = await getGeneratedLines(adaptationId)


		if (lines.length === 0) {
			console.log("No lines to download")
			return
		}

		const link = document.createElement('a');

		const title = "next_scene_adaptation"

		const outputXML = convertToXml(lines)

		link.setAttribute('download', title + ".fdx");
		link.setAttribute('href', 'data:text/xml;charset=utf-8,' + encodeURIComponent(outputXML));
		document.body.appendChild(link);

		// simulate a click on the link to trigger the download
		link.click();

		// remove the link from the DOM
		document.body.removeChild(link);


	}


	const saveAndOpenEditor = async (adaptationId) => {

		const lines = await getGeneratedLines(adaptationId)
		const outputXML = convertToXml(lines)

		let linesArray = []
		for (let line of parseFinalDraftXML(outputXML)) {
			linesArray.push({ "children": [{ "detail": 0, "format": 0, "mode": "normal", "style": "", "text": line["text"], "type": "text", "version": 1 }], "direction": "ltr", "format": "", "indent": 0, "type": "heading", "version": 1, "tag": line["headingTag"] })
		}



		const scriptJSON = { "root": { "children": linesArray, "direction": "ltr", "format": "", "indent": 0, "type": "root", "version": 1 } }


		const documentId = await initRandomDocID();

		await createNewDoc(documentId, adaptationId)
		await store.api.post('/user/script/update', { script: JSON.stringify(scriptJSON), documentId: documentId, adaptationId: adaptationId });

		const script = await setInitialScriptState(documentId);
		localStorage.setItem("script", script)
		window.location.href = `/ai/editor/${documentId}`;




	}


	const getGeneratedLines = async (adaptationId) => {

		let lines = [];

		let response = await store.api.post('/user/adaptationHistory/view', { adaptationId: adaptationId })
		if (response.data.length > 0) {
			const generatedData = [...response.data]
			const adaptationData = generatedData
				.filter(({ output }) => output !== undefined) // Filter out objects where output is undefined
				.map(({ output }) => ({ output }));

			for (let i = 0; i < adaptationData.length; i++) {

				let outputString = adaptationData[i].output
				// replace consecutive brackets
				outputString = outputString.replace(/\[\s*\[/g, "[");

				lines = lines.concat(extractLinesFromJSON(outputString))

			}

		}


		return lines;
	}


	const createNewDoc = async (documentId, adaptationId) => {

		let imageIndex = 0;
		let response = await store.api.post('/user/adaptation/view', { adaptationId: adaptationId })
		if (response.data.length > 0) {
			const generatedData = [...response.data]
			imageIndex = generatedData[0].dashboardImageIndex
		}

		await store.api.post('/user/document', { documentId: documentId, dashboardImageIndex: imageIndex });
	}


	const setInitialScriptState = async (documentId) => {


		let result;
		let response = await store.api.post('/user/script/view', { documentId: documentId })
		if (response.data.length > 0) {
			const scriptState = [...response.data]
			result = scriptState[0].script
			return result
		}
		return null
	}






	const maxChunkSize = 14000;
	const minChunkSize = 5000;


	//const first5000Words = value.split(" ").slice(0, 5000).join(" ");


	let chunkSize = value.length / 50;
	//let chunkSize = first5000Words.length / 60;

	if (chunkSize > maxChunkSize) {
		chunkSize = maxChunkSize;
	} else if (chunkSize < minChunkSize) {
		chunkSize = minChunkSize;
	}



	const splitter = new RecursiveCharacterTextSplitter({
		chunkSize: chunkSize,
		chunkOverlap: 1,
	});


	const adaptationId = window.location.href.split('/').pop();

	const onGenerateClick = async () => {


		if (currentWordCount < 2000 || currentWordCount > 125000) {
			setOpenWordCountDialog(true);

		} else {




			setGenerating(true);

			let justTesting = false;

			let allLines = [];

			setOutput([]);

			store.generationSourceCategory = "generateScreenplay"

			const chunks = await splitter.createDocuments([value]);
			//const chunks = await splitter.createDocuments([first5000Words]);

			const wordCount = countWords(value);
			setCurrentWordCount(wordCount);
			const title = getTitle(value);

			//await store.api.post('/user/adaptation/updateStarted', { adaptationId: adaptationId, started: true });
			//await store.api.post('/user/adaptation/updateTitle', { adaptationId: adaptationId, title: title });


			let adaptationImageIndex = localStorage.getItem('adaptationImageIndex')
			if (adaptationImageIndex === null) {
				adaptationImageIndex = 0;
			} else {
				adaptationImageIndex = parseInt(adaptationImageIndex)
			}
			await store.api.post('/user/adaptation', { adaptationId: adaptationId, dashboardImageIndex: adaptationImageIndex, title: title, chunkCount: chunks.length, wordCount: wordCount });


			/* 	await store.api.post('/user/adaptation/updateTotalChunks', { adaptationId: adaptationId, chunkCount: chunks.length });
				await store.api.post('/user/adaptation/updateWordCount', { adaptationId: adaptationId, wordCount: wordCount });
				await store.api.post('/user/adaptation/updateTitle', { adaptationId: adaptationId, title: title }); */

			const addProgress = Math.round((100 / chunks.length) * 10) / 10




			if (justTesting) {

				//setOutput(prevOutput => `${prevOutput}${chunkValue}`); 

				/* async function processChunks() {
					for (let chunk of chunks) {
					  await new Promise(resolve => setTimeout(resolve, 2000));
					  setOutput(prevOutput => `${prevOutput}${chunk.pageContent}`);
					  setProgress(progress => progress + addProgress);
					}
				  }
				  
				  processChunks(); */

				let response;





				let postObj = {}
				postObj.user = store.profile._id
				postObj.email = store.profile.email


				postObj.content = chunks
				postObj.adaptationId = adaptationId

				response = await fetch(store.baseURL_AI + "/convertBook", {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify(postObj),
				});

				// This data is a ReadableStream
				const data = await response.text();
				if (!data) {
					//console.log(response)
					return;
				}

				//await store.api.post('/user/adaptation/updateCompletedChunks', { completedChunks: completedChunks, adaptationId: adaptationId });
				//await store.api.post('/user/adaptationHistory', { adaptationId: adaptationId, chunkNumber: completedChunks, input: chunk.pageContent, outputText: data  });
				setOutput(prevOutput => `${prevOutput}${data}`);
				setProgress(progress => progress + addProgress);
				//await new Promise(resolve => setTimeout(resolve, 2000));





			} else {



				try {


					let postObj = {}
					postObj.user = store.profile._id
					postObj.email = store.profile.email
					postObj.adaptationId = adaptationId
					postObj.content = chunks

					let response;

					response = await fetch(store.baseURL_AI + "/convertBook", {
						method: 'POST',
						headers: {
							'Content-Type': 'application/json',
						},
						body: JSON.stringify(postObj),
					});

					// This data is a ReadableStream
					const data = response.body;
					if (!data) {
						//console.log(response)
						return;
					}



					const reader = data.getReader();
					const decoder = new TextDecoder();


					let done = false;
					/* let inQuotes = false;
					let keyValCount = 1;
					let currentLineType = "";
					let lineText = "";
					let seenOpeningParen = false; */
					let currentLine = "";
					let tempLine = "";


					/* const firstQuoteRegex = /"([^"]*)"[^"]*"/;
					const secondQuoteRegex = /(?:[^"]*"){3}([^"]*)"/;
	 */


					while (!done) {
						const { value, done: doneReading } = await reader.read();
						//console.log(this.props.store.streaming)
						done = doneReading;

						const chunkValue = decoder.decode(value);

						if (chunkValue === "%%%%%") {
							setProgress(progress => progress + addProgress);
							continue;
						}


						store.scriptWriteOutput += chunkValue;

						if (!chunkValue.includes("}")) {
							currentLine += chunkValue

						} else {
							if (chunkValue.includes("{")) {
								tempLine = "{" + chunkValue.split("{")[1];
								currentLine += chunkValue.split("{")[0];
							} else {
								tempLine = "";
								currentLine += chunkValue
							}

							let { oddQuotes, evenQuotes } = extractOddAndEvenQuotes(currentLine)

							for (let i = 0; i < oddQuotes.length; i++) {

								console.log(currentLine)
								let lineType = oddQuotes[i]
								console.log(lineType)
								let lineText = evenQuotes[i]
								console.log(lineText)


								if (lineType && lineText) {

									let styledLine = "";

									if (lineType === "Scene Heading") {
										styledLine = <SceneHeading>{lineText}</SceneHeading>
									}
									else if (lineType === "Action") {
										styledLine = <ActionLine>{lineText}</ActionLine>
									}
									else if (lineType === "Character" || lineType === "Character Name") {
										styledLine = <CharacterLine>{lineText}</CharacterLine>
									}
									else if (lineType === "Parenthetical") {
										styledLine = <ParensLine>{lineText}</ParensLine>
									}
									else if (lineType === "Dialogue") {
										styledLine = <DialogueLine>{lineText}</DialogueLine>
									}

									else if (lineType === "Transition") {
										styledLine = <TransitionLine>{lineText}</TransitionLine>
									}


									setOutput((prevOutput) => [...prevOutput, styledLine]);

								}
							}

							allLines.push(currentLine);
							currentLine = tempLine;



						}


						/* 
											console.log(chunkValue)
											console.log(inQuotes)
						
											if (inQuotes) {
												
												if (chunkValue.includes('"')) {
													inQuotes = false;
													if (keyValCount % 2 === 0) {
						
														lineText = chunkValue.split('"')[0];
														//lineText = lineText.trim();
						
														if (currentLineType === "Parenthetical") {
						
															if (!lineText.endsWith(")")) {
																lineText = lineText + ")";
															}
														}
													
															setOutput(prevOutput => `${prevOutput}${lineText}`); 
															seenOpeningParen = false;
													
															if (currentLineType === "Character Name" || currentLineType === "Character" || currentLineType === "Parenthetical") {
																setOutput(prevOutput => `${prevOutput}${"\n"}`);
															} else {
																setOutput(prevOutput => `${prevOutput}${"\n\n"}`);
															}
								
						
													}
													else {
														currentLineType += chunkValue.split('"')[0];
													}
						
													keyValCount++;
						
												} else if (keyValCount % 2 === 0) {
													lineText = chunkValue;
													
													if (currentLineType === "Parenthetical" && !seenOpeningParen) {
														if (lineText.trim().startsWith("(")) {
															seenOpeningParen = true;
														} else if (hasLetters(lineText)) {
															lineText = "(" + lineText;
															seenOpeningParen = true;
														}
													}
													
													setOutput(prevOutput => `${prevOutput}${lineText}`); 
													
												} else {
													currentLineType += chunkValue;
												}
						
						
											} else if (chunkValue.includes('"')) {
												inQuotes = true;
						
												if (keyValCount % 2 === 0) {
													
													if (currentLineType === "Character Name" || currentLineType === "Character") {
														setOutput(prevOutput => `${prevOutput}${"\t\t\t\t"}`);
													} else if (currentLineType === "Parenthetical") {
														setOutput(prevOutput => `${prevOutput}${"\t\t"}`);
													}
													
													lineText = chunkValue.split('"')[1]
													lineText = lineText.trim();
													if (currentLineType === "Parenthetical") {
														if (lineText.startsWith("(")) {
															seenOpeningParen = true;
														} else if (hasLetters(lineText)) {
															lineText = "(" + lineText;
															seenOpeningParen = true;
														}
													}
										
													setOutput(prevOutput => `${prevOutput}${lineText}`); 
										
												} else {
													currentLineType = "";
													currentLineType = chunkValue.split('"')[1];
												}
						
											} */


					}
					setProgress(100);
					setOpenFinishedDialog(true);
					setGenerating(false);






					// Pause for 1 second before running the next iteration
					//setTimeout(() => {}, 1000);




				} catch (error) {
					console.log(error)

				}

			}

		}

	}


	return (

		<>
			<Helmet>
				<title>{`Next Scene - Adapt Book`}</title>
			</Helmet>

			<Dialog
				open={openWordCountDialog}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"

			>
				{currentWordCount < 2000 ?
					<DialogTitle id="alert-dialog-title">
						{"Book to Screenplay requires a minimum of 2000 words"}
					</DialogTitle>
					: <DialogTitle id="alert-dialog-title">
						{"Book to Screenplay has a maximum of 125,000 words"}
					</DialogTitle>
				}

				<DialogActions>
					<ButtonMUI variant="outlined" autofocus onClick={() => setOpenWordCountDialog(false)}>Got it</ButtonMUI>
				</DialogActions>
			</Dialog>



			<Dialog
				open={openFinishedDialog}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"

			>

				<DialogTitle id="alert-dialog-title">
					{"Your screenplay is ready! Choose one of the options below (you can always come back later)."}
				</DialogTitle>

				<DialogActions>
					<ButtonMUI variant="contained" size="small" onClick={() => { downloadFinalDraftFile(adaptationId) }}>Export to Final Draft file</ButtonMUI>
					<ButtonMUI variant="contained" size="small" onClick={() => { saveAndOpenEditor(adaptationId) }} color="secondary">Open in New Editor</ButtonMUI>
					<ButtonMUI variant="outlined" size="small" onClick={() => { setOpenFinishedDialog(false); setShowExportButtons(true) }} color="success">Maybe Later</ButtonMUI>
				</DialogActions>
			</Dialog>

			<div className="flex justify-between px-0 md:px-0 flex select-none">
				<div>
					<Tooltip title="Back to dashboard">
						<NavLink to="/"
							exact

						>

							<div

								activeClassName="bg-gray-100 hover:bg-gray-200 text-gray-800 transition"
								className="active:shadow-inner active:shadow-purple-200 bg-white border-r border-t border-b border-indigo-700 rounded-r-xl  shadow-md shadow-gray-300 text-md flex py-1 px-1 lg:py-2 lg:px-1 w-16 cursor-pointer hover:bg-indigo-100  font-medium transition justify-center items-center"
							>
								< ArrowBackIosNewIcon />
								<div />
							</div>
						</NavLink>
					</Tooltip>
				</div>


				{!generating ?

					<DrawerMenuNovelAdapter />

					: null}



			</div>


			<div className="flex flex-col brightness-110  items-center justify-center rounded-b-xl ">

				{!output ?

					<div className={`mt-[5vh]  w-[82vw] border border-blue-900 text-gray-700 font-light rounded-xl hover:shadow-lg hover:shadow-indigo-400 align-bottom bg-slate-100  text-left overflow-hidden transform transition-all sm:align-middle transition  focus:shadow-2xl md:mb-8 `}>

						<div className="px-6 py-6">

							<div className="flex items-center">



								<div className="mt-0 ml-4 text-left">
									<div as="h2" style={{ fontFamily: 'Coal' }} className="text-[23px] leading-6 font-light text-transparent bg-clip-text bg-gradient-to-l from-fuchsia-800 to-sky-700 mb-4  brightness-125 ">

										{"Book → Screenplay"}

									</div>

									<p className="text-[16px] font-mdeium text-slate-700 ">

										{"Paste text below"}

										<em><span>&ensp;or&ensp;</span></em>

										<label htmlFor="file-input" className=" hover:shadow-fuchsia-800 hover:shadow-sm shadow-sm shadow-indigo-100 bg-neutral-50 text-slate-600 font-medium py-1 px-4 rounded-xl cursor-pointer border border-slate-400 active:shadow-inner">
											Upload a file
										</label>
										<input
											type="file"
											disabled={generating}
											id="file-input"
											style={{ display: "none" }}
											accept=".txt"
											onChange={handleFileUpload} />

									</p>

								</div>

							</div>
							<div className={`mt-4`}>



								<div className="relative">





									<TextareaAutosize
										autoFocus={true}
										minRows={10}
										maxRows={13.5}
										name={"test"}
										value={value}
										onChange={(event) => { setValue(event.target.value); setCurrentWordCount(countWords(event.target.value)) }}

										className={`outline-none focus:outline-none text-lg bg-white rounded-md px-4 py-4  min-w-full border border-gray-600 font-regular focus:border-gray-800 "border-gray-300 font-regular mt-2 `}

									/>
								</div>

								{currentWordCount === 1 ?
									<div className={`text-xs mt-1  text-red-600 font-bold z-70 bottom-0 left-1 select-none pointer-events-none transition-all top px-2 ""}`}>
										{currentWordCount} word
									</div>
									: currentWordCount > 1 ?

										<div className={`text-xs mt-1  ${currentWordCount < 2000 ? "text-red-600" : "text-sky-700"}  font-bold z-70 bottom-0 left-1 select-none pointer-events-none transition-all top px-2 ""}`}>
											{currentWordCount} words
										</div>

										:

										<div className='mt-3'> </div>}

								<div className="md:flex place-content-center ">

									<Button
										title={"Generate Script"}
										disabled={value.length == 0}
										generating={generating}
										Icon={ElectricBoltIcon}
										onClick={() => { onGenerateClick() }}


									/>

								</div>



							</div>



						</div>


					</div>

					: null}


				{showExportButtons ?
					<div className='flex grid gap-8 grid-cols-2 mt-6 '>
						<ButtonMUI variant="contained" size="small" onClick={() => { downloadFinalDraftFile(store.currentAdaptationId) }}>Export to Final Draft file</ButtonMUI>

						<ButtonMUI variant="contained" size="small" onClick={() => { saveAndOpenEditor(store.currentAdaptationId) }} color="secondary">Open in New Editor</ButtonMUI>
					</div>

					: null
				}


				{output ?
					<>
						<div className='mt-4'></div>
						<LinearWithValueLabel progress={progress} />
					</>

					: null}

				{/* {output ? <ScrollToElement/>
				



					: null}
 */}

				{output ?

					<div className="bg-white w-[850px] mt-3 mb-1 rounded-xl min-h-[15vh] border border sky-900 hover:shadow-lg shadow-md " >

						<div
							className="output-component whitespace-pre-wrap overflow-y-scroll border max-h-[93vh] min-h-[93vh] text-gray-800 text-md  px-4 pb-5 mb-4"
							style={{}}
						>


							<div className='pl-28 mt-16'>

								{output}

							</div>

						</div>
					</div>
					:
					null
				}


			</div>

		</>
	);




}))






export default ConvertBook;