import React, { useState, useRef, useEffect } from 'react';
import Transition from '../utils/Transition';


import MovieFilterIcon from '@mui/icons-material/MovieFilter';
import ListAltIcon from '@mui/icons-material/ListAlt';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';


import EntryPrompt from '../../Components/EntryPromptLanding';

function Features() {

  const [tab, setTab] = useState(1);

  const tabs = useRef(null);

  const heightFix = () => {
    if (tabs.current.children[tab]) {
      tabs.current.style.height = tabs.current.children[tab - 1].offsetHeight + 'px'
    }
  }

  useEffect(() => {
    heightFix()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab])

  return (
    <section className="relative">


      {/* Section background (needs .relative class on parent and next sibling elements) */}

      <div className="absolute inset-0 bg-slate-100 pointer-events-none" aria-hidden="true"></div>
      <div className="absolute left-0 right-0 m-auto w-px p-px h-28 bg-gray-200 transform -translate-y-1/4"></div>


      <div className="relative max-w-6xl mx-auto px-4 sm:px-6 pb-12">
        <div className="pt-12 md:pt-20">

          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-11 md:pb-11">
            <div data-aos="fade-right" style={{ fontFamily: 'coal' }} className=" mt-4 text-[50px] h-18 mb-0 relative font-medium leading-tighter tracking-tighter text-transparent bg-clip-text brightness-125 bg-gradient-to-r from-sky-800 to-fuchsia-600  mb-0 ">Next Scene</div>
            <p data-aos="fade-left" className="text-xl text-gray-600">An AI writing partner designed to boost your creativity.</p>
          </div>

          {/* Section content */}
          <div className="md:grid md:grid-cols-12 md:gap-6">

            {/* Content */}
            <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6 md:mt-4" data-aos="fade-right">
              <div className="md:pr-4 lg:pr-12 xl:pr-16 mb-8">
                <h3 className="h3 mb-3 text-gray-700">Features to support your craft.</h3>
                <p className="text-[18px] text-gray-600">Outlines, beat sheets, character arcs, summaries, feedback ... Next Scene is ready to colloborate. Explore a few examples: </p>
              </div>
              {/* Tabs buttons */}
              <div className="mb-8 md:mb-0">
                <a
                  className={`rounded-2xl flex items-center text-lg p-5 rounded border transition duration-300 ease-in-out mb-3 ${tab !== 1 ? 'bg-white shadow-md border-gray-200 hover:shadow-lg' : 'bg-indigo-50 border-indigo-300'}`}
                  href="#0"
                  onClick={(e) => { e.preventDefault(); setTab(1); }}
                >
                  <div>
                    <div className="font-bold leading-snug text-blue-900 tracking-tight mb-1">Opening Scene</div>
                    <div className="font-light text-gray-600">Skip the blank page - a brief description of your plot is all that's needed to generate the first scene.  </div>
                  </div>
                  <div className="flex justify-center items-center w-8 h-8 bg-white rounded-full shadow flex-shrink-0 ml-3 text-sky-600">
                    <MovieFilterIcon />
                  </div>
                </a>
                <a
                  className={`rounded-2xl flex items-center text-lg p-5 rounded border transition duration-300 ease-in-out mb-3 ${tab !== 2 ? 'bg-white shadow-md border-gray-200 hover:shadow-lg' : 'bg-indigo-50 border-indigo-300'}`}
                  href="#0"
                  onClick={(e) => { e.preventDefault(); setTab(2); }}
                >
                  <div>
                    <div className="font-bold leading-snug text-blue-900 tracking-tight mb-1">Beat Sheet</div>
                    <div className="font-light text-gray-600">From the inciting incident to the finale, develop the perfect structure for your story.</div>
                  </div>
                  <div className="flex justify-center items-center w-8 h-8 bg-white rounded-full shadow flex-shrink-0 ml-3 text-sky-600">
                    <ListAltIcon />
                  </div>
                </a>
                <a
                  className={`rounded-2xl flex items-center text-lg p-5 rounded border transition duration-300 ease-in-out mb-3 ${tab !== 3 ? 'bg-white shadow-md border-gray-200 hover:shadow-lg' : 'bg-indigo-50 border-indigo-300'}`}
                  href="#0"
                  onClick={(e) => { e.preventDefault(); setTab(3); }}
                >
                  <div>
                    <div className="font-bold leading-snug text-blue-900 tracking-tight mb-1">Character Arc</div>
                    <div className="font-light text-gray-600">Generate infinite arcs and backstories, because no two characters are perfectly alike.</div>
                  </div>
                  <div className="flex justify-center items-center w-8 h-8 bg-white rounded-full shadow flex-shrink-0 ml-3 text-sky-600">
                    <PeopleAltOutlinedIcon />
                  </div>
                </a>
              </div>
            </div>

            {/* Tabs items */}
            <div className="mt-10 ml-2  max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-4 md:mb-8 md:order-1" data-aos="zoom-y-out" ref={tabs}>
              <div className="relative flex flex-col text-center lg:text-right">
                {/* Item 1 */}
                <Transition
                  show={tab === 1}
                  appear={true}
                  className="w-full"
                  enter="transition ease-in-out duration-700 transform order-first"
                  enterStart="opacity-0 translate-y-16"
                  enterEnd="opacity-100 translate-y-0"
                  leave="transition ease-in-out duration-300 transform absolute"
                  leaveStart="opacity-100 translate-y-0"
                  leaveEnd="opacity-0 -translate-y-16"
                >
                  <div className="relative inline-flex flex-col">
                    <EntryPrompt
                      title={"Opening Scene"}
                      icon={MovieFilterIcon}
                      input={"An extra on the set of a sci-fi movie opens a door and finds herself transported to an alternate universe. "}
                      output={`INT. SOUNDSTAGE - DAY

A spacious soundstage bustling with activity as CREW MEMBERS prepare for the next scene of a sci-fi movie. The various space-themed set pieces dwarf the humans working on them. Cameras, lights, and other equipment are scattered around the set. It's noisy with the sounds of hammering, welding, and people shouting.

A young extra, JAMIE, late 20s, with glasses and a quirky fashion sense, stands near a fake alien bar, holding a prop space gun. Jamie watches the chaos around her in wonder, starstruck by the glamour of the movie set.

                                  ASSISTANT DIRECTOR
                  Alright, everybody, we're setting up for the 
                  big shootout at Quasar's Saloon. Let's get 
                  those alien prosthetics ready!

Jamie practices her 'sci-fi' poses and facial expressions, attempting to look fierce and mysterious.

                                  JAMIE
                            (practicing)
                  I am the last hope for the Intergalactic 
                  Alliance...
  
Jamie accidentally fires their prop space gun, creating a harmless but loud 'pew-pew' sound.

                                  GRUFF CREW MEMBER
                  Hey! Watch where you're pointing that thing,
                  rookie!

                                  JAMIE
                            (embarrassed)
                  Sorry!

Jamie rolls her eyes and wanders away from the commotion, looking for a quiet place to practice. She notices a nondescript door, partially hidden by a hanging tarp.

                                  JAMIE
                            (muttering)
                  What's behind door number one?

Jamie cautiously opens the door to find a dimly lit room filled with various odds and ends, props, and costumes.

INT. PROP ROOM - DAY

As she steps in, the noise from the soundstage fades away, replaced by an eerie silence. A peculiar, ancient-looking door catches Jamie's attention. Intricate carvings and symbols adorn the doorframe.

                                  JAMIE
                            (intrigued)
                  Weird. Must be a prop for the movie...

Unable to resist, Jamie reaches for the door handle and turns it. The door opens, and a powerful, otherworldly light bursts from behind it. Jamie shields her eyes, but curiosity gets the best of her.

                                  JAMIE
                            (yells)
                  Action!

                                                                                                CUT TO:

EXT. ALTERNATE UNIVERSE - UNKNOWN

Jamie lands roughly on a strange, ethereal landscape. The sky is a dazzling array of colors, and bizarre plants sway without wind. There are no discernible suns, moons, or stars. Odd-looking creatures roam the land, seemingly unfazed by Jamie's presence.

                                  JAMIE
                            (in awe)
                  This... can't be a set. Where am I?

As if in response, a MYSTERIOUS FIGURE appears before Jamie, a humanoid silhouette shrouded in glimmering light.`}
                    />

                    
                  </div>
                </Transition>
                {/* Item 2 */}
                <Transition
                  show={tab === 2}
                  appear={true}
                  className="w-full"
                  enter="transition ease-in-out duration-700 transform order-first"
                  enterStart="opacity-0 translate-y-16"
                  enterEnd="opacity-100 translate-y-0"
                  leave="transition ease-in-out duration-300 transform absolute"
                  leaveStart="opacity-100 translate-y-0"
                  leaveEnd="opacity-0 -translate-y-16"
                >
                  <div className="relative inline-flex flex-col">
                    <EntryPrompt
                      title={"Beat Sheet"}
                      icon={ListAltIcon}
                      input={"An ingenious house cat secretly works on a rocket that will allow it to reach the moon."}
                      output={`Opening Image:
We see Cosmo, a gray tabby cat, surveying his neighborhood from the window of a ranch house. He gazes at the sky, watching birds soar and clouds move, clearly wishing he could be up there. His eyes reflect a yearning for something greater, something beyond his suburban life.

Theme Stated:
Cosmo longs for adventure and exploration, away from the ordinary and predictable life he leads. He dreams of the wider world, of the unknown and the impossible, and of one day soaring in the sky like the birds.

Set-Up:
Cosmo reveals his secret rocket prototype, hidden beneath a pile of old newspapers in the basement of a nearby abandoned house. He begins gathering materials like scrap metal and discarded electronics, slowly piecing together his unlikely creation.

Catalyst:
In a moment of inspiration, Cosmo realizes that he can use discarded pizza as rocket fuel. He sets to work, gathering more materials and fine-tuning his design, while also sneaking leftover pizza scraps from neighborhood trash cans.

Debate:
Cosmo faces a host of obstacles and setbacks along the way, including limited resources, a lack of expertise, and the overwhelming sense that he's in over his head. He's afraid of failure and of the ridicule that might come from his fellow cats if they found out what he was up to. He also struggles with feelings of loneliness and isolation as he keeps his secret from those around him.

Break into Two:
Cosmo decides to take a leap of faith and test his rocket prototype. He ignites the engines and achieves liftoff for a brief moment before crashing back down to Earth. Despite the crash, he's exhilarated by the experience and even more determined to succeed.

B Story:
Cosmo's desire for adventure and exploration is both fueled and hindered by his relationship with the other cats in the neighborhood. He longs to share his dreams with them but fears their rejection and judgment. Cosmo is particularly afraid of disappointing his closest friend, Luna - a wise, elderly cat who has always believed in him.

Fun and Games:
Cosmo continues to work on and test the rocket, making adjustments and improvements. He has some close calls, but also experiences moments of pure joy and wonder as he gets closer and closer to his goal. Luna eventually discovers Cosmo's secret project and, to his surprise, offers her support and encouragement.

Midpoint:
Cosmo manages to to propel himself out of earth's atmosphere. He gazes down at the patchwork of cities, oceans, and mountains, in awe of what he's accomplished.

Bad Guys Close In:
Cosmo's victory is short-lived when he realizes that his rocket is malfunctioning. He's stranded in space and must figure out how to fix the problem before he runs out of oxygen. Back on Earth, word of Cosmo's daring adventure spreads among the other cats, with some admiring his bravery and others mocking his foolishness.

All is Lost:
Cosmo crashes back to Earth, feeling defeated and uncertain. He's lost the sense of validation and purpose that came with achieving part of his dream, and he's not sure where to go from here. To make matters worse, some of the neighborhood cats gather to mock Cosmo's failure.

Dark Night of the Soul:
Cosmo contemplates giving up on his dream and returning to his safe, mundane life. He's afraid of being seen as a failure or a laughingstock, and he's not sure if he can handle the disappointment. Luna finds Cosmo in his lowest moment and reminds him of his passion for adventure and exploration.

Break into Three:
Cosmo, with renewed determination and the support of Luna, decides to try one more time. Together, they work to repair the rocket and make it better than ever.

Finale:
Cosmo successfully repairs the rocket and launches back into space. He achieves his dream of traveling to the moon, experiencing the breathtaking beauty of space and the thrill of low gravity. He returns to Earth safely, hailed as a hero by his fellow cats. His journey has inspired them to pursue their own dreams and passions, no matter how unlikely they may seem. Even the cats who mocked Cosmo's initial failure come to admire his bravery and resilience.

Final Image:
Cosmo looks out proudly at the world that he has explored and conquered. He's still longing for adventure and excitement, but he's also grateful for the journey that brought him to where he is now. He knows that there are still many more worlds to discover, and that he's ready for whatever comes next. By his side, Luna gazes out with him, a proud look in her eyes as she shares in Cosmo's triumph.`}
                    />
                   
                  </div>
                </Transition>
                {/* Item 3 */}
                <Transition
                  show={tab === 3}
                  appear={true}
                  className="w-full"
                  enter="transition ease-in-out duration-700 transform order-first"
                  enterStart="opacity-0 translate-y-16"
                  enterEnd="opacity-100 translate-y-0"
                  leave="transition ease-in-out duration-300 transform absolute"
                  leaveStart="opacity-100 translate-y-0"
                  leaveEnd="opacity-0 -translate-y-16"
                >
                  <div className="relative inline-flex flex-col">
                    <EntryPrompt
                      title={"Character Arc"}
                      icon={PeopleAltOutlinedIcon}
                      input={"After he loses his law practice following an ill-advised bet on his abilities at the basketball game HORSE, a lawyer travels to Alaska to train with a basketball guru."}
                      output={`Character Overview and Backstory:

Name: Benjamin "Ben" Thompson
Age: 35
Occupation: Former lawyer

Ben Thompson was a successful lawyer with an impressive career spanning over a decade. He was confident, ambitious, and well-respected by his peers. However, his seemingly perfect life was brought to an abrupt halt when he lost his entire law practice due to an ill-advised bet on his abilities at the basketball game HORSE.

Despite never playing basketball at a professional level, Ben was known amongst his friends and colleagues for his skills in the game of HORSE. Driven by his competitive nature and the desire to prove his doubters wrong, Ben challenged a wealthy investor to a high-stakes game of HORSE. The wager: his entire law practice against a substantial investment in his firm.

To Ben's shock and disbelief, he lost the game and his law practice, causing his life to spiral out of control. His career was in shambles, his reputation tarnished, and his personal relationships strained. In a desperate search for redemption and a way out of his misery, Ben stumbles upon an article about a basketball guru in Alaska who claims he can make anyone into the greatest HORSE player of all time.

Character Arc Outline:

1. Introduction to Ben's life before the bet: thriving law practice, strong relationships with friends and family, a loving wife, and a passion for the game of HORSE.

2. The fateful bet: an intense, nail-biting game of HORSE that ultimately leads to Ben's crushing defeat and the loss of his law practice.

3. The fallout: Ben struggles with shame, guilt, and anger as he watches his professional life crumble. His marriage starts to suffer as a result of his obsession with the game and his reluctance to accept his defeat.

4. The discovery: Ben comes across an article about a basketball guru in Alaska and becomes intrigued by his claims of making anyone into the greatest HORSE player of all time. This rekindles Ben's passion for the game and gives him a sense of purpose and a chance at redemption.

5. The decision: After much contemplation and a heart-to-heart conversation with his wife, Ben makes the decision to temporarily leave his old life behind and travel to Alaska to train with the guru.

6. Arrival in Alaska: Ben arrives in the remote Alaskan town where the guru lives and faces various challenges as he adjusts to the cold climate and the unconventional training methods of the guru.

7. Training montages: Through rigorous physical and mental training, Ben starts to unlock his true potential and develops a deep understanding of the game of HORSE.

8. New friendships: During his time in Alaska, Ben forms strong bonds with the local community and other trainees who are on a similar quest for self-improvement.

9. The test: The guru organizes a local HORSE tournament as an opportunity for Ben and the other trainees to test their newfound abilities against one another.

10. The moment of truth: In the final match of the tournament, Ben faces off against an opponent who seems unbeatable. Mustering all the strength and skill he has gained during his training, Ben manages to win the game in a dramatic, triumphant finish.

11. The return home: With his confidence restored and a sense of accomplishment, Ben returns to his old life, determined to rebuild his career and mend his relationships with a newfound humility and perspective.

12. Redemption: Ben successfully rebuilds his law practice and reestablishes his reputation. He also improves his relationships with his wife, family, and friends by being more present and humble.

Alternate Arcs:

A. Instead of returning to his old life as a lawyer, Ben decides to follow his newfound passion for basketball and becomes a coach for a local youth team. He inspires and trains the next generation of players and finds new meaning and purpose in life.

B. Ben's wife, inspired by his journey, decides to join him in Alaska, where they both train together and rediscover their passion for each other and the game of HORSE. They eventually decide to settle down in Alaska and open their own basketball training center.

C. Ben's journey to becoming the greatest HORSE player of all time leads him on a path of self-discovery and self-acceptance. In the end, he finally realizes he'd be happier playing table tennis.`}
                    />
                    
                  </div>
                </Transition>
              </div>
            </div >

          </div >

        </div >
      </div >
    </section >
  );
}

export default Features;
