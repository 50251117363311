import React, { Component, useState } from 'react'
import { observer, inject } from 'mobx-react'
import TextareaAutosize from 'react-textarea-autosize';
import '../css/style-sheet.css'

import SwitchAccessShortcutIcon from '@mui/icons-material/SwitchAccessShortcut';

import Typewriter from "typewriter-effect";



const ComponentStyles = () => (
	<style jsx global>{`
	
	.whitespace-pre-wrap::-webkit-scrollbar {
		width: 5px;
		height: 4px;
		
	
	  }
  
	  .whitespace-pre-wrap::-webkit-scrollbar-thumb {
		background-color:#ccd3eb;
		border-radius: 25px;
		margin-top: 4px;

	  }
  
	  
  
	  .whitespace-pre-wrap::-webkit-scrollbar-corner {
		background-color: #f5f5f5;
	  }
  
	  /* Always show the scrollbar */
	  .whitespace-pre-wrap::-webkit-scrollbar {
		visibility: visible;
	  }

	  .whitespace-pre-wrap::-webkit-scrollbar-track {
		visibility: visible !important;
	  }
	  
	`}</style>
);



@inject("store")
@observer
class EntryPrompt extends Component {

	constructor(props) {
		super(props);
		this.state = {
			showOutput: false
		};
		this.textareaRef = React.createRef();
		this.buttonClick = this.buttonClick.bind(this);
	}




	componentDidMount() {
		window.addEventListener('resize', this.handleResize);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleResize);
	}

	

	buttonClick() {
		this.setState({
			showOutput: true
		})
	}





	render() {

		let hidden = false

		this.props.store.toolInput = this.props.value


		const title = this.props.title

		const Icon = this.props.icon

		let {showOutput} = this.state


		return (
			<div>
				{!showOutput ?
				<div> 
					<div className="mr-12 mt-20 shadow-lg shadow-indigo-300 p-4 pt-3 bg-[#fafafc] text-gray-700 font-light align-bottom md:rounded-3xl text-left overflow-hidden transform transition-all sm:align-middle transition">

						

						<div className="px-4 py-1 mt-0 ">


							<div className="flex items-center">

								<div>

									<div className="mt-0 flex justify-self-center " >
										<div className='text-[26px] font-light text-sky-700' style={{ fontFamily: 'bangers' }}>

											{title}

										</div>

									</div>

								</div>







							</div>


							<div className={"mt-1"}>



								<div className="relative flex flex-col text-left">




									<TextareaAutosize disabled
											ref={this.textareaRef}
											minRows={10}
											maxRows={10}
											value={this.props.input}
											className={"shadow-inner pt-4 pr-3 outline-none focus:outline-none text-md bg-white rounded-md w-[24rem] text-left py-2 placeholder:text-neutral-400 text-gray-700  font-medium min-w-full font-regular border-slate-500 border font-regular mt-2 mb-5"}
											style={{ resize: "none", paddingLeft: '20px' }}
									/>
								</div>


							</div>
							<div className='flex justify-center'>
								<button
									className={
										`text-center mt-0 py-0.5 px-5 shadow-sm border border-slate-100 hover:shadow-md hover:drop-shadow-md  select-none bg-sky-600 hover:bg-sky-500 focus:shawdow-none text-white  rounded-full flex md:inline-flex font-medium text-l cursor-pointer md:mx-0 transition`}
										onClick={this.buttonClick}>
										<Icon className= "mt-[2.7px] mr-1" style={{ width: "1.7rem", height: "1.2rem" }} /> Generate
								</button>
							</div>
							
						</div>

					</div>

						<SwitchAccessShortcutIcon className="md:max-w-none absolute w-full text-fuchsia-600 bottom-10 transform animate-float" alt="Element" style={{ height: 29, width: 29, top: '86%', left: '61.5%', rotate: "286deg", transform: 'scaleX(-1)' }} />
						<span
							className="text-fuchsia-600 bottom-10 text-[17px] transform animate-float"
							style={{
								position: 'absolute',
								top: '90.8%',
								left: '68.5%',
								transform: 'translate(-50%, -50%)',
								zIndex: 1,
								rotate: "352deg"
							}}
						>
							try it out!
						</span>

				</div>

			:
			<div className="mr-0 ml-5  mt-0 shadow-lg shadow-indigo-300 pb-3 pt-3 bg-[#fafafc] text-gray-700 font-light align-bottom md:rounded-3xl text-left overflow-hidden transform transition-all sm:align-middle transition">
				
				<div className="px-4 text-left" 
				>
					<div className="output-component whitespace-pre-wrap overflow-y-scroll  grow-1 text-gray-800 w-[32rem] text-md text-left px-4 pt-5"
					style={{maxHeight:"30rem"}}
						 
					>

					<ComponentStyles />

                    <Typewriter
                        options={{ delay: 0.5, cursor: "", }}
                        onInit={(typewriter) => {
                            typewriter.typeString(this.props.output).start();
                        }}
                    
                    />

					</div>
				</div>
			</div>


	}



			</div>


		)
	}
}





export default EntryPrompt;