import "../../css/lexical-scene.css";

const exampleTheme = {
    ltr: "ltr",
  rtl: "rtl",
    placeholder: "editor-placeholder",
    paragraph: "editor-paragraph-script",
    heading: {
      h1: "editor-text-heading",
      h2: "editor-text-action",
      h3: "editor-text-character",
      h4: "editor-text-parens",
      h5: "editor-text-dialogue",
      h6: "editor-text-transition"
    },

    image: "editor-image",
    link: "editor-link",
    text: {
      bold: "editor-text-bold",
      italic: "editor-text-italic",
      overflowed: "editor-text-overflowed",
      hashtag: "editor-text-hashtag",
      underline: "editor-text-underline",
      strikethrough: "editor-text-strikethrough",
      underlineStrikethrough: "editor-text-underlineStrikethrough",

      
    },
    
  };
  
  export default exampleTheme;
  