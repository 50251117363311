module.exports = [





   /*  {
        "voice_id": "toDnBBuRW6sFC5CX2jYH",
        "name": "Male #1",
        "alt_name": "Dylan2",
        "samples": ["09rs2gTkP7kXBovp6jIF", ],
        "category": "conversational",
        "available_for_tiers": [],
        "settings": null,
        "qualities" : { tone: "amiable", timbre: "mellow", pitch: "medium-low", speed: "medium"}
    }, */



   /*  {
        "voice_id": "XX79W1vjjOrTMN5Ib0yQ",
        "name": "Male #1",
        "alt_name": "Andrew",
        "samples": ["uvqtc4v8y77X5cMcnhVt", ],
        "category": "conversational",
        "available_for_tiers": [],
        "settings": null,
        "qualities" : { tone: "engaged", timbre: "rolling", pitch: "medium", speed: "medium"},
    },

    {
        "voice_id": "fYfk6kFJdAWwv62XwoV5",
        "name": "Male #2",
        "alt_name": "Bobby",
        "samples": ["Thq2OWrHG5yMrz4gQe23", ],
        "category": "conversational",
        "available_for_tiers": [],
        "settings": null,
        "qualities" : { tone: "relaxed", timbre: "smooth", pitch: "medium-low", speed: "medium"},
    },

 */

    {
        "voice_id": "Ak8Q9BCWt2s6elb5hCUT",
        "name": "Male #1",
        "alt_name": "Will2",
        "samples": ["fsJmtLliHVO5BSnZ0qjD", ],
        "category": "conversational",
        "available_for_tiers": [],
        "settings": null,
        "qualities" : { tone: "introspective", timbre: "deep", pitch: "low", speed: "medium"},
    },


   /*  {
        "voice_id": "wOruexzet0QuVgppPzDP",
        "name": "Male #2",
        "alt_name": "Blippi3",
        "samples": ["gZqmAVoSyi3cVYkSkTtF", ],
        "category": "conversational",
        "available_for_tiers": [],
        "settings": null,
        "qualities" : { tone: "carefree", timbre: "light", pitch: "medium-high", speed: "medium"},
    }, */


    {
        "voice_id": "HsAbjaYHh4D6uJJeQC8n",
        "name": "Male #2",
        "alt_name": "Larry",
        "samples": ["JkHN8eGb9FT5jIsee0uf", ],
        "category": "conversational",
        "available_for_tiers": [],
        "settings": null,
        "qualities" : { tone: "adamant", timbre: "brassy", pitch: "low", speed: "medium"},
    },


    {
        "voice_id": "6q9L1wbc6N2w4K27DSoP",
        "name": "Male #3",
        "alt_name": "Colin",
        "samples": ["HTaa91xqB3l06injgU3M", ],
        "category": "conversational",
        "available_for_tiers": [],
        "settings": null,
        "qualities" : { tone: "energetic", timbre: "compressed", pitch: "medium", speed: "medium-fast"},
    },



    {
        "voice_id": "KPCoCZVbC9Kuqg6gACoX",
        "name": "Male #4",
        "alt_name": "James",
        "samples": ["TgGjHPOvubXpvQ71AbRi", ],
        "category": "conversational",
        "available_for_tiers": [],
        "settings": null,
        "qualities" : { tone: "amused", timbre: "twangy", pitch: "medium", speed: "medium"},
    },


    {
        "voice_id": "HXqvYLxTcj4r1vLgR7j4",
        "name": "Male #5",
        "alt_name": "Morgan",
        "samples": ["OCVB1sm65GGAKYtMkKHi", ],
        "category": "conversational",
        "available_for_tiers": [],
        "settings": null,
        "qualities" : { tone: "calming", timbre: "weathered", pitch: "low", speed: "medium"}
    },


    {
        "voice_id": "ezjbJ8pJZRL9ukS2X7b8",
        "name": "Male #6",
        "alt_name": "Fifty",
        "samples": ["U9XmDYBjs7qCUio9XuZS", ],
        "category": "conversational",
        "available_for_tiers": [],
        "settings": null,
        "qualities" : { tone: "savvy", timbre: "full", pitch: "medium-low", speed: "medium-slow"}
    },






    {
        "voice_id": "fx9eBkB7fwckEL5iC6Vm",
        "name": "Male #7",
        "alt_name": "Shawn",
        "samples": ["WzktqdR09BdB5ycbRNUG", ],
        "category": "conversational",
        "available_for_tiers": [],
        "settings": null,
        "qualities" : {tone: "sympathetic", timbre: "crisp", pitch: "medium", speed: "medium"}
    },




    {
        "voice_id": "JLY09bbyzNCKldeVeW3v",
        "name": "Male #8",
        "alt_name": "Henry",
        "samples": ["vSxOcCLPCKNj9mLpEGVG", ],
        "category": "conversational",
        "available_for_tiers": [],
        "settings": null,
        "qualities" : {tone: "alert", timbre: "slightly sibilant", pitch: "medium-high", speed: "medium-fast"}
    },


    /* {
        "voice_id": "UdMe4M3ENXpKbGjylvaV",
        "name": "Male #4",
        "alt_name": "Sean",
        "samples": ["2K6R5WUQpSiH20gALhs9", ],
        "category": "conversational",
        "available_for_tiers": [],
        "settings": null,
        "qualities" : {tone: "serious", timbre: "clear", pitch: "medium-low", speed: "medium"}
    }, */


   {
        "voice_id": "ErXwobaYiN019PkySvjV",
        "name": "Male #9",
        "alt_name": "Antoni",
        "samples": ["WFydJNpkTOwikp0ejPi4", ],
        "category": "conversational",
        "available_for_tiers": [],
        "settings": null,
        "qualities" : {tone: "unimpressed", timbre: "smooth", pitch: "medium", speed: "medium"}
    }, 
    /* {
        "voice_id": "VzNBLpF81PfoMnjYAbZU",
        "name": "Male #6",
        "alt_name": "Amos",
        "samples": ["Dm2mradqatAPBagHqkdW", ],
        "category": "conversational",
        "available_for_tiers": [],
        "settings": null,
        "qualities" : {tone: "direct", timbre: "reedy", pitch: "medium-low", speed: "medium"}
    },
 */

    {
        "voice_id": "wtSGdgHARI5irJ3j7mBc",
        "name": "Male #10",
        "alt_name": "Thomas",
        "samples": ["Cq4Uqmf1SZuoP3uXHkSd", ],
        "category": "narration",
        "available_for_tiers": [],
        "settings": null,
        "qualities" : { tone: "flat", timbre: "heavy", pitch: "medium-low", speed: "medium-fast"}
    },

    {
        "voice_id": "FaISpi1W6uiIrlMWUxD6",
        "name": "Male #11",
        "alt_name": "Gary",
        "samples": ["bD10PO1hb4V6BCW92qKK", ],
        "category": "narration",
        "available_for_tiers": [],
        "settings": null,
        "qualities" : { tone: "confident", timbre: "slightly gravelly", pitch: "medium-low", speed: "medium"}
    },



    {
        "voice_id": "1hScQfwNRocHSIbfc1DV",
        "name": "Male #12",
        "alt_name": "William",
        "samples": ["HivWDHICsYbRlY61YgPH", ],
        "category": "narration",
        "available_for_tiers": [],
        "settings": null,
        "qualities" : { tone: "wearied", timbre: "clear", pitch: "medium-low", speed: "medium-slow"}
    },

    

  /*   {
        "voice_id": "ftjLLxwobVOtlslNS9zo",
        "name": "Male #13",
        "alt_name": "Leo",
        "samples": ["uvfEB4oI7Mvro0u4M6Av", ],
        "category": "narration",
        "available_for_tiers": [],
        "settings": null,
        "qualities" : { tone: "forthright", timbre: "resonant", pitch: "low", speed: "medium"}
    },
 */

    

  
    
    
    

    // use as narrator
  /*   {
        "voice_id": "ww9FNAlM9jHQGGm38iS2",
        "name": "Male #8",
        "alt_name": "Bob",
        "samples": ["35aJZVf2WfiWCuWt7Rsy", ],
        "category": "conversational",
        "available_for_tiers": [],
        "settings": null
    }, */

    {
        "voice_id": "RrsO92SRzk4PPOIfVghT",
        "name": "Male (British) #1",
        "alt_name": "Andy",
        "samples": ["FjhgmTcUIWpmqmNo3xgT", ],
        "category": "conversational",
        "available_for_tiers": [],
        "settings": null,
        "qualities" : {tone: "engrossing", timbre: "rich", pitch: "medium-low", speed: "medium", }
    },


    {
        "voice_id": "mLr1ZFpDORNX5c431S6S",
        "name": "Male (British) #2",
        "alt_name": "Liam",
        "samples": ["TkXvUjDNEtAKmqzVmUuT", ],
        "category": "conversational",
        "available_for_tiers": [],
        "settings": null,
        "qualities" : {tone: "impactful", timbre: "grainy", pitch: "medium", speed: "medium-fast", }
    },

    {
        "voice_id": "Vla2uJaiJtgErRyo24Mf",
        "name": "Male (British) #3",
        "alt_name": "David",
        "samples": ["JgDowCMb8rktEyMhv0gt", ],
        "category": "conversational",
        "available_for_tiers": [],
        "settings": null,
        "qualities" : {tone: "light", timbre: "springy", pitch: "medium-high", speed: "medium-fast", }
    },







   /*  {
        "voice_id": "I8FH4X7Zg7KHfBAW88n5",
        "name": "Female #1",
        "alt_name": "Meekah",
        "samples": ["JPaQPn5LrogyvzyQIQyr", ],
        "category": "conversational",
        "available_for_tiers": [],
        "settings": null,
        "qualities" : {tone: "joyful", timbre: "bouncy", pitch: "high", speed: "fast" }
    },
 */

    {
        "voice_id": "Ba8zmmg4JramThGJ82yE",
        "name": "Female #1",
        "alt_name": "Susan",
        "samples": ["Iqu8p8uB1SqvWNyih3jL", ],
        "category": "conversational",
        "available_for_tiers": [],
        "settings": null,
        "qualities" : {tone: "thoughtful", timbre: "complex", pitch: "medium-low", speed: "medium-slow", }
    },

    {
        "voice_id": "EFA13cyZHFTaa9YZR9k4",
        "name": "Female #2",
        "alt_name": "Glenn",
        "samples": ["lk67bkqfXnVaap5pwyNc", ],
        "category": "conversational",
        "available_for_tiers": [],
        "settings": null,
        "qualities" : {tone: "emphatic", timbre: "full", pitch: "medium-low", speed: "medium", }
    },


    {
        "voice_id": "YmtSuOkENe07MwJUl5km",
        "name": "Female #3",
        "alt_name": "Ali",
        "samples": ["wRGQvshu5ZA3JjkbKARx", ],
        "category": "conversational",
        "available_for_tiers": [],
        "settings": null,
        "qualities" : {tone: "amused", timbre: "slight fry", pitch: "medium", speed: "medium-fast", }
    },


   

    {
        "voice_id": "5lKzvCjVhx5ak0fk2TUx",
        "name": "Female #4",
        "alt_name": "Alana",
        "samples": ["6JtEzfOAoNUaRuWXUkfj", ],
        "category": "conversational",
        "available_for_tiers": [],
        "settings": null,
        "qualities" : {tone: "optimistic", timbre: "bright", pitch: "medium-high", speed: "medium", }
    },

  

    {
        "voice_id": "8ojVsddQxmKCBLPtuHHt",
        "name": "Female #5",
        "alt_name": "Elena",
        "samples": ["RisISXf67qrGV0LZa8qB", ],
        "category": "conversational",
        "available_for_tiers": [],
        "settings": null,
        "qualities" : {tone: "convincing", timbre: "clear", pitch: "medium", speed: "medium", }
    },

    {
        "voice_id": "EohFAmExoU2aLZrRCOht",
        "name": "Female #6",
        "alt_name": "Lena",
        "samples": ["JN3hU4KOgtkKxoxVmiSN", ],
        "category": "conversational",
        "available_for_tiers": [],
        "settings": null,
        "qualities" : {tone: "confessional", timbre: "velvety", pitch: "medium-low", speed: "medium", }
    },


    {
        "voice_id": "EXAVITQu4vr4xnSDxMaL",
        "name": "Female #7",
        "alt_name": "Bella",
        "samples": ["mrJeCYXLcbqUon0lOldk", ],
        "category": "conversational",
        "available_for_tiers": [],
        "settings": null,
        "qualities" : {tone: "imploring", timbre: "slightly nasal", pitch: "medium", speed: "medium", }
    },

  

    {
        "voice_id": "YOeThIkYMOpG8z8ht4ko",
        "name": "Female #8",
        "alt_name": "Sheila",
        "samples": ["9eoj5FNe0n5SYNDtfCRz", ],
        "category": "conversational",
        "available_for_tiers": [],
        "settings": null,
        "qualities" : {tone: "neutral", timbre: "clear", pitch: "medium", speed: "medium", }
    },


    
    {
        "voice_id": "naBBs1LnBD8OHBGrwtcW",
        "name": "Female #9",
        "alt_name": "Michelle",
        "samples": ["I3TGGq3UTpSjlEeoTKJy", ],
        "category": "conversational",
        "available_for_tiers": [],
        "settings": null,
        "qualities" : {tone: "confiding", timbre: "silky", pitch: "medium-low", speed: "medium-fast", }
    },

    {
        "voice_id": "2OqfkXiMClF0V7cwb8Te",
        "name": "Female #10",
        "alt_name": "Jessica",
        "samples": ["51NtmZXPgUgs2fWLWE5y", ],
        "category": "conversational",
        "available_for_tiers": [],
        "settings": null,
        "qualities" : {tone: "solemn", timbre: "clear", pitch: "medium-low", speed: "medium-slow", }
    },
    
   
    {
        "voice_id": "ZuQcMtqgRRlFUPvXvipe",
        "name": "Female #11",
        "alt_name": "Marla",
        "samples": ["DaIm3OVMvus1GSVNkuEI", ],
        "category": "conversational",
        "available_for_tiers": [],
        "settings": null,
        "qualities" : { tone: "discerning", timbre: "slightly raspy", pitch: "medium-low", speed: "medium",}
    },

    {
        "voice_id": "eeao4d2zkMdfEhyXwG8T",
        "name": "Female #12",
        "alt_name": "Marsha",
        "samples": ["ASQ9Nj19ZpjtlCXidXHK", ],
        "category": "conversational",
        "available_for_tiers": [],
        "settings": null,
        "qualities" : { tone: "hopeful", timbre: "slightly nasally", pitch: "medium-high", speed: "medium",}
    },

   

    
    {
        "voice_id": "RMfubAqvv7YEFOHgbswc",
        "name": "Female (British) #1",
        "alt_name": "Helen",
        "samples": ["5aZ359vjyAAV1oNWCXnP", ],
        "category": "conversational",
        "available_for_tiers": [],
        "settings": null,
        "qualities" : { tone: "gentle", timbre: "soft", pitch: "medium", speed: "medium",}
    },
   /*  {
        "voice_id": "ZsSBQqyi6cdov6KNf6Ht",
        "name": "Female (British) #2",
        "alt_name": "Lynn",
        "samples": ["2YUXw7MXmsVROVAChvuY", ],
        "category": "conversational",
        "available_for_tiers": [],
        "settings": null,
        "qualities" : { tone: "precise", timbre: "clear", pitch: "medium-low", speed: "medium",}
    }, */
    {
        "voice_id": "lPwGHr18bGX6L8CP7l5M",
        "name": "Female (British) #2",
        "alt_name": "Anna",
        "samples": ["OcrtDoTyyEyZq0jr46EK", ],
        "category": "conversational",
        "available_for_tiers": [],
        "settings": null,
        "qualities" : { tone: "focused", timbre: "clear", pitch: "medium", speed: "medium-fast",}
    },
    {
        "voice_id": "MrOuq37nI1EXvvh6QAX",
        "name": "Female (British) #3",
        "alt_name": "Betty",
        "samples": ["0rBLt4YcYR8zQb5i0aXT", ],
        "category": "conversational",
        "available_for_tiers": [],
        "settings": null,
        "qualities" : { tone: "patient", timbre: "slightly sibilant", pitch: "medium-high", speed: "medium-fast",}
    },

    







    // removed
    
   /*  
   
    {
        "voice_id": "AZnzlk1XvdvUeBnXmlld",
        "name": "Female #11",
        "alt_name": "Domi",
        "samples": ["PepxEQhyPKiiL0VgwwOc", ],
        "category": "conversational",
        "available_for_tiers": [],
        "settings": null
    },

   
   
   {
        "voice_id": "MF3mGyEYCl7XYWbV9V6O",
        "name": "Female #4",
        "alt_name": "Elli",
        "samples": ["E9m2dC0DCHUCnYR2Sil9", ],
        "category": "conversational",
        "available_for_tiers": [],
        "settings": null
    },
     */

    /*  {
        "voice_id": "pNInz6obpgDQGcFmaJgB",
        "name": "Male #6",
        "alt_name": "Adam",
        "samples": ["Ag7VP2n6gxqYf796u7Yf", ],
        "category": "narration",
        "available_for_tiers": [],
        "settings": null
    }, */
    

   /*  {
        "voice_id": "yoZ06aMxZJJ28mfd3POQ",
        "name": "Male #9",
        "alt_name": "Sam",
        "samples": ["oXJZAyFUSGHusAFFFfO1", ],
        "category": "conversational",
        "available_for_tiers": [],
        "settings": null
    }, */

     /* {
        "voice_id": "21m00Tcm4TlvDq8ikWAM",
        "name": "Female #10",
        "alt_name": "Rachel",
        "samples": ["Q5Tts6f0iDmi7kPeq8Wp", ],
        "category": "conversational",
        "available_for_tiers": [],
        "settings": null
    },
    */



   /*  {
        "voice_id": "VR6AewLTigWG4xSOukaG",
        "name": "Male #7",
        "alt_name": "Arnold",
        "samples": ["j5XviC4GCWAJAX87XB1f", ],
        "category": "conversational",
        "available_for_tiers": [],
        "settings": null,
        "qualities" : {tone: "ingratiating", timbre: "nasally", pitch: "medium-high", speed: "medium"}
    }, */
   



   
 /*    {
        "voice_id": "6TZ9My1vecVcKz0OfQR2",
        "name": "Male #3",
        "alt_name": "Stan",
        "samples": ["rwn2P8lc84g0YoDK391i", ],
        "category": "conversational",
        "available_for_tiers": [],
        "settings": null,
        "qualities" : {tone: "warm", timbre: "slight fry", pitch: "medium", speed: "medium-fast"}
    }, */


/*     {
        "voice_id": "F2MdGpdZlxbkPS5vjAhf",
        "name": "Female #2",
        "alt_name": "Amber",
        "samples": ["pqRXJanpr3n2ujn59ei4", ],
        "category": "conversational",
        "available_for_tiers": [],
        "settings": null,
        "qualities" : {tone: "hesitant", timbre: "slightly sibilant", pitch: "medium-high", speed: "medium-fast", }
    }, */


]