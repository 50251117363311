import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import * as React from "react"
import { createPortal } from "react-dom"

const DropDownContext = React.createContext(null)

export function DropDownItem({ children, className, onClick, title }) {
  const ref = useRef(null)

  const dropDownContext = React.useContext(DropDownContext)

  if (dropDownContext === null) {
    throw new Error("DropDownItem must be used within a DropDown")
  }

  const { registerItem } = dropDownContext

  useEffect(() => {
    if (ref && ref.current) {
      registerItem(ref)
    }
  }, [ref, registerItem])

  return (
    <button
      className={className}
      onClick={onClick}
      ref={ref}
      title={title}
      type="button"
    >
      {children}
    </button>
  )
}

function DropDownItems({ children, dropDownRef, onClose }) {
  const [items, setItems] = useState()
  const [highlightedItem, setHighlightedItem] = useState()

  const registerItem = useCallback(
    itemRef => {
      setItems(prev => (prev ? [...prev, itemRef] : [itemRef]))
    },
    [setItems]
  )

  const handleKeyDown = event => {
    if (!items) return

    const key = event.key

    if (["Escape", "ArrowUp", "ArrowDown", "Tab"].includes(key)) {
      event.preventDefault()
    }

    if (key === "Escape" || key === "Tab") {
      onClose()
    } else if (key === "ArrowUp") {
      setHighlightedItem(prev => {
        if (!prev) return items[0]
        const index = items.indexOf(prev) - 1
        return items[index === -1 ? items.length - 1 : index]
      })
    } else if (key === "ArrowDown") {
      setHighlightedItem(prev => {
        if (!prev) return items[0]
        return items[items.indexOf(prev) + 1]
      })
    }
  }

  const contextValue = useMemo(
    () => ({
      registerItem
    }),
    [registerItem]
  )

  useEffect(() => {
    if (items && !highlightedItem) {
      setHighlightedItem(items[0])
    }

    if (highlightedItem && highlightedItem.current) {
      highlightedItem.current.focus()
    }
  }, [items, highlightedItem])

  return (
    <DropDownContext.Provider value={contextValue}>
      <div className="dropdown" ref={dropDownRef} onKeyDown={handleKeyDown}>
        {children}
      </div>
    </DropDownContext.Provider>
  )
}

export default function DropDown({
  disabled = false,
  buttonLabel,
  buttonAriaLabel,
  buttonClassName,
  buttonIconClassName,
  children,
  stopCloseOnClickSelf
}) {
  const dropDownRef = useRef(null)
  const buttonRef = useRef(null)
  const [showDropDown, setShowDropDown] = useState(false)

  const handleClose = () => {
    setShowDropDown(false)
    if (buttonRef && buttonRef.current) {
      buttonRef.current.focus()
    }
  }

  useEffect(() => {
    const button = buttonRef.current
    const dropDown = dropDownRef.current

    if (showDropDown && button !== null && dropDown !== null) {
      const { top, left } = button.getBoundingClientRect()
      dropDown.style.top = `${top + 40}px`
      dropDown.style.left = `${Math.min(
        left,
        window.innerWidth - dropDown.offsetWidth - 20
      )}px`
    }
  }, [dropDownRef, buttonRef, showDropDown])

  useEffect(() => {
    const button = buttonRef.current

    if (button !== null && showDropDown) {
      const handle = event => {
        const target = event.target
        if (stopCloseOnClickSelf) {
          if (dropDownRef.current && dropDownRef.current.contains(target))
            return
        }
        if (!button.contains(target)) {
          setShowDropDown(false)
        }
      }
      document.addEventListener("click", handle)

      return () => {
        document.removeEventListener("click", handle)
      }
    }
  }, [dropDownRef, buttonRef, showDropDown, stopCloseOnClickSelf])

  return (
    <>
      <button
        disabled={disabled}
        aria-label={buttonAriaLabel || buttonLabel}
        className={buttonClassName}
        onClick={() => setShowDropDown(!showDropDown)}
        ref={buttonRef}
      >
        {buttonIconClassName && <span className={buttonIconClassName} />}
        {buttonLabel && (
          <span className="text dropdown-button-text">{buttonLabel}</span>
        )}
        <i className="chevron-down" />
      </button>

      {showDropDown &&
        createPortal(
          <DropDownItems dropDownRef={dropDownRef} onClose={handleClose}>
            {children}
          </DropDownItems>,
          document.body
        )}
    </>
  )
}
