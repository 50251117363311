export function Body2(props) {
	return(
		<div className={`  overflow-hidden ${props.className || " mt-0 "}`}>{props.children}</div>
	)
}

export function Grid(props) {
	return(
		<div className="grid grid-cols-6 lg:gap-4 xl:grid-cols-12 xl:gap-4  md:gap-1">{props.children}</div>
	)
}



export function Col({span, start, children, className}) {
	return(
		<div className={`col-span-${span || "6"} col-start-${start || "1"}  ${className}`}>{children}</div>
	)
}

export default Body2