import React, { Component } from 'react'
import { computed } from 'mobx'
import { observer, inject } from 'mobx-react'
import TextareaAutosize from 'react-textarea-autosize';
import DropdownMenu from './Dropdown'
import '../css/style-sheet.css'
import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import { Dropdown, Container, Col } from "@nextui-org/react";



const ContextSwitch = styled(Switch)(({ theme }) => ({
	padding: 8,
	'& .MuiSwitch-track': {
	  borderRadius: 22 / 2,
	  '&:before, &:after': {
		content: '""',
		position: 'absolute',
		top: '50%',
		transform: 'translateY(-50%)',
		width: 16,
		height: 16,
	  },
	  '&:before': {
		backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
		  theme.palette.getContrastText(theme.palette.primary.main),
		)}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
		left: 12,
	  },
	  '&:after': {
		backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
		  theme.palette.getContrastText(theme.palette.primary.main),
		)}" d="M19,13H5V11H19V13Z" /></svg>')`,
		right: 12,
	  },
	},
	'& .MuiSwitch-thumb': {
	  boxShadow: 'none',
	  width: 16,
	  height: 16,
	  margin: 2,
	},
  }));




const countWords = (str) => {
	let wordCount = 0;
	let match = str.match(/\b[-\w']+\b/gi);
	if (match) {
		wordCount = match.length;
	}
	return wordCount;
}









@inject("store")
@observer
class WritingPartner extends Component {

	constructor(props) {
		super(props);
		this.textareaRef = React.createRef();
		this.state = { selectedValue: null,
					   isScreenWidth1280OrLess: window.innerWidth < 1280,
					};
		this.setSelectedValue = this.setSelectedValue.bind(this);
		this.handleResize = this.handleResize.bind(this);
	}



	setSelectedValue(e) {
		this.setState({ selectedValue: e.target });
		if (e.target.checked) {
		this.props.store.sceneContext = true
		} else {
		this.props.store.sceneContext = false
		}
		}


	onChange = async (e) => {
		const { prompt } = this.props;
		const value = e.target.value;

		this.setState({
			textareaValue: value
		});

		prompt.value = value;
		this.props.store.error = "";
		this.props.store.currentInput = value
	};


	handlePaste = async (e) => {
		this.props.store.currentInput = " ";
	};


	handleFileUpload = (event) => {
		const file = event.target.files[0];
		const reader = new FileReader();
		reader.onload = (e) => {
			this.props.prompt.value = e.target.result;
		};
		reader.readAsText(file);
	};

	componentDidMount() {
		window.addEventListener('resize', this.handleResize);
	  }
	
	  componentWillUnmount() {
		window.removeEventListener('resize', this.handleResize);
	  }
	
	  handleResize() {
		this.setState({
		  isScreenWidth1280OrLess: window.innerWidth < 1280,
		});
	  }


	@computed get currentNumberOfWords() {
		let maxWords = 1000;
		let currentWords = countWords(this.props.prompt.value);

		if (this.props.maxWords) {
			maxWords = this.props.maxWords
		}

		return { wordString: `${currentWords} / ${maxWords} words`, aboveMax: currentWords > maxWords }
	}




	render() {

		const { isScreenWidth1280OrLess } = this.state;

		return (
			<div >
			
				<div className={`px-1 py-0 mt-0 ${isScreenWidth1280OrLess ? 'mr-1' : 'mr-3'}`}>
		

					<div className={`${this.props.size ? "mt-1" : "mt-1"}`}>

					

						<div className="relative flex flex-col">
						<div className='ml-3 mt-1'>
				<FormControlLabel
        control={<ContextSwitch/>}
        label={<span style={{ fontSize: '.95rem' }}>include Script context</span>}
		onChange={this.setSelectedValue}
      />
	  </div>

							



							{this.props.prompt.type === "textarea" && <TextareaAutosize
								ref={this.textareaRef}
								minRows={8.74}
								maxRows={8.74}
								name={this.props.prompt.attr}
								placeholder={"Anything I can help out with?"}
								id={this.props.prompt.attr}
								value={this.props.value}
								className={`shadow-inner outline-none focus:outline-none text-${this.props.size || "md"} placeholder:text-[#4a89b0] text-gray-700 bg-white rounded-xl ${this.props.size ? "px-2 py-2" : "px-4 py-4"}  min-w-full border-2 border-sky-700 font-regular focus:border-sky-700 focus:border ${this.props.store.error ? "border-red-400" : "border-gray-300"} font-regular mt-2 mb-4 `}
								onChange={this.onChange}
								onPaste={this.handlePaste}


							/>}
						</div>

						{(this.props.prompt.label || this.props.store.error) && <div
							htmlFor={this.props.prompt.attr}
							className={`${this.props.store.error ? "text-red-400" : "text-gray-400"} text-s transition-all line mb-1`}>
							{this.props.store.error || this.props.store.label}
						</div>}
					</div>
					{this.props.children}
				</div>
			</div>

		)
	}
}





export default WritingPartner;