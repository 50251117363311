import React, { Component } from 'react';
import { observer, inject,  } from 'mobx-react'

import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

import Loader from './Loader'


const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : '#edf7f7',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));



@inject('store')
@observer
class FeedbackAccordions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
    };
		this.init();
  }


  init = async () => {
		this.refreshFeedback()
	}



  refreshFeedback = async () => {
		let response = await this.props.store.api.post('/user/feedbackAI/view',  {documentId: window.location.href.split('/').pop()})
    if (response.data.length > 0) {
      const feedbackItems = [...response.data]
      const feedbackValues = feedbackItems.map((item) => item.data)
      this.props.store.savedFeedbackItems = feedbackValues
     
    }

	}


  handleChange = (panel) => (event, isExpanded) => {
    this.setState({ expanded: isExpanded ? panel : false });
  };

  render() {
    const { items } = this.props;
    const { expanded } = this.state;

    return (
      <div>
      <div> {this.props.store.isFeedbackLoading   ? <div className="flex justify-center mb-2">
			<Loader active={this.props.store.isFeedbackLoading } className="w-10 h-10" />
		</div> : <>

		</>}</div>
        {items.map((item, index) => (
  <Accordion
    key={index}
    expanded={expanded === `panel${index}`}
    onChange={this.handleChange(`panel${index}`)}
  >
    <AccordionSummary
      aria-controls={`panel${index}d-content`}
      id={`panel${index}d-header`}
    >
      <Typography sx={{ width: '33%', flexShrink: 0, color: 'rgb(30 58 138)', fontWeight: 'bold' }}>
        {item.summary}
      </Typography>
      <Typography sx={{ color: 'text.secondary' }}>{item.details}</Typography>
    </AccordionSummary>
    <AccordionDetails>
      <pre className='mt-0 mb-0 p-1 '>
        <Typography
          className='overflow-y-scroll whitespace-pre-wrap pl-5 pr-4'
          style={{ maxHeight: '50vh' }}
        >
          {item.content.includes('>>') || item.content.includes('//')
  ? item.content
      .split('\n')
      .map((line, index) => {
        
        // the line below is throwing an error for safari
        //const modifiedLine = line.match(/(?<!\n)>>/) ? line.replace(/(?<!\n)>>/g, "\n>>") : line;
        
        // alternate approach
        const modifiedLine = line.replace(/\s?>>/g, '\n>>');
        
        const lines = modifiedLine.split('\n');
        //console.log(lines)
        return (
          <span key={index}>
            {lines.map((line, index) => (
              <span
                key={index}
                style={{ color: line.trim().startsWith('>>') ? '#2a27ba' : line.trim().startsWith('//') ? '#33498a' : 'inherit' }}
              >
                {line.trim()==="" ? null : line.trim().startsWith('>>') || line.trim().startsWith('//')  ? line + "\n\n" : line + "\n" }
              </span>
            ))}
          </span>
        );
      })
  : item.content
}
        </Typography>
      </pre>
    </AccordionDetails>
  </Accordion>
))}
      </div>
    );
  }
}

export default FeedbackAccordions;