import "../../css/floating-toolbar.css"

import { observer, inject } from 'mobx-react'
import { autorun } from "mobx";

import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { mergeRegister } from "@lexical/utils"
import {
  $getSelection,
  $isRangeSelection,
  $isTextNode,
  $getNodeByKey,
  $getRoot,
  COMMAND_PRIORITY_LOW,
  FORMAT_TEXT_COMMAND,
  SELECTION_CHANGE_COMMAND,
  $setSelection,
} from "lexical"
import { useCallback, useEffect, useRef, useState } from "react"
import * as React from "react"
import { createPortal } from "react-dom"

import { getDOMRangeRect } from "../utils/getDOMRangeRect"
import { getSelectedNode } from "../utils/getSelectedNode"
import { setFloatingElemPosition } from "../utils/setFloatingElemPosition"
import { INSERT_INLINE_COMMAND } from "../plugins/CommentPlugin"

import VideoCameraBackIcon from '@mui/icons-material/VideoCameraBack';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import SummarizeIcon from '@mui/icons-material/Summarize';
import GradingIcon from '@mui/icons-material/Grading';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import EditIcon from '@mui/icons-material/Edit';
import StyledSnackbar from '../lexical-components/Snackbar'


import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MUIButton from '@mui/material/Button';
import { CompressOutlined } from "../../../node_modules/@mui/icons-material/index";
import {Divider as MUIDivider} from '@mui/material';


const TextFormatFloatingToolbar = inject('store')(observer(({
  streaming,
  store,
  editor,
  anchorElem,
  isBold,
  isItalic,
  isUnderline,
  isStrikethrough
}) => {
  const popupCharStylesEditorRef = useRef(null)

  function findIndex(arr, n) {
    let index = arr.indexOf(n);
    if (index !== -1) {
      return index;
    }
    for (let i = arr.length - 1; i >= 0; i--) {
      if (arr[i] < n) {
        return i;
      }
    }
    return -1; // n is smaller than all values in the array
  }

  // code to see if string contains any letters
  function hasLetters(str) {
    return str.toLowerCase() != str.toUpperCase();
  }



  function mouseMoveListener(e) {
    if (
      popupCharStylesEditorRef?.current &&
      (e.buttons === 1 || e.buttons === 3)
    ) {
      popupCharStylesEditorRef.current.style.pointerEvents = "none"
    }
  }
  function mouseUpListener(e) {
    if (popupCharStylesEditorRef?.current) {
      popupCharStylesEditorRef.current.style.pointerEvents = "auto"
    }
  }

  useEffect(() => {
    if (popupCharStylesEditorRef?.current) {
      document.addEventListener("mousemove", mouseMoveListener)
      document.addEventListener("mouseup", mouseUpListener)

      return () => {
        document.removeEventListener("mousemove", mouseMoveListener)
        document.removeEventListener("mouseup", mouseUpListener)
      }
    }
  }, [popupCharStylesEditorRef])

  const updateTextFormatFloatingToolbar = useCallback(() => {
    const selection = $getSelection()

    const popupCharStylesEditorElem = popupCharStylesEditorRef.current
    const nativeSelection = window.getSelection()

    if (popupCharStylesEditorElem === null) {
      return
    }

    const rootElement = editor.getRootElement()
    if (
      selection !== null &&
      nativeSelection !== null &&
      !nativeSelection.isCollapsed &&
      rootElement !== null &&
      rootElement.contains(nativeSelection.anchorNode)
    ) {
      const rangeRect = getDOMRangeRect(nativeSelection, rootElement)

      setFloatingElemPosition(rangeRect, popupCharStylesEditorElem, anchorElem)
    }
  }, [editor, anchorElem])

  useEffect(() => {
    const scrollerElem = anchorElem.parentElement

    const update = () => {
      editor.getEditorState().read(() => {
        updateTextFormatFloatingToolbar()
      })
    }

    window.addEventListener("resize", update)
    if (scrollerElem) {
      scrollerElem.addEventListener("scroll", update)
    }

    return () => {
      window.removeEventListener("resize", update)
      if (scrollerElem) {
        scrollerElem.removeEventListener("scroll", update)
      }
    }
  }, [editor, updateTextFormatFloatingToolbar, anchorElem])

  useEffect(() => {
    editor.getEditorState().read(() => {
      updateTextFormatFloatingToolbar()
    })
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateTextFormatFloatingToolbar()
        })
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateTextFormatFloatingToolbar()
          return false
        },
        COMMAND_PRIORITY_LOW
      )
    )
  }, [editor, updateTextFormatFloatingToolbar])



  function splitTextOnFirstNewLine(text) {
    const index = text.indexOf('\n');
    if (index === -1) {
      // If there is no new line, select the first two words
      return text.split(' ').slice(0, 2).join(' ');
    }
    const firstLine = text.slice(0, index);
    const remainingText = text.slice(index + 1);
    const recombinedText = `${remainingText}`;
    return [firstLine, recombinedText];
  }

  function onlyUnique(value, index, array) {
    return array.indexOf(value) === index;
  }


  const saveToDatabase = async ({ databaseAPI, formattedResult }) => {
    if (databaseAPI === '/user/summary') {
      store.isSummaryLoading = false;
    } else if (databaseAPI === '/user/feedbackAI') {
      store.isFeedbackLoading = false;
    }
    try {
      await store.api.post(databaseAPI, { data: formattedResult, documentId: window.location.href.split('/').pop() })
    } catch (err) {
      console.log(err)
      console.log("failed to save to database")
    }
  }



  const generateSummary = async () => {
    editor.getEditorState().read(() => {
      store.selectedTextScript = $getSelection().getTextContent()

    })
    const targetAPI = "/ai/summarize"
    const postObj = { content: store.selectedTextScript, currentPrompt: '' }

    const response = await store.api.post(targetAPI, postObj)


    if (response.data.output) {
      const output = response.data.output

      const [firstLine, recombinedText] = splitTextOnFirstNewLine(output);
      const title = firstLine.replace("Title:", '').replace('"', '').trim();

      const firstSevenInputWords = "Input: " + store.selectedTextScript.split(" ").slice(0, 7).join(" ") + " ..."

      const formattedResult = { summary: title, details: firstSevenInputWords, content: recombinedText.trim() }
      if (store.savedSummaryItems[0].summary === "(click for details)") {
        store.savedSummaryItems = [formattedResult]
      } else {
        store.savedSummaryItems = [formattedResult, ...store.savedSummaryItems,]
      }

      saveToDatabase({ databaseAPI: '/user/summary', formattedResult: formattedResult })



    } else {
      console.log(response)
    }

  }

  const generateGeneralFeedback = async () => {
    editor.getEditorState().read(() => {
      store.selectedTextScript = $getSelection().getTextContent()

    })
    const targetAPI = "/ai/feedbackGeneral"
    const postObj = { content: store.selectedTextScript, currentPrompt: '' }

    const response = await store.api.post(targetAPI, postObj)


    if (response.data.output) {
      const output = response.data.output

      const firstSevenInputWords = "Input: " + store.selectedTextScript.split(" ").slice(0, 7).join(" ") + " ..."

      const formattedResult = { summary: "General Feedback", details: firstSevenInputWords, content: output.trim() }

      if (store.savedFeedbackItems[0].summary === "(click for details)") {
        store.savedFeedbackItems = [formattedResult]
      } else {
        store.savedFeedbackItems = [formattedResult, ...store.savedFeedbackItems,]
      }


      saveToDatabase({ databaseAPI: '/user/feedbackAI', formattedResult: formattedResult })

    } else {
      console.log(response)
    }

  }

  const generateInlineFeedback = async () => {
    editor.getEditorState().read(() => {
      store.selectedTextScript = $getSelection().getTextContent()

    })
    const targetAPI = "/ai/feedback"
    const postObj = { content: store.selectedTextScript, currentPrompt: '' }

    const response = await store.api.post(targetAPI, postObj)


    if (response.data.output) {
      const output = response.data.output



      const firstSevenInputWords = "Input: " + store.selectedTextScript.split(" ").slice(0, 7).join(" ") + " ..."

      const formattedResult = { summary: "Inline Feedback", details: firstSevenInputWords, content: output.trim() }

      if (store.savedFeedbackItems[0].summary === "(click for details)") {
        store.savedFeedbackItems = [formattedResult]
      } else {
        store.savedFeedbackItems = [formattedResult, ...store.savedFeedbackItems,]
      }


      saveToDatabase({ databaseAPI: '/user/feedbackAI', formattedResult: formattedResult })

    } else {
      console.log(response)
    }

  }


  const generateFilmNotes = async () => {
    editor.getEditorState().read(() => {
      store.selectedTextScript = $getSelection().getTextContent()

    })
    const targetAPI = "/ai/notes"
    const postObj = { content: store.selectedTextScript, currentPrompt: '' }

    const response = await store.api.post(targetAPI, postObj)


    if (response.data.output) {
      const output = response.data.output



      const firstSevenInputWords = "Input: " + store.selectedTextScript.split(" ").slice(0, 7).join(" ") + " ..."

      const formattedResult = { summary: "Film Notes", details: firstSevenInputWords, content: output.trim() }

      if (store.savedFeedbackItems[0].summary === "(click for details)") {
        store.savedFeedbackItems = [formattedResult]
      } else {
        store.savedFeedbackItems = [formattedResult, ...store.savedFeedbackItems,]
      }


      saveToDatabase({ databaseAPI: '/user/feedbackAI', formattedResult: formattedResult })

    } else {
      console.log(response)
    }

  }


  const generateRewrite = async (rewriteType) => {

    store.showTableOfContents = false;
    store.openaiInput = "";
    store.closeOutput = false;
    store.scriptWriteOutput = "";
    store.scriptWriteOutputShow = "";
    //store.loading = false;
    store.output = "";

    store.loading = true;
    store.streaming = true

    let lineCount;
    let isSingleLine;
    editor.getEditorState().read(() => {
      isSingleLine = false;
      let seenNodeTags = [];
      let actualSelection;
      lineCount = 0;
      //console.log($getSelection()._cachedNodes.length)
      actualSelection = $getSelection().getTextContent()
      store.selectedTextScript = actualSelection
      if ($getSelection()._cachedNodes.length === 1) {
        isSingleLine = true;
      } else if ($getSelection()._cachedNodes.length <= 10) {

        let lastLine = "";
        let nodeText = "";
        $getSelection()._cachedNodes.forEach(node => {
          nodeText = node.getTextContent()
          //console.log(actualSelection)
          //console.log(nodeText)
          //console.log(node.__tag)
          if (actualSelection.includes(nodeText)) {
            //console.log("includes")
            if (node.__tag) {
              seenNodeTags.push(node.__tag)
            }
            if (nodeText !== lastLine) {
              //console.log("not last line")
              lineCount++;

            }
            lastLine = nodeText;

          }

        }
        )
        //console.log(lineCount)
        if (lineCount  <= 1) {
          isSingleLine = true;

        } else {

          //console.log(seenNodeTags)
          seenNodeTags = seenNodeTags.filter(onlyUnique);
          //console.log(seenNodeTags)
          if (!seenNodeTags || (seenNodeTags.length === 1 && seenNodeTags[0] === "h2")) {
            isSingleLine = true;
            //console.log("isSingleLine")
          }

        }



      }

    })

    store.openaiInput = store.selectedTextScript;

    //console.log(store.selectedTextScript)
    //console.log(isSingleLine)

    const targetAPI = isSingleLine ? "/rewriteLine" : "/rewrite"

    const postObj = { content: store.selectedTextScript, rewriteType: rewriteType }

    postObj.user = store.profile._id
    postObj.email = store.profile.email

    postObj.lineCount = lineCount


     const response = await fetch(store.baseURL_AI + targetAPI, {
       method: 'POST',
       headers: {
         'Content-Type': 'application/json',
       },
       body: JSON.stringify(postObj),
     });
 
     const data = response.body;
     if (!data) {
       console.log(response)
       return;
     }
     store.loading = false;
     store.generationSourceCategory = undefined;
     store.apiSource = targetAPI;
     store.generationSourceCategory = isSingleLine ? "generateLine" : "generateScreenplay"
 
     const reader = data.getReader();
     const decoder = new TextDecoder();
 
 
 
     let done = false;
     let inQuotes = false;
     let keyValCount = 1;
     let currentLineType = "";
     let lineText = "";
     let seenOpeningParen = false;
     let seenCurlyBraces = false;

     let lastUpdateTime = Date.now(); // initialize lastUpdateTime variable with the current time
 
     while (!done) {
       const { value, done: doneReading } = await reader.read();
       done = doneReading;
       const chunkValue = decoder.decode(value);

        // check if more than 15 seconds have passed since last update
        if (Date.now() - lastUpdateTime > 15000) {
          console.error("No update received for the last 15 seconds");
          store.generationSourceCategory = "error"
          store.scriptWriteOutput = "Sorry, it appears an error occurred. Please try generating again."
          store.streaming = false

          done = true;

      }
      lastUpdateTime = Date.now(); // update lastUpdateTime variable with the current time

 
       if (isSingleLine) {
        // not an opitmal approach - might remove desired quotes from the middle of a line
         store.scriptWriteOutput += chunkValue.replace('"','');
 
       } else {
 
         store.scriptWriteOutput += chunkValue;
 
         if (seenCurlyBraces) {
          console.log("curly braces seen!!!!")

          if (inQuotes) {
              inQuotes = false;
              if (keyValCount % 2 === 0) {

                  if (currentLineType === "Parenthetical") {

                      if (!lineText.endsWith(")")) {
                          lineText = lineText + ")";
                      }
                  }
                  // removing "FADE OUT" won't work here beacause across multiple streaming chunks
                  if (lineText !== "FADE OUT" && lineText !== "FADE OUT.") {
                      store.scriptWriteOutputShow += lineText.replace("}","").replace("{","").trim()
                      seenOpeningParen = false;
                  }

                  
                  if (currentLineType === "Character Name" || currentLineType === "Character" || currentLineType === "Parenthetical") {
                      store.scriptWriteOutputShow += "\n"
                  } else {
                      store.scriptWriteOutputShow += "\n\n"
                  }
                  

              }

             
          }
          
          keyValCount = 1;
          seenCurlyBraces = false;
          lineText = "";
          
      } else if (inQuotes) {
          if (chunkValue.includes('"')) {
              if ((chunkValue.match(/"/g) || []).length < 2) {
                  inQuotes = false;
              }
              if (keyValCount % 2 === 0) {

                  lineText = chunkValue.split('"')[0];
                  lineText = lineText

                  if (currentLineType === "Parenthetical") {

                      if (!lineText.endsWith(")")) {
                          lineText = lineText + ")";
                      }
                  }
                  // removing "FADE OUT" won't work here beacause across multiple streaming chunks
                  if (lineText !== "FADE OUT" && lineText !== "FADE OUT.") {
                      store.scriptWriteOutputShow += lineText
                      seenOpeningParen = false;
                  }

                
                  if (currentLineType === "Character Name" || currentLineType === "Character" || currentLineType === "Parenthetical") {
                      store.scriptWriteOutputShow += "\n"
                  } else {
                      store.scriptWriteOutputShow += "\n\n"
                  }
              

              }
              else {
                  currentLineType += chunkValue.split('"')[0];
              }

              keyValCount++;

          } else if (keyValCount % 2 === 0) {
              lineText = chunkValue;
              seenCurlyBraces = lineText.includes("{") || lineText.includes("}")
              if (currentLineType === "Parenthetical" && !seenOpeningParen) {
                  if (lineText.trim().startsWith("(")) {
                      seenOpeningParen = true;
                  } else if (hasLetters(lineText)) {
                      lineText = "(" + lineText;
                      seenOpeningParen = true;
                  }
              }
              if (!seenCurlyBraces) {
                  store.scriptWriteOutputShow += lineText
              }
          } else {
              currentLineType += chunkValue;
          }


      } else if (chunkValue.includes('"')) {
          inQuotes = true;

          if (keyValCount % 2 === 0) {
              if (!seenCurlyBraces) {
                  if (currentLineType === "Character Name" || currentLineType === "Character") {
                      store.scriptWriteOutputShow += "\t\t\t\t"
                  } else if (currentLineType === "Parenthetical") {
                      store.scriptWriteOutputShow += "\t\t"
                  }
              }
              lineText = chunkValue.split('"')[1]
              seenCurlyBraces = lineText.includes("{") || lineText.includes("}")
              lineText = lineText
              if (currentLineType === "Parenthetical") {
                  if (lineText.startsWith("(")) {
                      seenOpeningParen = true;
                  } else if (hasLetters(lineText)) {
                      lineText = "(" + lineText;
                      seenOpeningParen = true;
                  }
              }
              if (!seenCurlyBraces) {
                  store.scriptWriteOutputShow += lineText;
              }
          } else {
              currentLineType = "";
              currentLineType = chunkValue.split('"')[1];
          }

      }


       }
 
 
     } 

    store.streaming = false

  }


  const generateAutowrite = async () => {

    store.showTableOfContents = false;
    store.openaiInput = "";
    store.closeOutput = false;
    store.scriptWriteOutput = "";
    store.scriptWriteOutputShow = "";
    //store.loading = false;
    store.output = "";
    store.loading = true;
    store.streaming = true

    editor.getEditorState().read(() => {

      const selection = $getSelection();

      const selectedText = selection.getTextContent()

      store.selectedTextScript = selectedText


      const focusNode = selection.focus.getNode();

      const startKey = focusNode.getKey()

      const root = $getRoot();
      const children = root.getChildren();
      const allKeys = children.map(x => x.getKey())

      // Find the index of the largest integer in the array that is less than or equal to n
      const index = findIndex(allKeys, startKey)

      let earlierKeys = []
      // If all integers in the array are greater than or equal to n, return an empty array
      if (index === 0 || index === -1) {
        // leave earlierKeys as empty array
      } else if (index > 0) {
        // Otherwise, slice the array to keep only the integers before the first one that is greater than or equal to n
        earlierKeys = allKeys.slice(0, index);
      } else {
        earlierKeys = allKeys
      }

      let contextText = "";
      for (let i = earlierKeys.length - 1; i >= 0; i--) {
        contextText = $getNodeByKey(earlierKeys[i]).getTextContent().trim() + " " + contextText;
        if (contextText.split(/\s+/).length >= 200) {
          break;
        }
      }

      store.selectedTextScript = contextText.split(/\s+/).slice(-210).join(" ") + " " + focusNode.getTextContent()



      console.log(store.selectedTextScript)

    })


    store.openaiInput = store.selectedTextScript;

    const targetAPI = "/autowrite"
    const postObj = { content: store.selectedTextScript, currentPrompt: '' }

    postObj.user = store.profile._id
    postObj.email = store.profile.email
    postObj.source = "autowrite menu"

    const response = await fetch(store.baseURL_AI + targetAPI, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(postObj),
    });

    const data = response.body;
    if (!data) {
      console.log(response)
      return;
    }
    store.loading = false;
    store.generationSourceCategory = undefined;
    store.apiSource = targetAPI;
    store.generationSourceCategory = "generateScreenplay"

    const reader = data.getReader();
    const decoder = new TextDecoder();



    let done = false;
    let inQuotes = false;
    let keyValCount = 1;
    let currentLineType = "";
    let lineText = "";
    let seenOpeningParen = false;
    let seenCurlyBraces = false;

    let lastUpdateTime = Date.now(); // initialize lastUpdateTime variable with the current time
 

    while (!done) {
      const { value, done: doneReading } = await reader.read();
      done = doneReading;
      const chunkValue = decoder.decode(value);

      // check if more than 15 seconds have passed since last update
      if (Date.now() - lastUpdateTime > 15000) {
        console.error("No update received for the last 15 seconds");
        store.generationSourceCategory = "error"
        store.scriptWriteOutput = "Sorry, it appears an error occurred. Please try generating again."
        store.streaming = false

        done = true;

    }
    lastUpdateTime = Date.now(); // update lastUpdateTime variable with the current time


      store.scriptWriteOutput += chunkValue;

      if (seenCurlyBraces) {
        console.log("curly braces seen!!!!")

        if (inQuotes) {
            inQuotes = false;
            if (keyValCount % 2 === 0) {

                if (currentLineType === "Parenthetical") {

                    if (!lineText.endsWith(")")) {
                        lineText = lineText + ")";
                    }
                }
                // removing "FADE OUT" won't work here beacause across multiple streaming chunks
                if (lineText !== "FADE OUT" && lineText !== "FADE OUT.") {
                    store.scriptWriteOutputShow += lineText.replace("}","").replace("{","").trim()
                    seenOpeningParen = false;
                }

                
                if (currentLineType === "Character Name" || currentLineType === "Character" || currentLineType === "Parenthetical") {
                    store.scriptWriteOutputShow += "\n"
                } else {
                    store.scriptWriteOutputShow += "\n\n"
                }
                

            }

           
        }
        
        keyValCount = 1;
        seenCurlyBraces = false;
        lineText = "";
        
    } else if (inQuotes) {
        if (chunkValue.includes('"')) {
            if ((chunkValue.match(/"/g) || []).length < 2) {
                inQuotes = false;
            }
            if (keyValCount % 2 === 0) {

                lineText = chunkValue.split('"')[0];
                lineText = lineText

                if (currentLineType === "Parenthetical") {

                    if (!lineText.endsWith(")")) {
                        lineText = lineText + ")";
                    }
                }
                // removing "FADE OUT" won't work here beacause across multiple streaming chunks
                if (lineText !== "FADE OUT" && lineText !== "FADE OUT.") {
                    store.scriptWriteOutputShow += lineText
                    seenOpeningParen = false;
                }

              
                if (currentLineType === "Character Name" || currentLineType === "Character" || currentLineType === "Parenthetical") {
                    store.scriptWriteOutputShow += "\n"
                } else {
                    store.scriptWriteOutputShow += "\n\n"
                }
            

            }
            else {
                currentLineType += chunkValue.split('"')[0];
            }

            keyValCount++;

        } else if (keyValCount % 2 === 0) {
            lineText = chunkValue;
            seenCurlyBraces = lineText.includes("{") || lineText.includes("}")
            if (currentLineType === "Parenthetical" && !seenOpeningParen) {
                if (lineText.trim().startsWith("(")) {
                    seenOpeningParen = true;
                } else if (hasLetters(lineText)) {
                    lineText = "(" + lineText;
                    seenOpeningParen = true;
                }
            }
            if (!seenCurlyBraces) {
                store.scriptWriteOutputShow += lineText
            }
        } else {
            currentLineType += chunkValue;
        }


    } else if (chunkValue.includes('"')) {
        inQuotes = true;

        if (keyValCount % 2 === 0) {
            if (!seenCurlyBraces) {
                if (currentLineType === "Character Name" || currentLineType === "Character") {
                    store.scriptWriteOutputShow += "\t\t\t\t"
                } else if (currentLineType === "Parenthetical") {
                    store.scriptWriteOutputShow += "\t\t"
                }
            }
            lineText = chunkValue.split('"')[1]
            seenCurlyBraces = lineText.includes("{") || lineText.includes("}")
            lineText = lineText
            if (currentLineType === "Parenthetical") {
                if (lineText.startsWith("(")) {
                    seenOpeningParen = true;
                } else if (hasLetters(lineText)) {
                    lineText = "(" + lineText;
                    seenOpeningParen = true;
                }
            }
            if (!seenCurlyBraces) {
                store.scriptWriteOutputShow += lineText;
            }
        } else {
            currentLineType = "";
            currentLineType = chunkValue.split('"')[1];
        }

    }


     }

     store.streaming = false
   } 






  const generateImage = async (imageType) => {
    console.log(imageType)

    editor.getEditorState().read(() => {
      store.selectedTextScript = $getSelection().getTextContent()

    })
    const targetAPI = "/ai/generateImage"
    const postObj = { content: store.selectedTextScript, imageType: imageType}

    const response = await store.api.post(targetAPI, postObj)


    if (response.data.output && response.data.input) {
      try {

        await store.api.post('/user/images', { imageURL: response.data.output, prompt:response.data.input, imageType:response.data.imageType, documentId: window.location.href.split('/').pop() })
        
        store.isImageLoading = false;
      
      } catch (err) {
        store.isImageLoading = false;
        store.imageError = true;
        console.log(err)
        console.log("failed to save to database")
      }
    
    } else {
      console.log(response.data)
      store.isImageLoading = false;
      store.imageError = true;
    }

  }

  const generateVideo = async () => {
    editor.getEditorState().read(() => {
      store.selectedTextScript = $getSelection().getTextContent()

    })
    const targetAPI = "/ai/generateVideo"
    const postObj = { content: store.selectedTextScript }

    const response = await store.api.post(targetAPI, postObj)


    if (response.data.output && response.data.input) {
      try {
        console.log(response.data)
        console.log(response.data.predicted)
        await store.api.post('/user/videos', { videoURL: response.data.output, prompt:response.data.input, documentId: window.location.href.split('/').pop() })
        
        store.isVideoLoading = false;
      
      } catch (err) {
        store.isVideoLoading = false;
        store.videoError = true;
        console.log(err)
        console.log("failed to save to database")
      }
    
    } else {
      console.log(response.data)
      store.isVideoLoading = false;
      store.videoError = true;
    }

  }



  const setupTableRead = async () => {
    editor.getEditorState().read(() => {

      let allVoiceLines = [];
      let characters = [];
      let currCharacter = "";
      let lastNodeText = "";


      var selection = window.getSelection();
      var selectedText = selection.toString();


      console.log(selectedText)

      const cachedNodes = $getSelection()._cachedNodes;
      
     cachedNodes.forEach(node => {

      console.log(node)

        
        const element =
          node.getKey() === "root"
            ? node
            : node.getTopLevelElementOrThrow();

        
        const elementKey = element.getKey();

        let elementDOM = editor.getElementByKey(elementKey);
        
        let heading = elementDOM.tagName;
        if (!heading) {
          heading = "H2";
        } 

        let elementText = elementDOM.innerText;
        if (elementText === lastNodeText) {
          return;
        }
        lastNodeText = elementText;
        let nodeKey =  node.getKey();

        //console.log(nodeKey)
        //let domElement = editor.getElementByKey(nodeKey);

        //console.log(elementText)

        let text = stripNonContiguousText(elementText, selectedText);

        if (heading && text) {
          
          if (heading === "H1" || heading === "H2" || heading === "H6" || heading === "P") {
    
            if (heading === "H1") {
             text = text.toLowerCase();
             text = text.replace("int.", "interior")
             text = text.replace("ext.", "exterior")
             text = text.replace("int./ext.", "interior exterior")
             text = text.replace("int/ext.", "interior exterior")
             text = text.replace("int/ext", "interior exterior")
             text = text.replace("i/e", "interior exterior")
             text = text.replace("i/e.", "interior exterior")
             text = text.replace("cont'd", "continued")
             text = text = text.toUpperCase();
            } else if (heading === "H6") {
              text = text.toLowerCase();
              text = text.replace("cont'd", "continued")
              text = text.toUpperCase();
            }

              allVoiceLines.push(["NARRATOR", text, node])

              if (!characters.includes("NARRATOR")) {
                characters.push("NARRATOR")
              }
  
          } else if (heading  === "H5") {
            if (currCharacter) {

                if (text.length < 15) {
                  text = "-   " + text;
                }
                text = text + "   -"
                allVoiceLines.push([currCharacter, text, node])
            } else {
              let prevNodeKey = nodeKey;
              while (!currCharacter && prevNodeKey > (nodeKey - 10) ) {
                prevNodeKey -= 1;
                //console.log(prevNodeKey)
                let elementDOM = editor.getElementByKey(prevNodeKey.toString());
                if (elementDOM) {
                  if (elementDOM.tagName === "H3") {
                    currCharacter = elementDOM.innerText;
                    if (currCharacter && !characters.includes(currCharacter)) {
                      characters.push(currCharacter)
                    }

                    if (text.length < 15) {
                      text = "-   " + text;
                    }
                    text = text + "   -"

                    allVoiceLines.push([currCharacter, text, node])
                  }
                }

              }
            }
        
          } else if (heading === "H3") {
            currCharacter = elementText
            if (currCharacter && !characters.includes(currCharacter)) {
              characters.push(currCharacter)
            }
          }
      

        }
      
      

      })
      store.cachedNodes = cachedNodes;
      store.selectedCharacters = characters;
      store.selectedVoiceLines = allVoiceLines;
      store.clearSelection = true;
      console.log(allVoiceLines)
      
    })

      //scrollToNode()
    
    

 /*  var selectedPart = document.getElementsByClassName('editor-inner')[0];

  let allVoiceLines = [];
  let characters = [];
  let currCharacter = "";

  // Check if the element exists
  if (selectedPart) {
    // Get the selected text within the selected part
    var selection = window.getSelection();
    var selectedText = selection.toString();

    console.log(selectedText)
    
    // Create a range based on the selection
    var range = selection.getRangeAt(0);

    // Create a temporary element to extract the selected text
    var tempElement = document.createElement('div');
    tempElement.appendChild(range.cloneContents());

    // Get all heading elements within the temporary element
    var headingElements = tempElement.querySelectorAll('h1, h2, h3, h4, h5, h6');


    // Iterate through each heading element and extract the heading and innerText
    headingElements.forEach(function (headingElement) {
      let heading = headingElement.tagName;
      let elementText = headingElement.innerText;

      console.log(heading)


      const text = stripNonContiguousText(elementText, selectedText);

      if (heading && text) {
        if (heading === "H1" || heading === "H2" || heading === "H6") {
        
            allVoiceLines.push(["narrator", text])

        } else if (heading  === "H5") {
          if (currCharacter) {
              allVoiceLines.push([currCharacter, text])
            }
      
        } else if (heading === "H3") {
          currCharacter = elementText
          if (currCharacter && !characters.includes(currCharacter)) {
            characters.push(currCharacter)
          }
        }
    }
      
      
    });
  } else {
    console.log("The element with class 'editor-inner' was not found.");
  }

  console.log(allVoiceLines)
*/

}

/* const tableReadScrolling = async () => {

  let domElems = []

  editor.getEditorState().read(() => {

  store.cachedNodes.forEach(node => {

    
    if (node.getKey() % 2){
      const element =
        node.getKey() === "root"
          ? node
          : node.getTopLevelElementOrThrow();

      
      const elementKey = element.getKey();

      let elementDOM = editor.getElementByKey(elementKey);
      
      let heading = elementDOM.tagName;
      let elementText = elementDOM.innerText;
      let nodeKey =  node.getKey();
      let domElement = editor.getElementByKey(nodeKey);


      if (heading && elementText) {
        if (heading === "H1" || heading === "H2" || heading  === "H5" ||  heading === "H6") {
        
            domElems.push(domElement)

      
        }
    }
      }
    
    })

  })

    scrollToNode(domElems)
    store.generateVoiceReady = false;
} */


/* const runTableRead = async () => {

  store.generateVoiceReady = false


  const audioContext = new (window.AudioContext || window.webkitAudioContext)();

  const playAudio = async (item) => {

    
  let postObj = {}

    const audioElement = new Audio();
    // audioElement.controls = true;
    document.body.appendChild(audioElement);

    const characterName = item[0]
    const content = item[1]
    const node = item[2]
    let domElement;
    
    const voiceId = store.characterToVoiceID[characterName]

    postObj["content"] = content
    postObj["voiceId"] = voiceId

    const response = await fetch(store.baseURL_AI + '/voiceGen', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'audio/mpeg',
      },
      body: JSON.stringify(postObj)
    });

    editor.getEditorState().read(() => {
      let nodeKey =  node.getKey();
      domElement = editor.getElementByKey(nodeKey);
      scrollToNode(domElement)

  })


    if (response.ok) {
      const audioData = await response.arrayBuffer();
      const audioBuffer = await audioContext.decodeAudioData(audioData);

      // Create an AudioBufferSourceNode
      const sourceNode = audioContext.createBufferSource();
      sourceNode.buffer = audioBuffer;

      // Connect the sourceNode to the destination (audio output)
      sourceNode.connect(audioContext.destination);

      // Start playing the audio
      sourceNode.start();

      // Wait for the audio to finish playing
      await new Promise(resolve => {
        sourceNode.onended = resolve;
      });

      domElement.style.color = '';

      // Remove the audio element from the document
      audioElement.parentNode.removeChild(audioElement);
    } else {
      console.error('Error streaming audio:', response.status);
    }
  };

  // Iterate through the contentArray sequentially
  for (let item of store.selectedVoiceLines) {
    await playAudio(item);
  } 
};
*/


function initRandomID() {
  return new Promise(resolve => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for ( var i = 0; i < 16; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    resolve(result);
  });
}



const runTableRead = async () => {
  store.generateVoiceReady = false;
  store.readCompleted = false;

  const generationId = await initRandomID();

  let totalCharLength = 0;


  const audioContext = new (window.AudioContext || window.webkitAudioContext)();

  let domElement;
 

  const playAudio = async (item, currentIndex) => {

    console.log(currentIndex)

    const postObj = {};
    const audioElement = new Audio();
    document.body.appendChild(audioElement);
    const characterName = item[0];
    const content = item[1];
    totalCharLength += content.length;
    const node = item[2];
    const voiceId = store.characterToVoiceID[characterName];
    postObj["content"] = content;
    postObj["voiceId"] = voiceId;
    postObj["generationId"] = generationId;

    const response = await fetch(store.baseURL_AI + '/voiceGen', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'audio/mpeg',
      },
      body: JSON.stringify(postObj)
    });

    

    editor.getEditorState().read(() => {
      let nodeKey =  node.getKey();
      domElement = editor.getElementByKey(nodeKey);
      scrollToNode(domElement)

    })
  

    if (response.ok) {
      const audioData = await response.arrayBuffer();
      const audioBuffer = await audioContext.decodeAudioData(audioData);
      const sourceNode = audioContext.createBufferSource();
      sourceNode.buffer = audioBuffer;
      sourceNode.connect(audioContext.destination);

      // Start playing the audio immediately
      sourceNode.start();

      // Wait for the audio to finish playing
      await new Promise(resolve => {
        sourceNode.onended = resolve;
      });

      if (domElement && domElement.style) {
        domElement.style.color = '';
        }


      audioElement.parentNode.removeChild(audioElement);
    } else {
      console.error('Error streaming audio:', response.status);

      if (domElement && domElement.style) {
        domElement.style.color = '';
        }
    }
  };

  let textPreview = store.selectedVoiceLines[0][1].replace("   -","").replace("INTERIOR","INT.").replace("EXTERIOR","EXT.").replace("CONTINUED","CONT'D")
  textPreview = textPreview.replace("-   ","")
  textPreview = textPreview.slice(0,35)

  await store.api.post('/user/voiceEvent', { generationId: generationId, documentId: window.location.href.split('/').pop(), textPreview: textPreview })

  // Iterate through the contentArray sequentially
  const totalItems = store.selectedVoiceLines.length;



  if (totalItems > 1) {
  for (let i = 0; i < totalItems - 1; i++) {

    const item = store.selectedVoiceLines[i];

    if (i === 0) {
      await playAudio(item, i);

    } else  {
      await new Promise(resolve => setTimeout(resolve, 100)); 
      await playAudio(item, i);
    }
  }

  const lastItem = store.selectedVoiceLines[totalItems - 1];
  await new Promise(resolve => setTimeout(resolve, 100));
  await playAudio(lastItem, totalItems - 1);

  } else {
    const item = store.selectedVoiceLines[0];
    await playAudio(item, 0);
  }


  store.readCompleted = true;
  await store.api.post('/user/voiceEvent/totalCharLength', { generationId: generationId, totalCharLength: totalCharLength })
  
};



/* const runTableRead = async () => {
  store.generateVoiceReady = false;

  const generationId = await initRandomID();

  let totalCharLength = 0;

  await store.api.post('/user/voiceEvent', { generationId: generationId, documentId: window.location.href.split('/').pop() })

  const audioContext = new (window.AudioContext || window.webkitAudioContext)();
  const playAudio = async (item) => {
    let postObj = {};
    const audioElement = new Audio();
    document.body.appendChild(audioElement);
    const characterName = item[0];
    const content = item[1];
    totalCharLength += content.length;
    const node = item[2];
    let domElement;
    const voiceId = store.characterToVoiceID[characterName];
    postObj["content"] = content;
    postObj["voiceId"] = voiceId;
    postObj["generationId"] = generationId;

    const response = await fetch(store.baseURL_AI + '/voiceGen', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'audio/mpeg',
      },
      body: JSON.stringify(postObj)
    });

    editor.getEditorState().read(() => {
      let nodeKey =  node.getKey();
      domElement = editor.getElementByKey(nodeKey);
      scrollToNode(domElement)

  })

    if (response.ok) {
      const audioData = await response.arrayBuffer();
      const audioBuffer = await audioContext.decodeAudioData(audioData);
      const sourceNode = audioContext.createBufferSource();
      sourceNode.buffer = audioBuffer;
      sourceNode.connect(audioContext.destination);
      sourceNode.start();
      await new Promise(resolve => {
        sourceNode.onended = resolve;
      });

      domElement.style.color = '';

      audioElement.parentNode.removeChild(audioElement);
    } else {
      console.error('Error streaming audio:', response.status);
    }
  };

  // Iterate through the contentArray sequentially
  for (let i = 0; i < store.selectedVoiceLines.length; i++) {
    const currentItem = store.selectedVoiceLines[i];
    const nextItem = store.selectedVoiceLines[i + 1];
    await Promise.all([playAudio(currentItem), sendNextRequest(nextItem)]);
  
  }

  await store.api.post('/user/voiceEvent/totalCharLength', { generationId: generationId, totalCharLength: totalCharLength })
};

const sendNextRequest = async (nextItem) => {
  if (nextItem) {
    const characterName = nextItem[0];
    const content = nextItem[1];
    const voiceId = store.characterToVoiceID[characterName];
    const postObj = {
      content: content,
      voiceId: voiceId
    };
    const response = await fetch(store.baseURL_AI + '/voiceGen', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'audio/mpeg',
      },
      body: JSON.stringify(postObj)
    });
    if (!response.ok) {
      console.error('Error streaming audio:', response.status);
    }
  }
}; */

/* 
const runTableRead = async () => {

  editor.getEditorState().read(() => {

  for (let item of store.selectedVoiceLines) {

    let node = item[2];

    let nodeKey =  node.getKey();
    let domElement = editor.getElementByKey(nodeKey);
    scrollToNode(domElement)
  }
})

store.generateVoiceReady = false;
}
 */


function scrollToNode(domElement) {

    

      

    if (domElement !== null) {
      // Set the innerHTML color to blue
      domElement.style.color = '#215eff';

      // Scroll the element into view
      domElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      
      // Reset the color after a certain delay (e.g., 2000 milliseconds)
     /*  setTimeout(() => {
        domElement.style.color = '';
      }, 5000); */
     
    }
    

} 

/* function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
} */



function stripNonContiguousText(str, selection) {
  let longestStr = '';
  
  for (let i = 0; i < str.length; i++) {
    let subset = '';
    
    for (let j = i; j < str.length; j++) {
      const currentSubset = str.slice(i, j + 1);
      
      if (selection.includes(currentSubset) && currentSubset.length > subset.length) {
        subset = currentSubset;
      }
    }
    
    if (subset.length > longestStr.length) {
      longestStr = subset;
    }
  }
  
  return longestStr;
}



  const [summarizeOpen, setSummarizeOpen] = React.useState(false);
  const [feedbackOpen, setFeedbackOpen] = React.useState(false);
  const [rewriteOpen, setRewriteOpen] = React.useState(false);
  const [autowriteOpen, setAutowriteOpen] = React.useState(false);
  const [imageOpen, setImageOpen] = React.useState(false);
  const [videoOpen, setVideoOpen] = React.useState(false);


  const handleClose = (snackbarType, _, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    if (snackbarType === 'summarize') {
      setSummarizeOpen(false);
    } else if (snackbarType === 'feedback') {
      setFeedbackOpen(false);
    } else if (snackbarType === 'rewrite') {
      setRewriteOpen(false);
    } else if (snackbarType === 'autowrite') {
      setAutowriteOpen(false);
    } else if (snackbarType === 'image') {
      setImageOpen(false);
    } else if (snackbarType === 'video') {
      setVideoOpen(false);
    }
    

  };


  const handleVideoClick = async () => {
    generateVideo();
    setVideoOpen(true);
    store.videoError = false;
    store.isVideoLoading = true;

    //store.closeOutput = false;

  };



  const handleSummarizeClick = async () => {
    generateSummary();
    setSummarizeOpen(true);
    store.isSummaryLoading = true;

    store.closeOutput = false;

  };

  const handleGeneralFeedbackClick = () => {
    generateGeneralFeedback();
    setFeedbackOpen(true);
    handleFeedbackButtonClose();
    store.isFeedbackLoading = true;

    store.closeOutput = false;
  };

  const handleInlineFeedbackClick = () => {
    generateInlineFeedback();
    setFeedbackOpen(true);
    handleFeedbackButtonClose();
    store.isFeedbackLoading = true;

    store.closeOutput = false;
  };

  const handleNotesClick = () => {
    generateFilmNotes();
    setFeedbackOpen(true);
    handleFeedbackButtonClose();
    store.isFeedbackLoading = true;

    store.closeOutput = false;
  };


  const handleAutowriteClick = () => {
    generateAutowrite();
    setAutowriteOpen(true);

    store.closeOutput = false;

    // isAutowriteLoading is not in store (not needed I think because not an accordion section)
    //store.isAutowriteLoading = true;  
  };




  const handleImageClick = async (e) => {

    const imageType = e.currentTarget.textContent

    generateImage(imageType);
    setImageOpen(true);
    store.imageError = false;
    store.isImageLoading = true; 

    //store.closeOutput = false;

  };


  const handleRewriteClick = async (e) => {

    const rewriteType = e.currentTarget.textContent
    generateRewrite(rewriteType);
    setRewriteOpen(true);

    store.closeOutput = false;

    // isRewriteLoading is not in store (not needed I think because not an accordion section)
    //store.isRewriteLoading = true;
  };







  let moveLeft = 0

  /*  const diff = store.editorRect.right - store.cursorRect.right
   //console.log(diff)
   if ((diff < 550) && (store.popupMenuLeftPos > 220)) {
     if (diff < 200) {
     moveLeft = (store.editorRect.right - store.cursorRect.right) * 1.7
     } else if ( diff < 250) {
     moveLeft = (store.editorRect.right - store.cursorRect.right) * 1.55 
     } else if ( diff < 300) {
     moveLeft = (store.editorRect.right - store.cursorRect.right) * 1.3
     } else if ( diff < 350) {
     moveLeft = (store.editorRect.right - store.cursorRect.right) * 1.2
     } else if ( diff < 400) {
     moveLeft = (store.editorRect.right - store.cursorRect.right) * 0.65
     } else {
     moveLeft = (store.editorRect.right - store.cursorRect.right) * 0.3
     }
 
   }
 if (store.cursorRect.left < 300) {
   moveLeft = 0
 }
 //console.log(moveLeft)
 
 if ((store.cursorRightPos- store.cursorLeftPos) > 300) {
   moveLeft = 0
 }
 
 console.log(store.cursorRect.right)
 console.log(store.cursorRect.left)
 console.log(moveLeft) */


  const [wordCount, setWordCount] = React.useState(0);



  React.useEffect(() => {
    const disposer = autorun(() => {
      setWordCount(store.wordCount);
    });
    return disposer;
  }, []);


  const [anchorEl, setAnchorEl] = React.useState(null);
  const openFeedbackMenu = Boolean(anchorEl);
  const handleFeedbackButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleFeedbackButtonClose = () => {
    setAnchorEl(null);
  };


  const [anchorElImage, setAnchorElImage] = React.useState(null);
  const [anchorElRewrite, setAnchorElRewrite] = React.useState(null);
  
  const openImageMenu = Boolean(anchorElImage);
  const openRewriteMenu = Boolean(anchorElRewrite);
  
  const handleImageButtonClick = (event) => {
    setAnchorElImage(event.currentTarget);
  };
  const handleRewriteButtonClick = (event) => {
    setAnchorElRewrite(event.currentTarget);
  };


  // these functions caused the editor to jump to the top of the page
   const handleImageButtonClose = (event) => {
    event.preventDefault();
    setAnchorElImage(null);
  }; 

  const handleRewriteButtonClose = (event) => {
    event.preventDefault();
    setAnchorElRewrite(null);
  };


  React.useEffect(() => {
    const handleDocumentMouseDown = (event) => {
      if (anchorElImage && !anchorElImage.contains(event.target)) {
        event.preventDefault();
      }
    };
  
    document.addEventListener('mousedown', handleDocumentMouseDown);
  
    return () => {
      document.removeEventListener('mousedown', handleDocumentMouseDown);
    };
  }, [anchorElImage]);


  React.useEffect(() => {
    const handleDocumentMouseDown = (event) => {
      if (anchorElRewrite && !anchorElRewrite.contains(event.target)) {
        event.preventDefault();
      }
    };
  
    document.addEventListener('mousedown', handleDocumentMouseDown);
  
    return () => {
      document.removeEventListener('mousedown', handleDocumentMouseDown);
    };
  }, [anchorElRewrite]);



  return (
    <>
    {!store.voiceMode ?
    <div ref={popupCharStylesEditorRef} className="floating-text-format-popup" style={{ left: `-${moveLeft}px` }}>
      {editor.isEditable() && (
        <>

          <button
            onMouseDown={() => {
              editor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold")
            }}
            className={"popup-item spaced " + (isBold ? "active" : "")}
            aria-label="Format text as bold"
          >
            <i className="format bold" />
          </button>
          <button
            onMouseDown={() => {
              editor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic")
            }}
            className={"popup-item spaced " + (isItalic ? "active" : "")}
            aria-label="Format text as italics"
          >
            <i className="format italic" />
          </button>
          <button
            onMouseDown={() => {
              editor.dispatchCommand(FORMAT_TEXT_COMMAND, "underline")
            }}
            className={"popup-item spaced " + (isUnderline ? "active" : "")}
            aria-label="Format text to underlined"
          >
            <i className="format underline" />
          </button>
          <button
            onMouseDown={() => {
              editor.dispatchCommand(FORMAT_TEXT_COMMAND, "strikethrough")
            }}
            className={"popup-item spaced " + (isStrikethrough ? "active" : "")}
            aria-label="Format text with a strikethrough"
          >
            <i className="format strikethrough" />
          </button>

          <div className="divider" />





          <React.Fragment>
            <button style={{ color: "rgb(119, 119, 119)", alignItems: "center", marginTop: "1px" }}
              disabled={store.streaming}
              onMouseDown={handleAutowriteClick}
              className={"popup-item spaced "}
              aria-label="Autowrite"
            >
              <EditIcon style={{ height: "21px", width: "21px" }} />
              <span style={{ fontSize: "12.4px", marginLeft: "2px" }}>Autowrite</span>
            </button>
            <StyledSnackbar open={autowriteOpen} autoHideDuration={1500} onClose={(event, reason) => handleClose('autowrite', event, reason)}>
              generating next few lines ...
            </StyledSnackbar>
          </React.Fragment>



          {(wordCount > 2 && wordCount < 500) ?
            <React.Fragment>
              <button style={{ color: "rgb(119, 119, 119)", alignItems: "center", marginTop: "1px" }}
                disabled={store.streaming}
                onMouseDown={handleRewriteButtonClick}
                className={"popup-item spaced "}
                aria-label="Provide Feedback"
              //title="Rewrite"
              >
                <AutoFixHighIcon style={{ height: "22px", width: "22px" }} />
                <span style={{ fontSize: "12.4px", marginLeft: "2px" }}>Rewrite</span> <span style={{ fontSize: "12.3px", marginLeft: "3px", marginTop: "1px" }}> ▽</span>
              </button>
              <Menu
                  id="basic-menu"
                  anchorEl={anchorElRewrite}
                  open={openRewriteMenu}
                  onClose={handleRewriteButtonClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <MenuItem style={{ fontSize: "14px" }} onMouseDown={handleRewriteClick}>Rephrase</MenuItem>
                  <MenuItem style={{ fontSize: "14px" }} onMouseDown={handleRewriteClick}>Snappier</MenuItem>
                  <MenuItem style={{ fontSize: "14px" }} onMouseDown={handleRewriteClick}>Funnier</MenuItem>
                  <MenuItem style={{ fontSize: "14px" }} onMouseDown={handleRewriteClick}>Sillier</MenuItem>
                  <MenuItem style={{ fontSize: "14px" }} onMouseDown={handleRewriteClick}>Weirder</MenuItem>
                  <MenuItem style={{ fontSize: "14px" }} onMouseDown={handleRewriteClick}>Ambivalent</MenuItem>
                  <MenuItem style={{ fontSize: "14px" }} onMouseDown={handleRewriteClick}>Sadder</MenuItem>


                </Menu>
              <StyledSnackbar open={rewriteOpen} autoHideDuration={1500} onClose={(event, reason) => handleClose('rewrite', event, reason)}>
                rewriting ...
              </StyledSnackbar>
            </React.Fragment>

            : null}








          {(wordCount > 100 && wordCount <= 7500) ?
            <React.Fragment>
              <button style={{ color: "rgb(119, 119, 119)", alignItems: "center", marginTop: "2px" }}
                onMouseDown={handleSummarizeClick}
                className={"popup-item spaced "}
                aria-label="Summarize"
              //title="Summarize"
              >
                <SummarizeIcon style={{ height: "22px", width: "22px" }} />
                <span style={{ fontSize: "12.4px", marginLeft: "2px" }}>Summary</span>
              </button>

              <StyledSnackbar open={summarizeOpen} autoHideDuration={2000} onClose={(event, reason) => handleClose('summarize', event, reason)}>
                generating in Summary tab ...
              </StyledSnackbar>
            </React.Fragment>

            : null}


          {(wordCount > 100 && wordCount <= 7500) ?
            <div>
              <React.Fragment>
                <button style={{ color: "#696868", alignItems: "center", marginTop: "0px" }}
                  className={"popup-item spaced"}
                  aria-label="Provide Feedback"
                  id="basic-button"
                  aria-controls={openFeedbackMenu ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={openFeedbackMenu ? 'true' : undefined}
                  onMouseDown={handleFeedbackButtonClick}
                >
                  <GradingIcon style={{ height: "20px", width: "20px" }} />
                  <span style={{ fontSize: "12.3px", marginLeft: "2px", marginTop: "1px" }}>Feedback</span> <span style={{ fontSize: "12.3px", marginLeft: "3px", marginTop: "1px" }}> ▽</span>
                </button>

                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={openFeedbackMenu}
                  onClose={handleFeedbackButtonClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >

                  <MenuItem style={{ fontSize: "14px" }} onMouseDown={handleGeneralFeedbackClick}>General feedback</MenuItem>


                  <MenuItem style={{ fontSize: "14px" }} onMouseDown={handleInlineFeedbackClick}>Inline feedback</MenuItem>
                  <MenuItem style={{ fontSize: "14px" }} onMouseDown={handleNotesClick}>Film notes</MenuItem>


                </Menu>
                <StyledSnackbar open={feedbackOpen} autoHideDuration={2000} onClose={(event, reason) => handleClose('feedback', event, reason)}>
                  generating in Feedback tab ...
                </StyledSnackbar>

              </React.Fragment>

            </div>

            : null}




{(wordCount >=3 && wordCount < 50 && store.allowImageSelection) ?
            <div>
              <React.Fragment>
              <button style={{ color: "rgb(119, 119, 119)", alignItems: "center", marginTop: "0px", marginBottom: "4px" }}
                onMouseDown={handleImageButtonClick}
                className={"popup-item spaced "}
                aria-label="Image"
              >
                <AddPhotoAlternateIcon style={{ height: "20px", width: "25px"}} />
                <span style={{ fontSize: "12.7px", marginBottom: "1px", marginLeft: "1px"}}>Image</span> <span style={{ fontSize: "12.3px", marginLeft: "3px", marginTop: "1px" }}> ▽</span>
              </button>
              <Menu
                  id="basic-menu"
                  anchorEl={anchorElImage}
                  open={openImageMenu}
                  onClose={handleImageButtonClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >

                  <MenuItem style={{ fontSize: "14px" }} onMouseDown={handleImageClick}>Storyboard</MenuItem>
                  <MenuItem style={{ fontSize: "14px" }} onMouseDown={handleImageClick}>Illustration</MenuItem>
                  <MUIDivider />
                  <MenuItem style={{ fontSize: "14px" }} onMouseDown={handleImageClick}>Establishing shot</MenuItem>
                  <MenuItem style={{ fontSize: "14px" }} onMouseDown={handleImageClick}>Medium shot</MenuItem>
                  <MenuItem style={{ fontSize: "14px" }} onMouseDown={handleImageClick}>Close-up shot</MenuItem>
                  <MenuItem style={{ fontSize: "14px" }} onMouseDown={handleImageClick}>Handheld shot</MenuItem>


                </Menu>

              <StyledSnackbar open={imageOpen} autoHideDuration={2000} onClose={(event, reason) => handleClose('image', event, reason)}>
                generating in Images tab ...
              </StyledSnackbar>
            </React.Fragment>

            </div>

            : null}





{ 
            
  (wordCount >=3 && wordCount <= 10) ?
  <div>
    <React.Fragment>
    <button style={{ color: "rgb(119, 119, 119)", alignItems: "center", marginTop: "0px", marginBottom: "4px" }}
      onMouseDown={handleVideoClick}
      className={"popup-item spaced "}
      aria-label="Video"
    >
      <VideoCameraBackIcon style={{ height: "20px", width: "25px"}} />
      <span style={{ fontSize: "12.4px", marginBottom: "1px", marginLeft: "1px"}}>Video</span>
    </button>

    <StyledSnackbar open={videoOpen} autoHideDuration={2000} onClose={(event, reason) => handleClose('video', event, reason)}>
      generating in Videos tab ...
    </StyledSnackbar>
  </React.Fragment>

  </div>
  

: null}




  







          


          <div className="divider" />

          <div style={{ color: "#2263bf", alignItems: "center", padding: "2.5px", marginRight: "1px" }}>


            <span style={{ fontSize: "12px", marginLeft: "2px" }}>{wordCount} {wordCount === 1 ? "word" : "words"}</span>
          </div>





        </>

      )}
      {/*
      <button
        onMouseDown={insertComment}
        className={"popup-item spaced insert-comment"}
        aria-label="Insert comment"
      >
        <i className="format add-comment" />
      </button>
        */}
    </div>

    : !store.showTableRead ?
    <div ref={popupCharStylesEditorRef} className="floating-text-format-popup" style={{ left: `-${moveLeft}px`, padding:"5px" }}>
    
    <div style={{ color: "#2263bf", alignItems: "center", marginBottom: "6px", marginRight: "2px", marginLeft: "4px" }}>


      <span style={{ fontSize: "13px", marginLeft: "2px", }}>{wordCount} {wordCount === 1 ? "word" : "words"}</span>
    </div>
    
    
    <div className="divider" />

    <button style={{ color: "rgb(119, 119, 119)", alignItems: "center", marginTop: "0.5px", padding: "13px", paddingRight:"14px", paddingLeft:"14px",  marginLeft: "4px", marginRight: "4px", marginTop: "0px", marginBottom: "1px" }}
              //onMouseDown={() => generateTableRead()}
              onMouseDown={() => {setupTableRead(); store.selectVoicesMode = true}}
              //onMouseDown={() => {generateTableRead()}}
              disabled={wordCount >1000}
              className={`popup-item spaced shadow ${wordCount <= 1000 ? 'shadow-slate-400' : 'shadow-none' } active:shadow-inner}`}
              aria-label="Continue"
            >
              
              <span style={{ fontSize: "16px", marginLeft: "2px" }}>{wordCount <= 1000 ? "Continue" : "over word limit"}</span>
            </button>

    
    </div>
    :

    <div ref={popupCharStylesEditorRef} className="floating-text-format-popup" style={{ left: `-${moveLeft}px`, padding:"5px" }}>
   
    

    <div style={{ color: "rgb(119, 119, 119)", alignItems: "center", }}
              onMouseDown={store.generateVoiceReady ? runTableRead() : null}
              /* className={`popup-item spaced shadow ${wordCount <= 1000 ? 'shadow-slate-400' : 'shadow-none' } active:shadow-inner}`} */
              aria-label="generate"
            >
              
              <span style={{ fontSize: "14px", marginLeft: "2px" }}>{store.generateVoiceReady ? "generating..." : "Finished - click Saved Reads to replay"}</span>
            </div>

    
    </div>


    }

    </>
  )
}))


function useFloatingTextFormatToolbar(editor, anchorElem) {
  const [isText, setIsText] = useState(false)
  const [isBold, setIsBold] = useState(false)
  const [isItalic, setIsItalic] = useState(false)
  const [isUnderline, setIsUnderline] = useState(false)
  const [isStrikethrough, setIsStrikethrough] = useState(false)


  const updatePopup = useCallback(() => {
    editor.getEditorState().read(() => {
      // Should not to pop up the floating toolbar when using IME input
      if (editor.isComposing()) {
        return
      }
      const selection = $getSelection()
      const nativeSelection = window.getSelection()
      const rootElement = editor.getRootElement()

      if (
        nativeSelection !== null &&
        (!$isRangeSelection(selection) ||
          rootElement === null ||
          !rootElement.contains(nativeSelection.anchorNode))
      ) {
        setIsText(false)
        return
      }

      if (!$isRangeSelection(selection)) {
        return
      }

      const node = getSelectedNode(selection)

      // Update text format
      setIsBold(selection.hasFormat("bold"))
      setIsItalic(selection.hasFormat("italic"))
      setIsUnderline(selection.hasFormat("underline"))
      setIsStrikethrough(selection.hasFormat("strikethrough"))

      // Update links
      const parent = node.getParent()


      if (
        selection.getTextContent() !== ""
      ) {
        setIsText($isTextNode(node))
      } else {
        setIsText(false)
      }
    })
  }, [editor])

  useEffect(() => {
    document.addEventListener("selectionchange", updatePopup)
    return () => {
      document.removeEventListener("selectionchange", updatePopup)
    }
  }, [updatePopup])

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(() => {
        updatePopup()
      }),
      editor.registerRootListener(() => {
        if (editor.getRootElement() === null) {
          setIsText(false)
        }
      })
    )
  }, [editor, updatePopup])

  if (!isText) {
    return null
  }

  return createPortal(
    <TextFormatFloatingToolbar
      editor={editor}
      anchorElem={anchorElem}
      isBold={isBold}
      isItalic={isItalic}
      isStrikethrough={isStrikethrough}
      isUnderline={isUnderline}
    />,
    anchorElem
  )
}

export default function FloatingTextFormatToolbarPlugin({
  anchorElem = document.body
}) {
  const [editor] = useLexicalComposerContext()
  return useFloatingTextFormatToolbar(editor, anchorElem)
}
