import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { observer, inject } from 'mobx-react'


@inject("store")
@observer
class DropdownItem extends Component {
  handleClick = (e) => {
    e.preventDefault();
    const { title } = this.props;
    this.props.store.selectedTool = title;
    this.props.toggleDropdown();
  };

  render() {
    const { displayTitle } = this.props;
    return (
      <li className="w-26 font-light text-slate-700 rounded-md drop-shadow-lg bg-white border-gray-400 hover:bg-blue-300 my-3">
        <a
          href="#"
          className="block py-1 px-2"
          onClick={this.handleClick}
        >
          {displayTitle}
        </a>
      </li>
    );
  }
}

@inject("store")
@observer
class DropdownMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  toggleDropdown = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  handleClickOutside = (event) => {
    if (event.target.closest('.absolute')) return;
    this.setState({
      isOpen: false,
    });
  };

  componentDidMount() {
    document.addEventListener("click", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  }

  render() {
    const { items } = this.props;
    const { isOpen } = this.state;
    return (
      <div className="absolute mt-3 right-5">
        <button className="bg-white shadow-sm focus:shadow-inner text-md border border-b-2 border-zinc-300 hover:bg-blue-200 text-slate-800 font-light py-1 px-2 rounded-t-xl rounded-b-lg"  onClick={this.toggleDropdown}>
          Switch Tool &nbsp; &#9661;
        </button>
        {isOpen && (
          <ul className="bg-sky-50 border border-slate-300 bg-white absolute z-10 text-black py-1 px-3 rounded-b-lg shadow-lg" style={{top: '36px', left: 2.1}}>
            {items.map(({ title, displayTitle }, index) => (
              <DropdownItem key={index} title={title} displayTitle={displayTitle} toggleDropdown={this.toggleDropdown}/>
            ))}
          </ul>
        )}
      </div>
    );
  }
}

export default DropdownMenu;