import React, { Component } from 'react';
import { observer, inject,  } from 'mobx-react'

import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

import Loader from './Loader'


const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgb(241 245 249)' ,
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));



@inject('store')
@observer
class SummaryAccordions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,

    };
    this.mounted = false;
  }


  componentDidMount() {
    this.mounted = true;
    this.init();
  }
  
  componentWillUnmount() {
    this.mounted = false;
  }
  

  init = async () => {
		this.refreshSummaries()
	}


  refreshSummaries = async () => {
    // Set the loading state to true before making the API call
    let response = await this.props.store.api.post('/user/summary/view',  {documentId: window.location.href.split('/').pop()})
    if (this.mounted) { // Check whether the component is mounted before updating the state
      if (response.data.length > 0) {
        const summaryItems = [...response.data]
        const summaryValues = summaryItems.map((item) => item.data)
        this.props.store.savedSummaryItems = summaryValues

        }
      }
    }



  handleChange = (panel) => (event, isExpanded) => {
    this.setState({ expanded: isExpanded ? panel : false });
  };

  render() {
    const { items } = this.props;
    const { expanded } = this.state;

    return (
      
      <div>
      <div> {this.props.store.isSummaryLoading   ? <div className="flex justify-center mb-2">
			<Loader active={this.props.store.isSummaryLoading } className="w-10 h-10" />
		</div> : <>

		</>}</div>
      {items.map((item, index) => (
        <Accordion
          key={index}
          expanded={expanded === `panel${index}`}
          onChange={this.handleChange(`panel${index}`)}
        >
          <AccordionSummary
            aria-controls={`panel${index}d-content`}
            id={`panel${index}d-header`}
          >
            <Typography sx={{ width: '33%', flexShrink: 0, color: 'rgb(30 58 138)', fontWeight: 'bold' }}>
              {item.summary}
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>{item.details}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <pre className='mt-0 mb-0 p-1 '>
              <Typography
                className='overflow-y-scroll whitespace-pre-wrap pl-5 pr-4'
                style={{ maxHeight: '50vh' }}
              >
                {item.content}
              </Typography>
            </pre>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
    );
  }
}

export default SummaryAccordions;