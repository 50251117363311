import React, { Component } from 'react';
import { observer, inject } from 'mobx-react'
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useMediaQuery from '@mui/material/useMediaQuery';




const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 64,
  height: 34,
  padding: 9,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#5f748a",
      transform: "translateX(22px)",
      "& .MuiSwitch-thumb:before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="21" width="23.5" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          "#fff"
        )}" d="M16 8c0 3.866-3.582 7-8 7a9.06 9.06 0 0 1-2.347-.306c-.584.296-1.925.864-4.181 1.234-.2.032-.352-.176-.273-.362.354-.836.674-1.95.77-2.966C.744 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7zM5 8a1 1 0 1 0-2 0 1 1 0 0 0 2 0zm4 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0zm3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/></svg>')`
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#c1cedb" : "#c1cedb"
      }
    }
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: "#054482",
    width: 32,
    height: 32,
    "&:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 1.25,
      top: 1.8,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "5.44px",
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="24.5" width="23.5" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        "#fff"
      )}" d="M.102 2.223A3.004 3.004 0 0 0 3.78 5.897l6.341 6.252A3.003 3.003 0 0 0 13 16a3 3 0 1 0-.851-5.878L5.897 3.781A3.004 3.004 0 0 0 2.223.1l2.141 2.142L4 4l-1.757.364L.102 2.223zm13.37 9.019.528.026.287.445.445.287.026.529L15 13l-.242.471-.026.529-.445.287-.287.445-.529.026L13 15l-.471-.242-.529-.026-.287-.445-.445-.287-.026-.529L11 13l.242-.471.026-.529.445-.287.287-.445.529-.026L13 11l.471.242z"/></svg>')`
    }
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "#c1cedb" : "#c1cedb",
    borderRadius: 20 / 2
  }
}));

@inject("store")
@observer
class CustomizedSwitch extends Component {

  constructor(props) {
		super(props);
    this.state = { selectedValue: null,
      isScreenWidth1280OrLess: window.innerWidth < 1280, 
    };
		this.setSelectedValue = this.setSelectedValue.bind(this);
    this.handleResize = this.handleResize.bind(this);
	}

  setSelectedValue(e) {
    this.setState({ selectedValue: e.target });
    if (e.target.checked) {
      this.props.store.mode = "chat"
    } else {
      this.props.store.mode = "tools"
    }
    this.props.store.error = "";
    this.props.store.currentInput = "";
	}

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize() {
    this.setState({
      isScreenWidth1280OrLess: window.innerWidth < 1280,
    });
  }


  


  render() {
   
    const { isScreenWidth1280OrLess } = this.state;

    return (
      <div
      className={`switch bg-white border border-slate-300 align-items-center rounded-full mb-6 shadow-sm shadow-blue-800 bg-slate-100 h-11 ${isScreenWidth1280OrLess ? 'mr-1' : 'mr-5'}`}
      >
        <Stack direction="row" spacing={0} alignItems="center" justifyContent="center">
          <Typography sx={{ mr: isScreenWidth1280OrLess ? '0rem' : '1.6rem', ml: isScreenWidth1280OrLess ? '.5rem' : '0rem', fontSize: isScreenWidth1280OrLess ? 14 : 16  }}>Script Tools</Typography>
          <FormGroup >
            <FormControlLabel
              control={<MaterialUISwitch sx={{ m: .6, mr:1, ml:1 }} />}
              onChange={this.setSelectedValue}
              checked={this.props.store.mode === 'tools' ? false : true}
            />
          </FormGroup>
          <Typography sx={{ ml: isScreenWidth1280OrLess ? '-1.5rem' : '-.3rem', mr: isScreenWidth1280OrLess ? '.5rem' : '0rem', fontSize: isScreenWidth1280OrLess ? 14 : 16 }}>Writing Partner</Typography>
        </Stack>
      </div>
    );
  }
}

export default CustomizedSwitch;
