import React, { useRef } from "react";

function Button({ title, onClick, Icon, disabled, children }) {
  const buttonRef = useRef(null);


  return (
    <button
      ref={buttonRef}
      className={disabled ? 
		`select-none py-1 px-4 border-2 border-gray-300 bg-gray-300 hover:bg-gray-400 disabled hover:to-gray-700 text-white  rounded-full flex md:inline-flex font-medium text-l cursor-pointer mt-1 md:mx-0 transition` :  
		`select-none py-1 px-4 border-2 border-blue-100 bg-gradient-to-r from-blue-500 to-blue-600  hover:to-purple-500 text-white  rounded-full flex md:inline-flex font-medium text-l cursor-pointer mt-1 md:mx-0 transition` } 
	onClick={disabled ? null : onClick}>
      {disabled ? "Generating..." : title}
      {children}
    </button>
  );
}

export default Button;