import Loader from './Loader'
//import Typist from 'react-typist';


import {
	CheckIcon,
} from '@heroicons/react/solid'
import {
	DuplicateIcon, ClipboardCopyIcon, ArrowsExpandIcon, XIcon
} from '@heroicons/react/outline'

import NoteAddSRoundedIcon from '@mui/icons-material/NoteAddRounded';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';



import styled from 'styled-components'
import { observer, inject } from 'mobx-react'


import React, { useEffect, useRef, useState } from "react"
import { saveAs } from 'file-saver';
import '../css/quill-editor-style.css'

import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';


const ComponentStyles = () => (
	<style jsx global>{`
	
	.whitespace-pre-wrap::-webkit-scrollbar {
		width: 3.5px;
		max-height: 4px;  
	
	  }
  
	  .whitespace-pre-wrap::-webkit-scrollbar-thumb {
		background-color:#5d7da1;
		border-radius: 35px;

	  }
  
	  
  
	  .whitespace-pre-wrap::-webkit-scrollbar-thumb:hover {
		background-color: #555;
	  }
  
	  .whitespace-pre-wrap::-webkit-scrollbar-corner {
		background-color: #f5f5f5;
	  }
  
	  /* Always show the scrollbar */
	  .whitespace-pre-wrap::-webkit-scrollbar {
		visibility: visible;
	  }
	  @media (max-width: 1280px) {
		.output-component {
		  max-width: 20vw;
		}
	  }
	`}</style>
);





export const Output = inject('store')(observer(({ mode, store, title, desc, Icon, output, code, language, outputs, loading, streaming, children, fromColor, toColor, outputsColor, OutputsIcon }) => {

	
	
	
	let editorBottom = 0;
	let editorTop = 0;
	const editorElem = document.querySelector('.editor-container');
    if (editorElem) {
	  const editorRect = editorElem.getBoundingClientRect();
	  editorTop = editorRect.top;
	  editorBottom = editorRect.bottom
	
	}

	

	const buttonDiv = document.querySelector('.py-0.px-5.shadow-sm.border');
	if (buttonDiv) {
		const buttonRect = buttonDiv.getBoundingClientRect();
		const diff = buttonRect.bottom + 94 - editorTop;
	
		buttonDiv.style.maxHeight = `${editorBottom - diff}px`;
		store.outputHeight = `${editorBottom - diff}px`;
	}
	
	

	
	if (!output || !store.generationSourceCategory) {
		output = ""
	}

	//console.log(store.generationSourceCategory)
	//console.log(output)

	 //const outputArray = output.split('\n'); // split output on newlines

	let flattenedArray;

	if (store.generationSourceCategory === "generateScreenplay" && output && !store.streaming) {

		//console.log(output)
		const cleanOutput = output.replace(/,(?=\s*})/g, '');
		//console.log(cleanOutput)
		const parsedOutput = JSON.parse(cleanOutput)
		const scriptOutputArray = parsedOutput.map(obj => Object.entries(obj));


		flattenedArray = scriptOutputArray.reduce((acc, arr) => {
			return acc.concat(arr);
		}, []);



		// don't need this anymore with streaming

		/* let outputString = "";
		

		for (let line of flattenedArray) {
			if (line[0] === "Character Name" || line[0] === "Parenthetical") {
				outputString = outputString + line[1] + "\n"
			} else {
				outputString = outputString + line[1] + "\n\n"
			}
		}
		output = outputString; */
		
	} 





	const [addTooltipText, setAddTooltipText] = useState("");


	useEffect(() => {
		const isScreenplayOutput = store.generationSourceCategory === "generateScreenplay" || store.generationSourceCategory === "generateLine";
		setAddTooltipText(isScreenplayOutput ? "Add to Script" : "Add to Notes");

	}, [store.generationSourceCategory]);

	const handleAddClick = () => {
		
		const isScreenplayOutput = store.generationSourceCategory === "generateScreenplay" || store.generationSourceCategory === "generateLine";
		if (isScreenplayOutput) {
			if (store.selectedDocType !== "Script") {
				store.switchedForInsert = true;
				store.selectedDocType = "Script";

			}

			store.scriptInsertText = (store.generationSourceCategory === "generateScreenplay") ? flattenedArray : output;

		} else {

			if (store.selectedDocType !== "Notes") {
				store.switchedForInsert = true;
				store.selectedDocType = "Notes";
				//console.log(store.selectedDocType)

			}

			store.notesInsertText = output;
		}
		setAddTooltipText(isScreenplayOutput ? "<<< Click where to insert into your script" : "<<< Click where to insert into your notes");
		setTimeout(() => {
			setAddTooltipText(store.generationSourceCategory === "generateScreenplay" || store.generationSourceCategory === "generateLine" ? "Add to Script" : "Add to Notes");
		}, 5000);

		setTimeout(function() {
			setIsFullScreen(false);
		  }, 1000);
	};


	const [saveTooltipText, setSaveTooltipText] = useState("Save to Memory");
	const [copyTooltipText, setCopyTooltipText] = useState("Copy");

	const elementRef = useRef(null);
	const [isFullScreen, setIsFullScreen] = useState(false);

	const saveMemoryToDatabase = async ({ formattedMemory }) => {
		try {
			await store.api.post('/user/memory', { memory: formattedMemory, documentId: window.location.href.split('/').pop() })
		} catch (err) {
			console.log(err)
			console.log("failed to save memory to database")
		}
	}



	const storeToMemory = () => {
		const input = store.openaiInput.replace(/\n+/g, " ")
		let firstNineInputWords = input;
		if (input.split(" ").length > 9) {
			firstNineInputWords = input.split(" ").slice(0, 9).join(" ") + " ..."
		}

		let firstSevenOutputWords = ""; 
		if (store.generationSourceCategory === "generateScreenplay") {
			firstSevenOutputWords = store.scriptWriteOutputShow.split(" ").slice(0, 7).join(" ") + " ..."
		} else {
			firstSevenOutputWords = output.split(" ").slice(0, 7).join(" ") + " ..."
		}
		

		const outputSource = store.apiSource === '/autowrite' ? "Autowrite" : 
							 store.apiSource === '/rewrite' || store.apiSource === "/rewriteLine" ? "Rewrite" :  
							 (mode === 'chat') ? "Writing Partner" : 
							 (store.selectedTool === null) ? "Opening Scene" : store.selectedTool

		const ouputPlusInput = store.apiSource === '/autowrite' ? store.scriptWriteOutputShow.trim() :  
							   store.generationSourceCategory === "generateScreenplay" ? "Input: " + input + "\n\n" +  store.scriptWriteOutputShow.trim() :
							   "Input: " + input + "\n\n" + output.trim()

		const formattedResult = { summary: outputSource, details: store.apiSource === '/autowrite' ? firstSevenOutputWords : firstNineInputWords, content: ouputPlusInput }
		if (store.savedMemoryItems[0].summary === "(click for details)") {
			store.savedMemoryItems = []
		} else {
			store.savedMemoryItems = [formattedResult, ...store.savedMemoryItems,]
		}

		saveMemoryToDatabase({ formattedMemory: formattedResult })

	}



	return (<div className="grow-0 lg:max-w-lg md:max-w-xs sm:max-w-xs mr-4 ml-1  mt-4"


	>
		{store.loading ? <div className="flex justify-center mt-10">
			<Loader active={store.loading} className="w-10 h-10" />
		</div> : <>

		</>}
		{output ?
			<div
				ref={elementRef}
				style={isFullScreen ? {
					position: 'fixed',
					top: 0,
					left: 0,
					width: '100%',
					height: '100%',
					zIndex: 9999,

				} : {}}
				className="border border-sky-800 bg-white rounded-t-2xl rounded-b-xl text-left  transform transition-all  transition  hover:shadow-lg hover:shadow-blue-600/30">

{ !store.streaming ?

				<div className="flex px-4 py-3 space-x-1">
					

					

					<Shortcut className="p-1 rounded-full cursor-pointer hover:bg-sky-100 relative group flex flex-col items-center group text-gray-300"
						onClick={handleAddClick}
					>
						<NoteAddSRoundedIcon className="w-6 h-6 text-gray-500" />
						<Tooltip className="absolute top-8 flex flex-col items-center mb-4 group-hover:flex">
							<span className={`${(isFullScreen && addTooltipText.includes("<<<")) ? 'left-24' : 'left-0'} relative z-10 p-3 ml-12 text-sm leading-none text-gray-800 bg-white bg-opacity-90 shadow-lg text-center backdrop-filter backdrop-blur rounded-md`}>
								{addTooltipText}
							</span>
						</Tooltip>
					</Shortcut>


					<Shortcut className="p-1 rounded-full cursor-pointer hover:bg-sky-100 relative group flex flex-col items-center group text-gray-300"
						onClick={() => {
							storeToMemory();
							setSaveTooltipText("Saved!");
							setTimeout(() => {
								setSaveTooltipText("Save to memory");
							}, 1750);
						}}

					>
						< SaveRoundedIcon className="w-6 h-6 text-gray-500" />
						<Tooltip className="absolute top-8 flex flex-col items-center mb-4 group-hover:flex">
							<span className="relative z-10 p-3 text-sm leading-none text-gray-800 bg-white bg-opacity-90 shadow-lg text-center backdrop-filter backdrop-blur rounded-md">{saveTooltipText}</span>
						</Tooltip>
					</Shortcut>



					<Shortcut className="p-1 rounded-full cursor-pointer hover:bg-sky-100 relative group flex flex-col items-center group text-gray-300"
						onClick={() => {
							store.copyToClipboard(store.generationSourceCategory === "generateScreenplay" ? store.scriptWriteOutputShow: output);
							setCopyTooltipText("Copied!");
							setTimeout(() => {
								setCopyTooltipText("Copy");
							}, 1750);
						}}
					>
						<AssignmentRoundedIcon className="w-6 h-6 text-gray-500 " />
						<Tooltip className="absolute top-8 flex flex-col items-center mb-4 group-hover:flex">
							<span className="relative z-10 p-3 text-sm leading-none text-gray-800 bg-white bg-opacity-90 shadow-lg text-center backdrop-filter backdrop-blur rounded-md">{copyTooltipText}</span>
						</Tooltip>
					</Shortcut>

					


					<FullScreenButton elementRef={elementRef} isFullScreen={isFullScreen} setIsFullScreen={setIsFullScreen} store={store} />

					
					{!isFullScreen ?

						<div class="flex-1 justify-end ">
							<Shortcut className=" mr-2 w-7 p-0 ml-auto rounded-full cursor-pointer relative group flex flex-1 flex-col justify-end items-center group text-gray-300"
								onClick={() => {
									store.closeOutput = true;
									output = null;
								}}
							>
								<XIcon className="w-[1.6rem] h-[1.6rem] text-blue-800  hover:bg-red-100 bg-sky-50  " />
								<Tooltip className="absolute top-8 mr-14 flex flex-col items-center mb-4 group-hover:flex">
									<span className="relative z-10 p-3 text-sm leading-none text-gray-800 bg-white bg-opacity-90 shadow-lg text-center backdrop-filter backdrop-blur rounded-md">Close Output</span>
								</Tooltip>
							</Shortcut>
						</div>


						: null}

				</div>


:

<div className="flex px-4 py-3 space-x-1">


<FullScreenButton elementRef={elementRef} isFullScreen={isFullScreen} setIsFullScreen={setIsFullScreen} store={store}/>

<Box sx= {{ width: !isFullScreen ?  '68%' : '30%', marginTop: `13px`, paddingLeft: `32px`  }}>
      <LinearProgress  color="primary" />
    </Box>



					{!isFullScreen ?

						<div class="flex-1 justify-end ">
							{/* <Box sx={{ display: 'flex' }}>
							<CircularProgress />
							</Box> */}
							{/* <Shortcut className=" mr-2 w-7 p-0 ml-auto rounded-full cursor-pointer relative group flex flex-1 flex-col justify-end items-center group text-gray-300"
								onClick={() => {
									store.closeOutput = true;
									output = null;
								}}
							>
								<XIcon className="w-[1.6rem] h-[1.6rem] text-blue-800  hover:bg-red-100 bg-sky-50  " />
								<Tooltip className="absolute top-8 mr-14 flex flex-col items-center mb-4 group-hover:flex">
									<span className="relative z-10 p-3 text-sm leading-none text-gray-800 bg-white bg-opacity-90 shadow-lg text-center backdrop-filter backdrop-blur rounded-md">Close Output</span>
								</Tooltip>
							</Shortcut> */}

							
						</div>


						: null}

</div>

}









				<div className="px-3 pl-6 py-0" >

					<div
						className="output-component whitespace-pre-wrap overflow-y-scroll  grow-0 text-gray-800 text-md  px-4 pt-4 pb-5 mb-2"
						style={{ marginLeft: isFullScreen ? '120px' : '0', marginRight: isFullScreen ? '5px' : '0',  maxHeight: isFullScreen ? '90.5vh' : store.focusMode ? '87vh' : store.outputHeight ?  store.outputHeight : '38vh' }}
					>
						<ComponentStyles />

						<div className='pr-2'>

						{store.generationSourceCategory === "generateScreenplay" ? store.scriptWriteOutputShow: output}

						</div>

					</div>

					


					




				</div>

			</div>

			: null}
	</div>)
}))




const FullScreenButton = ({ elementRef, isFullScreen, setIsFullScreen, store }) => {
	const toggleFullScreen = () => {
		setIsFullScreen(!isFullScreen);
	};


	return (
		<Shortcut className="p-1 rounded-full cursor-pointer hover:bg-sky-100 hover:text-green-700 relative group flex flex-col items-center group text-gray-300"
			onClick={toggleFullScreen}>
			{isFullScreen ?
				< CloseFullscreenIcon className="w-6 h-6 text-gray-500" /> : <ArrowsExpandIcon className="w-6 h-6 text-gray-500" />
			}
			{isFullScreen ?
				<Tooltip className="absolute top-8 flex flex-col items-center mb-4 group-hover:flex">
					<span className={`${ store.streaming ? 'left-8' : 'right-8'}  relative z-10 p-3 text-sm leading-none text-gray-800 bg-white bg-opacity-90 shadow-lg text-center backdrop-filter backdrop-blur rounded-lg`}>Exit Fullscreen</span>
				</Tooltip>
				:
				<Tooltip className="absolute top-8 flex flex-col items-center mb-4 group-hover:flex">
					<span className="right-3 relative z-10 p-3 text-sm leading-none text-gray-800 bg-white bg-opacity-90 shadow-lg text-center backdrop-filter backdrop-blur rounded-lg">Fullscreen</span>
				</Tooltip>
			}
		</Shortcut>




	);
};

const Tooltip = styled.div`
	display:none;
	white-space: nowrap;
	z-index: 60;
`

const Shortcut = styled.div`
	&:hover ${Tooltip} {
		display: flex;
	}
`






export default Output


