import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined';


const obj = {

	title: "Chapter to Scene",
	desc: "Convert a chapter or short story into a screenplay scene",
	category: "generateScreenplay",
	Icon: AutoStoriesOutlinedIcon,
	// tags: [],
	permissions: ['user'],
	
	fromColor: "purple-500",
	toColor: "blue-500",

	to: "/ai/editor",
	api: "/convertChapter",

	output: {
		title: "",
		desc: "",
		Icon: false,
		color: "blue",
	},

	button:{
		title: "Generate Scene",
	},


	prompts: [{
		title:"Chapter → Scene",
		desc: "Paste (or type) the chapter/story you want converted into a screenplay scene",
		// n: 1,
		prompts: [{ 
				title: "", 
				attr: "content",  
				value: "", 
				placeholder: "Paste (or type) the chapter/story you want converted into a detailed screenplay scene", 
				label: "",
				type: "textarea",
	
				// max: 100,
				minWords: 250,
				maxWords: 3000,
				required: true,
				error: "",
				example: "Hello World ",
			},
		],
		example: {
			output: "Hello World",
			// outputs: [
			// 	"The sun is very old, over 4.5 billion years",
			// 	"At 10,000 degrees, sun is also very hot",
			// 	"Gasses called coronal mass ejections erupt from the sun",
			// ],
			// Icon: RefreshIcon,
			//color: "blue",
		}
	}]
		
}

export default obj
