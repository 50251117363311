import { observer, inject } from 'mobx-react'

import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import {
  $getSelection,
  $isRangeSelection,
  COMMAND_PRIORITY_LOW,
  KEY_ENTER_COMMAND,
  INSERT_LINE_BREAK_COMMAND,
  DELETE_CHARACTER_COMMAND,
  KEY_ARROW_LEFT_COMMAND,
  INSERT_PARAGRAPH_COMMAND,
} from "lexical"

import {
  $moveCharacter,
} from '@lexical/selection';

import {
  $createHeadingNode,

} from "@lexical/rich-text";

import {
  $wrapNodes,

} from "@lexical/selection";
import { useEffect } from "react"

/**
 * This plugin adds the ability to indent content using the tab key. Generally, we don't
 * recommend using this plugin as it could negatively affect acessibility for keyboard
 * users, causing focus to become trapped within the editor.
 */

function isPrefixInSet(str, mySet) {
  for (let item of mySet) {
    if (item.startsWith(str)) {
      return true;
    }
  }
  return false;
}


export const HandleEnterPlugin = inject('store')(observer(({ store }) => {
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    return editor.registerCommand(
      KEY_ENTER_COMMAND,
      event => {
        let selection = $getSelection()

        if (!$isRangeSelection(selection)) {
          return false
        }

        const focusNode = selection.focus.getNode();
        const element =
          focusNode.getKey() === "root"
            ? focusNode
            : focusNode.getTopLevelElementOrThrow();
        const elementKey = element.getKey();
        
        const elementDOM = editor.getElementByKey(elementKey);
        const currClass = elementDOM.classList.value.replace(' ltr', '')

        store.lastLineType = currClass;

        if (currClass === "editor-text-parens") {
          editor.focus(); // this is key for some reason...

          $moveCharacter(selection, false, false);

        
         }  else if (currClass === "editor-text-character") {
            const character = elementDOM.textContent.toUpperCase()
            console.log(character)
            if (!isPrefixInSet(character, store.scriptCharacters)) {
              store.scriptCharacters.add(character)
              console.log(store.scriptCharacters)
            }

         }  else if (currClass === "editor-paragraph-script") {
          $wrapNodes(selection, () => $createHeadingNode("h2"))

       } 
        
      
          
        // this is needed for char->parens->dialogue flow
        /*}  else if (currClass === "editor-text-character") { 

          selection.insertText("()")
          selection.focus.offset = selection.focus.offset - 2;
          selection.anchor.offset = selection.anchor.offset - 2;
        } */



      },
      COMMAND_PRIORITY_LOW
    )
  })

  return null
}))
