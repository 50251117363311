import React, { useRef } from "react";
import {
	LightningBoltIcon,
} from '@heroicons/react/outline'

function Button2({ title, onClick, Icon, disabled, children }) {
  const buttonRef = useRef(null);


  return (
    <button
      ref={buttonRef}
      className={disabled ? 
		`select-none py-0 h-6.5 xl:h-7 px-5 border-2 border-gray-300 bg-gray-400 disabled hover:to-gray-700 text-white  rounded-2xl flex md:inline-flex font-medium text-l cursor-pointer md:mx-0 transition` :  
		`generate-button mt-1 py-0 h-6.5 xl:h-7  px-5 shadow-sm border border-slate-100 hover:shadow-md hover:drop-shadow-md  select-none bg-[#4187e8] hover:bg-[#23affa] focus:shawdow-none text-white  rounded-full flex md:inline-flex font-medium text-l cursor-pointer md:mx-0 transition` } 
	onClick={disabled ? null : onClick}>
      {disabled ? "working on it..." : <Icon style={{width:"2.3rem", height:"1.6rem"}}/>}
      {children}
    </button>
  );
}

export default Button2;