import React from "react"
import Joyride, { STATUS } from "react-joyride"
import { useState } from "react";

import { observer, inject } from 'mobx-react'



import {
	ArrowsExpandIcon
} from '@heroicons/react/outline'



const BasicDemo = inject('store')(({
    store,
  }) => {
    const [run, setRun] = useState(true);
       
    const [steps, setSteps] = useState([
      {
        content: <h2>Features Tour</h2>,
        locale: { skip: <h5 aria-label="skip">Skip Tour</h5> },
        placement: "center",
        target: "body"
      },
      {
        content: "Use it to return to the documents dashboard, see a list of keyboard shortcuts, or run this tour again at any point.",
        floaterProps: {
          disableAnimation: true
        },
        spotlightPadding: 20,
        target: ".drawer-button",
        title: "Clicking here opens the left sidebar menu."
      },
      {
        content: "Toggle between your Script and your Notes, or access saved generations from different AI features.",
        placement: "right",
        styles: {
          options: {
            width: 300
          }
        },
        target: ".vertical-tabs",
        title: "Project Panel"
      },
      {
        content:"The current script line type is always highlighted here (new lines default to Action). Click or tab to switch between line types.",
        placement: "bottom",
        target: ".toolbar",
        title: "Script Toolbar Menu"
      },
      /* {
        content: "Along with clicking, you can press the tab button on your keyboard to move right accross line types (or shift + tab to move left).",
        placement: "left",
        target: ".toolbar",
        title: "Switch between line types",
        
      }, */
     {
      content: "Note: this will overwrite any text in the current document.",
      placement: "left",
      target: ".format.upload",
      title: "Import a Final Draft file (.fdx)", 
     },
     {
        content: "",
        placement: "right",
        target: ".format.download",
        title: "Export your script to a Final Draft compatible file", 
       },
       {
        content: "Toggle on/off here. The table of contents will automatically update as you add new scenes. Use it to quickly navigate around your script.",
        placement: "bottom",
        target: ".toolbar-item.spaced.contents",
        title: "Table of Contents", 
       },

       {
        content: "Clear the clutter (you can also use the ⌘ + ctrl keyboard shortcut).",
        placement: "bottom",
        target: ".toolbar-item.spaced.focus-mode",
        title: "Focus Mode", 
       },

       {
        content: "Assign pre-made voices and instantly generate a Table Read of your script.",
        placement: "bottom",
        target: ".toolbar-item.spaced.table-read",
        title: "Table Read Mode", 
       },

       {
        content: "The title is used for saving the current project in the documents dashboard.",
        placement: "right",
        target: ".editor-placeholder",
        title: "Add a title here", 
       },
       /* {
        content: "Whenever you select text in the script, a menu will popup with formatting options and various AI features.",
        placement: "top",
        target: ".editor-input",
        title: "Floating Toolbar Menu", 
       }, */
       {
        content: "Don't feel like typing? Dictate your script instead.",
        placement: "top",
        target: ".action-button",
        title: "Dictation", 
       },
       {
        content: "Script Tools provides targeted AI features for specific use cases. Writing Partner is a more open-ended mode for asking questions and getting back ideas & suggestions.",
        placement: "left",
        target: ".switch",
        title: "Toggle between Script Tools and Writing Partner", 
       },
       {
        content: "Select the tool you want to use from the dropdown menu. The placeholder text will describe the tool's purpose and how to use it.",
        placement: "left",
        target: ".entry-prompt",
        title: "Script Tools", 
       },
      /*  {
        content: "Different tools have different maximum words allowed for the input. Your total will update here.",
        placement: "left",
        target: ".text-xs.absolute",
        title: "Word Limit", 
       },
       {
        content: "Click here when you're ready to generate. The output will appear directly below. Once the generation is complete, you'll be able to save, insert, or copy it.",
        placement: "left",
        target: ".generate-button",
        title: "Generate Button", 
       },
       {
        content: (
            <div>
              <h4>Pro Tip</h4>
            <ArrowsExpandIcon className="w-7 h-7 ml-[86px] "/>
             <span>You can expand the output to full screen while it's generating for better readibility.</span>
             
            </div>
          ),
        
        placement: "bottom",
        target: ".generate-button", 
       }, */
     
       

    ]
    )

/*   useMount(() => {
    a11yChecker()
  }) */




  const handleJoyrideCallback = (data, store) => {
    const { status, type } = data;
    const finishedStatuses = ["finished", "skipped"];
    
    
    console.log(store.runFeaturesTour)
    // despite returning true above, this still won't run after skipping the tour
    if (finishedStatuses.includes(status) && !store.runFeaturesTour) {
      setRun(false);
    }
  };

  return (
    <div>
      <Joyride
        callback={(data) => handleJoyrideCallback(data, store)}
        continuous
        hideCloseButton
        run={run}
        scrollToFirstStep
        showProgress
        showSkipButton
        steps={steps}
        styles={{
          options: {
            arrowColor: "#e6efff",
            backgroundColor: "#e6efff",
            overlayColor: "rgba(80, 81, 87, 0.4)",
            primaryColor: "#635a5a",
            textColor: "#4b4d52",
            width: 250,
            zIndex: 1000,
          }
        }}
      />
   
    </div>
    )
}
)

export default BasicDemo;