import * as React from 'react';
import { observer, inject } from 'mobx-react'

import Button4 from './Button4'

import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import InboxIcon from '@mui/icons-material/Inbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import SlowMotionVideoIcon from '@mui/icons-material/SlowMotionVideo';

import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
import GroupsIcon from '@mui/icons-material/Groups';

import InterpreterModeIcon from '@mui/icons-material/InterpreterMode';

import { Dropdown, Container, Col } from "@nextui-org/react";


import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ButtonMUI from '@mui/material/Button';


const voices = require('../Voices/voiceData.js');

function checkVoiceAssignment(characterNames) {
    const unassignedCharacters = [];
    const assignedCharacters = [];
    const assignedVoices = [];

    for (const name in characterNames) {
        if (!characterNames[name].voice) {
            unassignedCharacters.push(name);
        }
        else {
            //assignedCharacters.push(name);
            assignedVoices.push(characterNames[name].voice);
        }
    }

    if (unassignedCharacters.length > 0) {
        console.log('Not all characters have been assigned a voice:', unassignedCharacters);
    } else {
        console.log('All characters have been assigned a voice.');
    }


    const result = {
        //assignedCharacters: assignedCharacters,
        unassignedCharacters: unassignedCharacters,
        assignedVoices: assignedVoices
    };

    return result;

}


function shuffle(array) {
    let currentIndex = array.length, randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex != 0) {

        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
    }

    return array;
}


export const CharacterList = inject('store')(observer(({ store }) => {

    const [selected, setSelected] = React.useState({});
    const [openDialog, setOpenDialog] = React.useState(false);

    /* const selectedValue = React.useMemo(() => {
        return Object.entries(selected)
          .map(([key, value]) => {
            const characterName = key.replaceAll("_", " ");
            const selectedVoice = value || "Select Voice";
            return `${characterName}: ${selectedVoice}`;
          })
          .join(", ");
      }, [selected]);
     */


    // ADD A POPUP IF NOT ALL CHARACTERS HAVE A VOICE ASSIGNED - INDICATE TO USER THAT THEY WILL BE RANDOMLY ASSIGNED REGARDLESS OF GENDER OR AGE


    let characterNames = {};

    store.selectedCharacters.forEach(name => {
        characterNames[name] = { voice: selected[name] || "" };
    });


    const onGenerateClick = () => {

        const characterResult = checkVoiceAssignment(characterNames)
        const unassigned = characterResult.unassignedCharacters;

        if (unassigned.length > 0) {
            setOpenDialog(true)
        } else {
            //const characterToVoiceID = { narrator: "ww9FNAlM9jHQGGm38iS2" }
            let characterToVoiceID = { }


            for (const name in characterNames) {
                characterToVoiceID[name] = voices.find(voice => voice.name === characterNames[name].voice).voice_id;
            }

            store.characterToVoiceID = characterToVoiceID;

            store.selectVoicesMode = false;
            store.voiceMode = true;
            store.showTableRead = true;
            store.generateVoiceReady = true;
            store.readCompleted = false;
        }

    }

    const assignCharacters = () => {
        const characterResult = checkVoiceAssignment(characterNames)
        const unassigned = characterResult.unassignedCharacters;
        //const assigned = characterResult.assignedCharacters;
        const assignedVoices = characterResult.assignedVoices;

        //console.log(assigned)

        let availableVoices = [];

        for (let voice of voices) {

            if (!assignedVoices.includes(voice.name)) {
                availableVoices.push(voice.name)
            }

        }

        shuffle(availableVoices)

        //console.log(availableVoices)

        for (let i = 0; i < unassigned.length; i++) {

            if (availableVoices.length > 0) {
                characterNames[unassigned[i]].voice = availableVoices.pop()
                assignedVoices.push(characterNames[unassigned[i]].voice)

            } else {
                // need to handle case where this runs out as well
                characterNames[unassigned[i]].voice = assignedVoices.shift()
            }
        }

        //console.log(characterNames)

        //const characterToVoiceID = { narrator: "ww9FNAlM9jHQGGm38iS2" }
        let characterToVoiceID = { }

        for (const name in characterNames) {

            characterToVoiceID[name] = voices.find(voice => voice.name === characterNames[name].voice).voice_id;
        }

        store.characterToVoiceID = characterToVoiceID;

        store.selectVoicesMode = false;
        store.voiceMode = true;
        store.showTableRead = true;
        store.generateVoiceReady = true;
        store.readCompleted = false;


    }

    /* React.useEffect(() => {
        console.log(characterNames);
      }, [selected]);
      
     */
    return (
        <>
            <Box className="shadow-lg shadow-indigo-200 rounded-xl mt-2 lg:mt-0 border-2 border-indigo-100  border-b-indigo-200" sx={{ width: '100%', minWidth: "40vw", maxWidth: "50vw", bgcolor: 'background.paper', }}>
                <List className="bg-white rounded-t-xl border-b border-b-indigo-500" >
                    <ListItem>
                        <ListItemIcon>
                            <SwitchAccountIcon className="text-indigo-600" style={{ height: "26px", width: "26px" }} />
                        </ListItemIcon>
                        <ListItemText
                           primary={
                            <span>
                                {"Select Voices for  "} 
                                <span style={{ fontFamily: 'Coal', color: "#3645c9", fontSize: "15.5px" }} >
                                Characters
                                </span> 

                            </span>
                        }
                        />
                    </ListItem>
                    <div className='text-gray-500 text-[14px] ml-16 mr-4 pb-[10px]'>
                        <span> * Unassigned characters will get randomly assigned voices.</span>
                    </div>
                </List>


                <div>
                    {Object.keys(characterNames).map(name => (
                        <div>
                            <Divider />
                            <List key={name}>
                                <ListItem>
                                    <ListItemText primary={name} />

                                    <Dropdown>
                                        <Dropdown.Button
                                            flat
                                            size="sm"
                                            color="gradient"
                                            auto
                                            ghost
                                            css={{ tt: "capitalize" }}
                                        >
                                            {selected[name] || "Select Voice"}
                                        </Dropdown.Button>
                                        <Dropdown.Menu
                                            aria-label="Static Actions"
                                            color="primary"
                                            disallowEmptySelection
                                            items={voices}
                                            selectionMode="single"
                                            selectedKeys={selected[name]}
                                            onSelectionChange={(newSelectedKey) => {
                                                const selectedVoice = [...newSelectedKey][0] || "";
                                                setSelected(prevSelected => ({
                                                    ...prevSelected,
                                                    [name]: selectedVoice,
                                                }));
                                            }}
                                        >
                                            {item => (
                                                <Dropdown.Item
                                                    key={item.name}
                                                    withDivider={item.name === "Female #1"}

                                                >
                                                    {item.name}
                                                </Dropdown.Item>
                                            )}
                                        </Dropdown.Menu>
                                    </Dropdown>

                                </ListItem>

                            </List>
                        </div>
                    ))}
                </div>
            </Box>
            <div className="mt-6" />
            <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: "2px" }}>
                <button
                    className={`select-none py-0.25 px-6  border-2 border-blue-100 bg-blue-500 shadow-sm shadow-slate-300 hover:shadow-sm hover:shadow-slate-400 hover:drop-shadow-md hover:bg-gradient-to-r hover:from-[#a555fa] hover:to-[#397ff7]  active:shadow-inner active:shadow-slate-600 text-white   rounded-full flex md:inline-flex font-medium text-l cursor-pointer mt-1 md:mx-0 transition`}
                    onClick={() => onGenerateClick()}>
                    <InterpreterModeIcon style={{ width: "2.3rem", height: "1.6rem" }} />
                    Generate Table Read
                </button>


            </div>
            <Dialog
                open={openDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"

                modal={false}
            >
                <DialogTitle id="alert-dialog-title">
                    {"Some character voices haven't been assigned and will be assigned randomly."}
                </DialogTitle>
                <DialogActions>
                    <ButtonMUI variant="outlined" onClick={() => assignCharacters()}>Proceed anyway</ButtonMUI>
                    <ButtonMUI variant="outlined" onClick={() => setOpenDialog(false)} color="secondary" autoFocus>
                        Go back & assign
                    </ButtonMUI>
                </DialogActions>
            </Dialog>
        </>
    );

}))

export default CharacterList;