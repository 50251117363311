import "../css/audioElement.css";


import React, { useEffect } from "react";
import { observer, inject } from 'mobx-react'


import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Tooltip from '@mui/material/Tooltip';

import tableRead from '../Components/Assets/walkman.jpeg'
import acting from '../Components/Assets/conflict.png'


import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Padding } from "../../node_modules/@mui/icons-material/index";


const DrawerMenuTableRead = inject('store')(({
  store,
}) => {


  const [state, setState] = React.useState({
    left: false
  });

  const [tableReadData, setTableReadData] = React.useState([])
  const [checkedForReads, setCheckedForReads] = React.useState(false)


  useEffect(() => {
    getTableReadHistory()

  }, [])

  const toggleDrawer = (anchor, open) => (event) => {
    getTableReadHistory()
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };


  const getTableReadHistory = async () => {
    setCheckedForReads(true)
    let response = await store.api.post('/user/voiceEvent/view', { documentId: window.location.href.split('/').pop() })
    if (response.data.length > 0) {
      const TableReads = [...response.data]
      const TableReadData = TableReads
        .filter(item => item.textPreview)
        .map(({ generationId, textPreview, created }) => {
          const dateObj = new Date(created);

          const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
          const day = ("0" + dateObj.getDate()).slice(-2);
          const year = dateObj.getFullYear().toString().slice(-2);
          let hours = dateObj.getHours();
          const minutes = ("0" + dateObj.getMinutes()).slice(-2);
          let ampm = "am";

          if (hours > 12) {
            hours -= 12;
            ampm = "pm";
          } else if (hours === 12) {
            ampm = "pm";
          } else if (hours === 0) {
            hours = 12;
          }

          const formattedDate = `${month}/${day}/${year}`;
          const formattedTime = `${hours}:${minutes}${ampm}`;

          return {
            generationId,
            textPreview,
            created: `[${formattedDate}, ${formattedTime}]`
          };
        });




      setTableReadData(TableReadData)


    }

  }

  let currentAudioElement = null;

  const playTableReadAudio = async (generationId) => {
    let response = await store.api.post('/user/voiceHistory/view', { generationId: generationId });
    if (response.data.length > 0) {
      const AudioItems = [...response.data];
      const audioIds = AudioItems.map((item) => item.historyItemId);

      const audioElement = await preloadAudioClips(audioIds);

      const containerElement = document.getElementById('audio-container');

      if (currentAudioElement) {
        containerElement.removeChild(currentAudioElement);
      }

      containerElement.appendChild(audioElement);
      currentAudioElement = audioElement;

      playAudioClips(audioElement);
    }
  };
  
  async function preloadAudioClips(audioIds) {
    const audioDataPromises = audioIds.map(async (historyItemId) => {
      const response = await fetch(store.baseURL_AI + '/voiceGet', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ sampleId: historyItemId }),
      });
  
      const blob = await response.blob();
      return blob;
    });
  
    const audioData = await Promise.all(audioDataPromises);
    const combinedBlob = new Blob(audioData, { type: 'audio/mp3' });
    const audioURL = URL.createObjectURL(combinedBlob);
  
    const audioElement = new Audio();
    audioElement.src = audioURL;
    audioElement.controls = true; // Enable the default audio controls
  
    return audioElement;
  }
  
  function playAudioClips(audioElement) {
    let currentIndex = 0;
  
    audioElement.addEventListener('ended', playNextAudioClip);
  
    function playNextAudioClip() {
      audioElement.removeEventListener('ended', playNextAudioClip);
  
      // Check if there are more audio clips to play
      if (currentIndex < audioElement.length - 1) {
        currentIndex++;
        audioElement.addEventListener('ended', playNextAudioClip);
        audioElement.play();
      }
    }
  
    const containerElement = document.getElementById('audio-container');
containerElement.classList.add('audio-player');
    // Start playing the first audio clip
    audioElement.play();
  }


  const downloadTableReadAudio = async (generationId) => {
    let response = await store.api.post('/user/voiceHistory/view', { generationId: generationId })
    if (response.data.length > 0) {
      const AudioItems = [...response.data]
      const audioIds = AudioItems.map((item) => item.historyItemId)

      // Combine audio clipsDownload the audio file

      combineAudioClips(audioIds)


    }

  }





  function combineAudioClips(audioIds) {
    const audioContext = new AudioContext();
    const audioBuffers = [];
    let loadedCount = 0;

    // Load each audio clip and store their buffers
    audioIds.forEach((historyItemId, index) => {
      fetch(store.baseURL_AI + '/voiceGet', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ sampleId: historyItemId }),
      })
        .then((response) => response.arrayBuffer())
        .then((arrayBuffer) => audioContext.decodeAudioData(arrayBuffer))
        .then((audioBuffer) => {
          audioBuffers[index] = audioBuffer;
          loadedCount++;

          // Check if all audio clips have been loaded
          if (loadedCount === audioIds.length) {
            // Combine the audio clips and generate a downloadable file
            const combinedBuffer = concatenateBuffers(audioContext, audioBuffers, audioContext.sampleRate);

            // Create a temporary <a> element to trigger the file download
            const downloadLink = document.createElement('a');
            downloadLink.href = URL.createObjectURL(bufferToBlob(combinedBuffer, audioContext.sampleRate));
            downloadLink.download = 'combined_audio.wav';
            downloadLink.click();
          }
        })
        .catch((error) => {
          console.error('Request error:', error);
        });
    });
  }

  // Helper function to concatenate audio buffers
  function concatenateBuffers(audioContext, audioBuffers, sampleRate) {
    const totalDuration = audioBuffers.reduce((total, buffer) => total + buffer.duration, 0);
    const combinedBuffer = audioContext.createBuffer(1, totalDuration * sampleRate, sampleRate);

    let offset = 0;
    audioBuffers.forEach((buffer) => {
      combinedBuffer.getChannelData(0).set(buffer.getChannelData(0), offset * sampleRate);
      offset += buffer.duration;
    });

    return combinedBuffer;
  }

  function bufferToBlob(buffer, sampleRate) {
    const interleavedData = buffer.getChannelData(0);
    const wavData = createWavData(interleavedData, sampleRate);
    return new Blob([wavData], { type: 'audio/wav' });
  }

  // Helper function to create WAV audio data from interleaved channel data
  function createWavData(interleavedData, sampleRate) {
    const dataSize = interleavedData.length * 2;
    const buffer = new ArrayBuffer(44 + dataSize);
    const view = new DataView(buffer);

    // WAV file header
    writeString(view, 0, 'RIFF');                        // ChunkID: "RIFF"
    view.setUint32(4, 36 + dataSize, true);              // ChunkSize: 36 + dataSize
    writeString(view, 8, 'WAVE');                        // Format: "WAVE"
    writeString(view, 12, 'fmt ');                       // Subchunk1ID: "fmt "
    view.setUint32(16, 16, true);                         // Subchunk1Size: 16
    view.setUint16(20, 1, true);                          // AudioFormat: 1 (PCM)
    view.setUint16(22, 1, true);                          // NumChannels: 1 (mono)
    view.setUint32(24, sampleRate, true);                 // SampleRate
    view.setUint32(28, sampleRate * 2, true);             // ByteRate: SampleRate * NumChannels * BytesPerSample
    view.setUint16(32, 2, true);                          // BlockAlign: NumChannels * BytesPerSample
    view.setUint16(34, 16, true);                         // BitsPerSample
    writeString(view, 36, 'data');                        // Subchunk2ID: "data"
    view.setUint32(40, dataSize, true);                   // Subchunk2Size: dataSize

    // Audio data
    const dataView = new DataView(buffer, 44);
    const len = Math.min(interleavedData.length, dataSize / 2);
    let volume = 1;
    for (let i = 0; i < len; i++) {
      const sample = interleavedData[i] * volume;
      // Clamp the sample value between -1 and 1
      const clampedSample = Math.max(-1, Math.min(1, sample));
      const index = i * 2;
      dataView.setInt16(index, clampedSample < 0 ? clampedSample * 0x8000 : clampedSample * 0x7FFF, true);
    }

    return buffer;
  }

  // Helper function to write a string to a DataView
  function writeString(view, offset, value) {
    for (let i = 0; i < value.length; i++) {
      view.setUint8(offset + i, value.charCodeAt(i));
    }
  }

  const list = (anchor) => (
    <Box
      role="presentation"
      onKeyDown={() => toggleDrawer(anchor, false)}

    >
      <div 
      style={{ fontFamily: 'Coal' }}
      className="px-auto text-center text-[21px] text-indigo-600   mt-2 mb-3">Table Reads</div>
      <div id="audio-container"></div>
      {tableReadData.length > 0 ?
      
        <List>
          {tableReadData.map((data, index) => (
            <React.Fragment key={data.generationId}>
              <ListItem className="text-black mr-2">


                  <ListItem style={{ backgroundColor: 'transparent', paddingLeft: '0px', paddingRight: '6px', marginBottom: "0px", paddingBottom: "0px" }}>
                    <ListItemText secondary={<span><span className="text-gray-600">{data.created}</span> {' '}<span className="text-gray-900">{data.textPreview} {' ...'}</span></span>} />

                    <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                      <Tooltip title="Play">
                        <PlayCircleIcon className="text-sky-700 ml-2 hover:scale-125" 
                        style={{width:"21px"}} 
                        onClick={() => playTableReadAudio(data.generationId)} />
                      </Tooltip>
                      <Tooltip title="Download as .wav">
                        <FileDownloadIcon
                          className="text-indigo-700 ml-4 hover:scale-125"
                          style={{width:"22px"}}
                          onClick={() => downloadTableReadAudio(data.generationId)} />
                      </Tooltip>
                    </div>
                  </ListItem>
                </ListItem>
                <Divider style={{ marginBottom: "0px" }} />

            </React.Fragment>

          ))}


        </List>
        :

        checkedForReads && tableReadData.length == 0 ?

          <List>


            <ListItem key={0} index={0} className="text-slate-700  mr-1">

              <ListItemText primary={"Looks like you haven't generated any Table Reads yet."} />

            </ListItem>

            <ListItem key={1} index={1} className="text-slate-700  mr-1">


              <ListItemText
                primary={
                  <span>
                    Select some text to start bringing your <span
                      style={{
                        background: 'linear-gradient(to left, #da1bf2, #8114fc)',
                        WebkitBackgroundClip: 'text',
                        WebkitTextFillColor: 'transparent',
                      }}
                    >
                      characters
                    </span> to life.

                  </span>
                }
              />
            </ListItem>

            <ListItem key={2} index={2} >
              <div className="border border-fuchsia-500 shadow-inner shadow-fuchsia-200 rounded-full ml-[133px]">
                <div className="p-6">
                  <img className="brightness-125" src={acting} style={{ height: 90, width: 90 }}></img>
                </div>
              </div>
            </ListItem>
            



          </List>
          :
          null

      }



    </Box>
  );



  return (
    <div >
      {["left"].map((anchor) => (
        <React.Fragment key={anchor}>
          <div className="container flex px-0 md:px-0 flex select-none"></div>
          <div
            onClick={toggleDrawer(anchor, true)}
            activeClassName="bg-gray-100 hover:bg-gray-200 text-gray-800 transition"
            className="drawer-button border active:shadow-inner active:shadow-indigo-300 active:border-none bg-white border rounded-br-3xl xl:w-32  drop-shadow shadow-sm hover:shadow-md hover:border-t-white hover:shadow-indigo-500 hover:border hover:border-rose-400 shadow-indigo-600 text-md flex flex-col py-1.5 lg:py-1 px-2  lg:px-3 xl:px-4 cursor-pointer font-medium transition items-center"
          >
            < img className="brightness-125 mt-0.5" src={tableRead} style={{ height: 46, width: 40 }}></img>
            <div style={{fontFamily: "coal"}} className="text-center text-slate-600 mt-1 text-[13px] xl:text-[13.68px] 2xl:text-[13.9px]"> Saved Reads</div>
            <div />
            <div className="hidden lg:block"></div>
          </div>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >

            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
)

export default DrawerMenuTableRead;