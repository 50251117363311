import React, { Component, useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { computed,  } from 'mobx'
import MainBody from './Components/Body'
import {Helmet} from "react-helmet";
import './css/style-sheet.css'
import { observer, inject } from 'mobx-react'

import {Grid, Card, Col, Text, Link as Link2 } from "@nextui-org/react";
import {CardDocument} from './Components/CardDocument'
import styled from 'styled-components';



import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

import DeleteIcon from '@mui/icons-material/Delete';


import Carousel from "react-material-ui-carousel"
import { Paper, Typography } from "@mui/material"

import DoubleArrowOutlinedIcon from '@mui/icons-material/DoubleArrowOutlined';

import "./css/carousel.scss"



const SelectCarousel = ({items}) => {
 

  return (
    <div style={{ marginTop: "20px", color: "#494949" }}>
      
      <Carousel className="Carousel" autoPlay={true}
      navButtonsProps={{          // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
        style: {
            backgroundColor: '#DCDCDC',
            color: 'rgb(12, 17, 126)',
            padding: '6px',
            marginRight: '15px',
            marginLeft: '16px',
            textColor: 'blue',
        }    
    }}

    indicatorIconButtonProps={{
      style: {
          padding: '5px', 
      }
  }}


  activeIndicatorIconButtonProps={{
    style: {
        color: '#120a59'
    }
}}
    
    
    >
        {items.map((item, index) => {
          return <Project item={item} key={index} />
        })}
      </Carousel>
      <br />
      
    </div>
  )
}

function Project({ item }) {

  const [variant, setVariant] = useState('h5');

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 860) {
        setVariant('subtitle1');
        item.description = item.description.replace("working on", "")
        item.description = item.description.replace("Seamless script", "Seamless")
        item.description = item.description.replace("with one click", "")
        

      } else if (window.innerWidth < 1024) {
        setVariant('h6');
        item.description = item.description.replace("working on", "")
      } else {
        setVariant('h5');
      }
    }

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Paper
      className="PaperItem hover:scale-105 drop-shadow brightness-125 hover:brightness-150  "
      style={{
        backgroundImage: `url(${item.image})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        borderRadius: '30px'
      }}
    >
      <Typography variant={variant}>{item.name} </Typography>
      <br />
      <Typography>{item.description}</Typography>

      <Button
        className="CheckButton"
        component="a"
        
        target="_blank"
        rel="noreferrer"
        onClick={item.function}
      >
        <DoubleArrowOutlinedIcon />
      </Button>
    </Paper>
  )
}



const StyledHeader = styled(Card.Header)`
  position: absolute;
  z-index: 10;
  top: 2px;
  left: 0px;

  h1 {
    color: #ffffff;
    font-size: 1.5rem;
    font-weight: 250;
  }


  @media (max-width: 1400px) {
    h1 {
      font-size: 1.4rem;
      font-weight: 250;
    }
  }

  @media (max-width: 1250px) {
    h1 {
      font-size: 1.3rem;
      font-weight: 250;
    }
  }


  @media (max-width: 1110px) {
    h1 {
      font-size: 1.2rem;
      font-weight: 250;
    }
  }

  @media (max-width:1000px) {
    h1 {
      font-size: 1.1rem;
    }
  }


@media (max-width:850px) {
  h1 {
    font-size: 1rem;
  }
}
`;



const imageURLs = [

  
  
  
  {textColor: "light", url: "https://images.unsplash.com/photo-1501862700950-18382cd41497?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1119&q=80"},

  {textColor: "light", url: "https://cdn.pixabay.com/photo/2020/03/30/13/29/space-4984262_1280.jpg"},

  {textColor: "light", url: "https://images.unsplash.com/photo-1450849608880-6f787542c88a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2921&q=80"},

  {textColor: "light", url: "https://cdn.pixabay.com/photo/2016/11/23/15/23/cosmos-1853491_1280.jpg"},

  {textColor: "light", url: "https://images.unsplash.com/photo-1483401757487-2ced3fa77952?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2073&q=80"},
  
  {textColor: "light", url: "https://images.unsplash.com/photo-1631588631186-8946ffb3922b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80"},

  {textColor: "light", url: " https://cdn.pixabay.com/photo/2016/11/29/07/21/astronomy-1868065_1280.jpg"},

  {textColor: "light", url: "https://cdn.pixabay.com/photo/2016/11/29/08/58/astronomy-1868560_1280.jpg"},
  
  {textColor: "light", url: "https://images.unsplash.com/photo-1435224654926-ecc9f7fa028c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80"},
  
  {textColor: "light", url: "https://images.unsplash.com/photo-1528484701073-2b22dc76649e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1631&q=80"},  
  
  {textColor: "light", url: "https://burst.shopifycdn.com/photos/starry-starry-night.jpg?width=1850&format=pjpg&exif=1&iptc=1"},

  
  {textColor: "light", url: "https://cdn.pixabay.com/photo/2016/11/29/13/01/stars-1869692_1280.jpg"},
  
  {textColor: "light", url: "https://images.unsplash.com/photo-1570284613060-766c33850e00?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"},
  
  
 
  
  {textColor: "light", url: "https://images.unsplash.com/photo-1505506874110-6a7a69069a08?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"},
  
  {textColor: "light", url: "https://cdn.pixabay.com/photo/2016/11/21/12/39/stars-1845140_1280.jpg"},
  
  {textColor: "light", url: "https://cdn.pixabay.com/photo/2017/03/27/14/31/stars-2179083_1280.jpg"},

  {textColor: "light", url: "https://images.unsplash.com/photo-1531956468651-8fc931f28a9b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1554&q=80"},
  
  {textColor: "light", url: "https://images.unsplash.com/photo-1565103382118-c65707fc2a9c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8MTIxfHx8ZW58MHx8fHw%3D&auto=format&fit=crop&w=800&q=60"},
]






@inject('store')
@observer
class Body extends Component {

  constructor(props) {
    super(props);
		this.init();
    this.state = {
      alignment: 'open',
      openDialog: false,
      openAdaptDialog: false,
      isScreenWidthLessThan1280: window.innerWidth < 1280, 
    };
    this.handleResize = this.handleResize.bind(this);
  }



	@computed get permissions() {
		return this.props.store.tools.filter(tool=>
			tool.permissions.some(r=> this.props.store.profile.permissions.includes(r))
		)
	}

	@computed get generateScreenplay() {
		return this.permissions.filter(tool => tool.category === 'generateScreenplay')
	}

  @computed get generateOutline() {
		return this.permissions.filter(tool => tool.category === 'generateOutline')
	}

  @computed get generateFeedback() {
		return this.permissions.filter(tool => tool.category === 'generateFeedback')
	}

  init = async () => {
		this.refreshDocuments()
	}


  refreshDocuments = async () => {
		let response = await this.props.store.api.post('/user/document/viewOpened')
    if (response.data.length > 0) {

      localStorage.setItem('totalDocs', 1)
      
      const documents = [...response.data]
      const documentValues = documents
        .filter((document) => !document.deleted) // filter out deleted documents
        .map((document) => ({  
          documentId: document.documentId, 
          dashboardImageIndex: document.dashboardImageIndex ,
          lastOpened: document.lastOpened,
          title: document.title,
    })) 

    this.props.store.savedDocs = documentValues;

    } else {
      localStorage.setItem('totalDocs', null)

    }
  }

	
  
  initRandomDocID() {
    return new Promise(resolve => {
      let result = '';
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      const charactersLength = characters.length;
      for ( var i = 0; i < 16; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }

      resolve(result);
    });
  }


  createNewDoc = async ({docId}) =>  {

    let imageIndex = 0;
    let response =  await this.props.store.api.post('/user/document/viewCreated')
    if (response.data.length > 0) {
        const lastDocument = [...response.data]
        imageIndex = lastDocument[0].dashboardImageIndex
        if (imageIndex >= (imageURLs.length -1)) {
          imageIndex = 0;
        }
        else {
          imageIndex += 1;
        }
      
    }
    await this.props.store.api.post('/user/document', { documentId: docId, dashboardImageIndex: imageIndex});
}


setAdaptationImageIndex = async () =>  {

  let imageIndex = 0;
  let response =  await this.props.store.api.post('/user/adaptation/viewCreated')
  if (response.data.length > 0) {
      const lastDocument = [...response.data]
      imageIndex = lastDocument[0].dashboardImageIndex
      if (imageIndex >= (imageURLs.length -1)) {
        imageIndex = 0;
      }
      else {
        imageIndex += 1;
      }
    
  }
  localStorage.setItem('adaptationImageIndex', imageIndex.toString())
  
}




  
  handleOnPressNewDoc = async () => {

    localStorage.setItem('script', "null")

    const documentId = await this.initRandomDocID();

    await this.createNewDoc({docId: documentId});

    //this.props.store.documentId = documentId;
    //this.props.store.selectedDocType = "Script";

    window.location.href = `/ai/editor/${documentId}`;
  }


  handleOnPressNewAdaptation = async () => {

    localStorage.setItem('adaptation', "null")

    const adaptationId = await this.initRandomDocID();

    await this.setAdaptationImageIndex();

    window.location.href = `/ai/adapt/${adaptationId}`;
  }



  handleUploadScript = async () => {

    localStorage.setItem('uploadScript', "true")

    this.handleOnPressNewDoc();

  }




  setInitialScriptState= async ({docId}) =>  {


    let result;
    let response =  await this.props.store.api.post('/user/script/view',  {documentId: docId})
    if (response.data.length > 0) {
        const scriptState = [...response.data]
        result = scriptState[0].script
        return result
    }
      return null
}


  handleOnPressSavedDoc = async ({docId}) => {

    const script = await this.setInitialScriptState({docId:docId});
    localStorage.setItem("script", script)
    await this.props.store.api.post('/user/document/updateLastOpened', {documentId: docId, date: new Date() });
    
    window.location.href = `/ai/editor/${docId}`;
  }


  handleOnPressDeleteDoc = async ({docId}) => {

    await this.props.store.api.post('/user/document/delete', {documentId: docId });
    this.refreshDocuments();
    
  }
  


  splitArray({arr, split}) {
    var result = [];
    for (var i = 0; i < arr.length; i += split) {
      result.push(arr.slice(i, i + split));
    }
    return result;
  }


  formatDateDiff(savedDate) {
    const currentDate = new Date();
    const diff = currentDate.getTime() - savedDate.getTime();
  
    const minute = 60 * 1000;
    const hour = 60 * minute;
    const day = 24 * hour;
    const week = 7 * day;
    const month = 30 * day;
    const year = 365 * day;
  
    if (diff < minute) {
      return "Just now";
    } else if (diff < hour) {
      const minutes = Math.round(diff / minute);
      return `${minutes} ${minutes > 1 ? " minutes" : " minute"} ago`;

    } else if (diff < day) {
      const hours = Math.round(diff / hour);
      return `${hours} ${hours > 1 ? " hours" : " hour"} ago`;

    } else if (diff < week) {
      const days = Math.round(diff / day);
      return `${days} ${days > 1 ? " days" : " day"} ago`;

    } else if (diff < month) {
      const weeks = Math.round(diff / week);
      return `${weeks} ${weeks > 1 ? " weeks" : " week"} ago`;
    } else if (diff < year) {
      const months = Math.round(diff / month);
      return `${months} ${months > 1 ? " months" : " month"} ago`;
    } else {
      return savedDate.toLocaleDateString();
    }
  }

  handleChange = (event, newAlignment) => {
    this.setState({ alignment: newAlignment });
  };

  handleClickOpen = async ({docId}) => {

    this.docToDelete = docId;
    this.setState({ openDialog: true });
  };

  handleClose = (event) => {
    this.setState({ openDialog: false });
  };


  handleAdaptClickOpen = async () => {
    this.setState({ openAdaptDialog: true });
  };

  handleAdaptClose = (event) => {
    this.setState({ openAdaptDialog: false });
  };


  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize() {
    this.setState({
      isScreenWidthLessThan1280: window.innerWidth < 1280,
    });
  }

 items = [
    {
      name: "New Editor",
      description: "Start working on a new script",
      color: "#64ACC8",
      function: this.handleOnPressNewDoc,
      image: "https://images.unsplash.com/photo-1684162205807-630e5d5904be?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=802&q=60"
    },

    {
      name: "Novel Adapter",
      description:
        "Adapt a book with one click",
      color: "#7D85B1",
      function: ["matt@storywave.co","test@testerteststesting.com", "dylan@storywave.co", "rob.gabel@spotter.la", "paul.bakaus@spotter.la", "nic@spotter.la", "sarae.vandyke@spotter.la", "lailaaamal@gmail.com"].includes(this.props.store.profile.email) ? this.handleOnPressNewAdaptation : this.handleAdaptClickOpen,
      image: "https://images.unsplash.com/photo-1684170114111-32abdbe199cb?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=802&q=80"
    },
    {
      name: "Final Draft import",
      description: "Seamless script importing",
      color: "#CE7E78",
      function: this.handleUploadScript,
      image: " https://images.unsplash.com/photo-1684005733545-e604a648d4d0?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=802&q=80"
      
    },
    
  ]

	render() {

    const { alignment, openDialog } = this.state;

    const documents = this.props.store.savedDocs;
    const MAX_CARDS_PER_ROW = 2;
    const documentValuesSplit = this.splitArray({arr: documents, split: MAX_CARDS_PER_ROW});

    const { isScreenWidthLessThan1280 } = this.state;

	return (

		<>
			<Helmet>
				<title>{`Next Scene - Dashboard`}</title>
			</Helmet>
			<MainBody className=" mb-10 py-4 md:px-2 lg:px-20 xl:px-28 md:py-8 lg:py-5 mt-0 ">

<div className="mt-16" />
      
<div className="mb-20 flex">
  {this.items.map((item, index) => {
    return <Project item={item} key={index} />
  })}
</div>


{this.state.openAdaptDialog ?
                    <Dialog
        open={this.state.openAdaptDialog}
        onClose={this.handleAdaptClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        
        modal={false}
      >
        <DialogTitle id="alert-dialog-title">
          {"Currently in limited release. Contact us for access."}
        </DialogTitle>
      </Dialog>  
      
      : null}


     {/*  <Grid.Container gap={2} justify="center">
      <Grid xs={12} sm={12}>
      
      <Card  
      isPressable
      isHoverable = {documents.length > 0 ? true : false}
      disableRipple={true}
      variant="bordered"
      css={{ mw: "30vw", boxShadow: "0px 5px 10px rgba(51, 56, 138, 0.888)" }}
      onPress={this.handleOnPressNewDoc }

      className = {documents.length > 0 ? "hover:brightness-125" : "hover:scale-105 hover:brightness-125 transform transition duration-500 ease-in-out" } 
      >
    
    
    <StyledHeader>
      <Col>
        <Text h1 color="#ffffff">
          Open
        </Text>
      </Col>
      </StyledHeader>
    
    <Card.Image
      src="https://images.unsplash.com/photo-1591711584791-455de896b1e9?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Nnx8dHVubmVsfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60"
      objectFit="cover"
      width="100%"
      height={145}
      alt="Card image background"
      autoResize={true}
    />
    
  </Card>

  
      </Grid>
     
      
      </Grid.Container>


 */}


      {documents.length > 0 ? <Title title="Saved Projects" /> : null} 


      {documents.length > 0 ?
      <ToggleButtonGroup
        className = "mt-2 mb-4"
        color="primary"
        value={alignment == null ? "open" : alignment}
        exclusive
        onChange={this.handleChange}
        aria-label="Platform"
      >
        <ToggleButton value="open">Open</ToggleButton>
        <ToggleButton color="error" value="delete">Delete</ToggleButton>
      </ToggleButtonGroup>
    : null} 



      {documentValuesSplit.map(documentValues => (

<Grid.Container  gap={2} justify="left">

      
      <Grid xs={11} sm={12} md={12} lg={12} gap={3}>

      {documentValues.map(document => (


      <Card 
    className = "brightness-100 hover:brightness-125"
    variant="flat"
    isHoverable={false}
    isPressable
    disableRipple={true}
    onPress={() => this.state.alignment != "delete" ? this.handleOnPressSavedDoc({docId: document.documentId}): this.handleClickOpen({docId: document.documentId})}
    css={{ marginRight:"2vw", 
          maxWidth: "25vw",
          minWidth: "49%",
          border: "rgba(21, 41, 221, 0.888) 1px solid", 
          boxShadow: this.state.alignment !== "delete"  ? "0px 4px 6px rgba(100, 101, 110, 0.888);" : "4px 5px 1px red"  
          }}>
      
      <Card.Header css={{ position: "absolute", zIndex: 1, top: 5 }}>
        <Col>
        <Text size={isScreenWidthLessThan1280 ? 10 : 11.5} transform="uppercase" css={{fontWeight:500}} color = {imageURLs[document.dashboardImageIndex].textColor === "dark" ?  "#61504e" : "#f5eeed"}>
            Last Opened: {this.formatDateDiff(new Date(document.lastOpened))}
          </Text>
          <Text size={isScreenWidthLessThan1280 ? 16.5 : 20} transform="capitalize" css={{fontWeight:350}} color= {imageURLs[document.dashboardImageIndex].textColor === "dark" ?  "#473a39" : "#fafcf2"}>
            {document.title}
     </Text>

        </Col>
      </Card.Header>
  
      <Card.Image
        src = {imageURLs[document.dashboardImageIndex].url}
        width="100%"
        height={140}
        objectFit="cover"
        alt="Card image background"
      />
    
     
    </Card>

   

      ))}

{this.state.alignment === "delete" ?
<Dialog
        open={openDialog}
        onClose={this.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        
        modal={false}
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to delete this document?"}
        </DialogTitle>
        <DialogActions>
          <Button variant="outlined" color = "error" startIcon={<DeleteIcon />} onClick={(event) => { this.handleOnPressDeleteDoc({docId: this.docToDelete}); this.handleClose() }}>Yes</Button>
          <Button variant="outlined" onClick={this.handleClose} autoFocus>
            Nevermind
          </Button>
        </DialogActions>
      </Dialog>
    
 : null }

      </Grid>
      </Grid.Container>
     

))}

</MainBody>
</>)
}
  }

  export const Divider = () => (
	<div className="relative">
	  <div className="h-1 bg-slate-400 w-full my-8 md:my-12">
	  </div>
	</div>
  );


  

export const Title = ({ title }) => (
    <h2 
      className="brightness-150 mt-4 font-light text-2xl sm:text-2xl md:text-2xl relative text-slate-700 md:mb-3"
      
    >
      {title}
    </h2>
);


export const Grid1 = ({ children }) => <div className="grid  gap-10 mt-5  md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2">{children}</div>


export const Tool1 = ({ Icon, title, desc, to, group, fromColor, toColor }) => <Link to={to || "/"} className="flex relative" col-start-1>
	
	<div className={` mb-8 shadow-lg bg-white flex-1 rounded-2xl transition hover:shadow-md overflow-hidden md:max-w-xl h-34 text-gray-700 cursor-pointer border border-sky-500 md:flex relative transform hover:scale-105  hover:text-black`}>
    <div className=" p-4">
    <div className={`mb-2 uppercase tracking-wide text-sm text-${fromColor ? fromColor : "blue-500"} font-semibold leading-none`}>{group || "New"}</div>
    <div href="#" className="font-light block text-lg xl:text-xl 2xl:text-2xl leading-tight text-black leading-none">{title}</div>
    <p className="font-light mt-1 pr-1 text-sm ">{desc} </p>
  </div>
</div>
</Link>

export const Tool2 = ({ Icon, title, desc, to, group, fromColor, toColor }) => <Link to={to || "/"} className="flex relative">
	
	<div className={`mb-8 shadow-lg bg-white flex-1 rounded-2xl transition hover:shadow-md overflow-hidden md:max-w-xl h-34 text-gray-700 cursor-pointer border border-blue-600 md:flex relative transform hover:scale-105  hover:text-black`}>
  <div className="p-4">
	<div className={`mb-2 uppercase tracking-wide text-sm text-${fromColor ? fromColor : "green-500"} font-semibold leading-none`}>{group || "New"}</div>
	<div href="#" className="font-light block text-lg xl:text-xl 2xl:text-2xl leading-tight text-black leading-none">{title}</div>
	<p className="font-light mt-1 pr-1 text-sm ">{desc} </p>
  </div>
</div>
</Link>

export const Tool3 = ({ Icon, title, desc, to, group, fromColor, toColor }) => <Link to={to || "/"} className="flex relative ">
	
	<div className={` bg-white shadow-lg flex-1 rounded-2xl transition hover:shadow-md overflow-hidden md:max-w-xl h-34 text-gray-700 cursor-pointer border border-indigo-600 md:flex relative transform hover:scale-105 hover:text-black`}>
  <div className="p-4">
	<div className={`mb-2 uppercase tracking-wide text-sm text-${fromColor ? fromColor : "green-500"} font-semibold leading-none`}>{group || "New"}</div>
	<div href="#" className="font-light block text-lg xl:text-xl 2xl:text-2xl leading-tight text-black leading-none">{title}</div>
	<p className="font-light mt-1 pr-1 text-sm ">{desc} </p>
  </div>
</div>
</Link>



export default Body