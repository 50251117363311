import React, { useRef } from "react";

function Button4({ title, onClick, Icon, disabled, children }) {
  const buttonRef = useRef(null);


  return (
    <button
      ref={buttonRef}
      className={disabled ? 
        `select-none py-0.25 px-4 border-2 border-gray-300 bg-gray-300 hover:bg-gray-400 disabled hover:to-gray-700 text-white  rounded-full flex md:inline-flex font-medium text-l cursor-pointer mt-1 md:mx-0 transition` :  
        `select-none py-0.25 px-6  border-2 border-blue-100 bg-blue-500 shadow-sm shadow-slate-300 hover:shadow-sm hover:shadow-slate-400 hover:drop-shadow-md hover:bg-[#23affa] active:shadow-inner text-white   rounded-full flex md:inline-flex font-medium text-l cursor-pointer mt-1 md:mx-0 transition` } 
      onClick={disabled ? null : onClick}>
      {disabled ? "working on it..." : <Icon style={{width:"2.3rem", height:"1.6rem"}}/> }
      {title}
    </button>
  );
}

export default Button4;