

import React, { useEffect } from "react";
import { observer, inject } from 'mobx-react'

import { $getSelection, $createTextNode, INSERT_PARAGRAPH_COMMAND, KEY_ARROW_RIGHT_COMMAND, $setSelection, } from 'lexical';
import { $createHeadingNode, } from '@lexical/rich-text';

import {
  $wrapNodes,
} from "@lexical/selection";


import SceneTheme from "../Lexical/themes/SceneTheme";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import ToolbarPlugin from "../Lexical/plugins/SceneToolbarPlugin";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { CodeNode } from "@lexical/code";
import { LinkNode } from "@lexical/link";
import { ListItemNode, ListNode } from "@lexical/list";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import { TRANSFORMERS } from "@lexical/markdown";

import DraggableBlockPlugin from '../Lexical/plugins/DraggableBlockPlugin';

import FloatingTextFormatToolbarPlugin from '../Lexical/plugins/FloatingTextFormatToolbarScenePlugin';

import ActionsPlugin from "../Lexical/plugins/ActionsPlugin";
import SpeechToTextPlugin from "../Lexical/plugins/SpeechToTextPlugin";

import { $generateHtmlFromNodes, $generateNodesFromDOM } from '@lexical/html';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { $getRoot } from 'lexical';

import SetEditorPlugin from "../Lexical/plugins/SetEditorPlugin";

import MentionsPlugin from "../Lexical/plugins/MentionsPlugin";
import { MentionNode } from '../Lexical/nodes/MentionNode.js';



import { TabIndentationPlugin } from "../Lexical/plugins/TabIndentationScriptPlugin";
import { HandleEnterPlugin } from "../Lexical/plugins/HandleEnterPlugin";
import { KeyboardShortcutsPlugin } from "../Lexical/plugins/KeyboardShortcutsPlugin";
import TableOfContentsPlugin from "../Lexical/plugins/TableOfContentsPlugin";



function Placeholder() {
  return <div className="editor-placeholder">Untitled Script</div>;
}



const classNameToLineType = {
  "editor-paragraph-script": "action",
  "editor-text-heading": "scene heading",
  "editor-text-action": "action",
  "editor-text-character": "character",
  "editor-text-parens": "parens",
  "editor-text-dialogue": "dialogue",
  "editor-text-transition": "transition"
}


const lineTypeToHeading = {
  "Scene Heading": "h1",
  "Action": "h2",
  "Character Name": "h3",
  "Character": "h3",
  "Parenthetical": "h4",
  "Dialogue": "h5",
  "Transition": "h6",
}


const ComponentStyles = () => {
  useEffect(() => {
    const editorInput = document.querySelector('.editor-input');

    const handleScroll = () => {
      editorInput.classList.add('scrolling');
      clearTimeout(editorInput.scrollEndTimeout);
      editorInput.scrollEndTimeout = setTimeout(() => {
        editorInput.classList.remove('scrolling');
      }, 200); // Adjust the timeout value as desired (in milliseconds)
    };

    editorInput.addEventListener('scroll', handleScroll);

    return () => {
      clearTimeout(editorInput.scrollEndTimeout);
      editorInput.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <style jsx global>{`
      .editor-input::-webkit-scrollbar {
        width: 7px;
        max-height: 3px;
      }

      .editor-input::-webkit-scrollbar:hover {
        width: 10px;
        max-height: 3px;
      }

      .editor-input::-webkit-scrollbar-thumb {
        background-color: #b8b8b8;
        border-radius: 35px;
        max-height: 3px;
        height: 3px;
        display: none; 
      }

      .editor-input::-webkit-scrollbar-thumb:hover {
        display: block;
        width: 15px;
      }

      .editor-input.scrolling::-webkit-scrollbar-thumb {
        display: block;
      }

     
    `}</style>
  );
};



@inject('store')
@observer
class LexicalScene extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.title = null
  }



  editorConfig = {

    // The initial state of the editor
    editorState: this.props.store.initialScriptState,


    // The editor theme
    theme: SceneTheme,
    // Handling of errors during update
    onError(error) {
      throw error;
    },
    // Any custom nodes go here
    nodes: [
      HeadingNode,
      ListNode,
      ListItemNode,
      QuoteNode,
      CodeNode,
      TableNode,
      TableCellNode,
      TableRowNode,
      LinkNode,
      MentionNode
    ]
  };



  componentDidMount() {
    this.interval = setInterval(this.saveScriptToDatabase, 2500);

    try {

      if (this.props.store.initialScriptState) {
        const lines = JSON.parse(this.props.store.initialScriptState).root.children
        for (let lineData of lines) {
          if ("tag" in lineData && lineData.tag === "h3") {
            let character = lineData.children[0].text
            //console.log(character)

            if (character) {
              this.props.store.scriptCharacters.add(character.toUpperCase())
            }

          }
        }

      }
    } catch (err) {
      console.log(err)

    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  saveScriptToDatabase = async () => {
    if (this.props.store.scriptText !== null) {


      const script = JSON.stringify(this.props.store.scriptJSON);

      if (script === this.props.store.lastScriptDatabaseUpdate) {
        return;
      }

      console.log("saving script to database and local store")


      try {
        await this.props.store.api.post('/user/script/update', { script: script, documentId: window.location.href.split('/').pop() });
        this.props.store.lastScriptDatabaseUpdate = script
        localStorage.setItem('script', script)
      } catch (err) {
        console.log(err);
        console.log("failed to save script to database");
      }



      try {
        let title = this.props.store.scriptText.split('\n')[0]
        // limit title to 12 words
        title = title.split(" ").slice(0, 12).join(" ")
        if (title !== this.title) {
          title = title.trim()
          if (!title) {
            title = "Untitled"
          }
          this.title = title
          await this.props.store.api.post('/user/document/updateTitle', { title: title, documentId: window.location.href.split('/').pop() });
          console.log("updated title")

        }
      } catch (err) {
        console.log(err);
        console.log("failed to save script to database");
      }




    }

  };




  onChange(editorState, editor) {

    /* const popupMenu = document.querySelector('.floating-text-format-popup');
    if (popupMenu) {
      //console.log("popup")
      let popupRect = popupMenu.getBoundingClientRect();
      //console.log(popupRect.left)
      this.props.store.popupMenuLeftPos= popupRect.left
  
    }

    const selection = window.getSelection();
    const range = selection.getRangeAt(0);
    const cursorRect = range.getBoundingClientRect();
    this.props.store.cursorRect = cursorRect

    const editorElem = document.querySelector('.editor-container');
    const editorRect = editorElem.getBoundingClientRect();
    this.props.store.editorRect = editorRect */

    editor.update(() => {

      const rawHTML = $generateHtmlFromNodes(editor, null)
      const editorStateTextString = editorState.read(() => $getRoot().getTextContent());

      // store json
      this.props.store.scriptJSON = editorState
      this.props.store.initialScriptState = editorState

      // store html
      this.props.store.scriptHTML = rawHTML

      // store text
      this.props.store.scriptText = editorStateTextString

      if (this.props.store.clearSelection) {
        this.props.store.clearSelection = false
        $setSelection(null);
      }


      let selection = $getSelection();

      if (selection) {

        let words = selection.getTextContent().split(/\s+/).length
        if (words) {
          this.props.store.wordCount = words
        } else {
          this.props.store.wordCount = 0
        }

        if (selection.getTextContent().length > 0) {
          this.props.store.allowImageSelection = true;
          selection._cachedNodes.forEach(node => {

            if (node.__tag && node.__tag !== "h2") {
              //console.log(node.__tag)
              this.props.store.allowImageSelection = false;
            }

          })
        }

        let focusNode = selection.focus.getNode();
        const element =
          focusNode.getKey() === "root"
            ? focusNode
            : focusNode.getTopLevelElementOrThrow();
        const elementKey = element.getKey();

        let elementDOM = editor.getElementByKey(elementKey);
        this.props.store.currentScriptLineType = classNameToLineType[elementDOM.classList.value.replace(' ltr', '')]

        const editorElem = document.querySelector('.editor-inner');

        /*  let lastLine = classNameToLineType[this.props.store.lastLineType];
         
         const store = this.props.store
 
         if (!his.props.store.scriptInsertText) {
 
         let switchLinePromise = new Promise((resolve, reject) => {
           document.addEventListener("keydown", function(event) {
             if (event.key === "Enter") {
               store.switchLineType = true;
               resolve();
             }
           });
         });
         
         switchLinePromise.then(() => {
           var event = new KeyboardEvent('keydown', {'keyCode': 39});
 document.dispatchEvent(event);
 
           editor.update(() => {
        if (this.props.store.switchLineType) {
         //console.log(lastLine)
         selection = $getSelection();
         console.log()
 
         if (lastLine === "character") {
          
           $wrapNodes(selection, () => $createHeadingNode("h4"));
         
           
         } else if (lastLine === "parens") {
           $wrapNodes(selection, () => $createHeadingNode("h5"));
         } else if (lastLine === "dialogue") {
           $wrapNodes(selection, () => $createHeadingNode("h2"));
         }
 
         this.props.store.switchLineType = undefined;
 
        }
       })
       });
 
     } */


        if (this.props.store.scriptInsertText) {

          try {

            if (!this.props.store.switchedForInsert) {

              const text = elementDOM.textContent
              if (text) {
                selection.anchor.offset = text.length
                selection.focus.offset = text.length
                editor.dispatchCommand(INSERT_PARAGRAPH_COMMAND)
                //editor.focus()
                selection = $getSelection();
                focusNode = selection.focus.getNode();
              }

              if (this.props.store.generationSourceCategory === "generateLine") {

                selection.toggleFormat('bold')
                selection.insertText(this.props.store.scriptInsertText)
                this.props.store.scriptInsertText = null;

              } else {

                let textNode;
                let headingNode;
                let lineText;

                for (let line of this.props.store.scriptInsertText) {

                  lineText = line[1];

                  if (line[0] === "Parenthetical") {
                    if (!lineText.startsWith("(")) {
                      lineText = "(" + lineText;
                    }

                    if (!lineText.endsWith(")")) {
                      lineText = lineText + ")";
                    }

                  }

                  textNode = $createTextNode(lineText);
                  if (this.props.store.apiSource === "/rewriteLine" || this.props.store.apiSource === "/rewrite") {
                    textNode.toggleFormat('bold')
                  }

                  headingNode = $createHeadingNode(lineTypeToHeading[line[0]]);

                  headingNode.append(textNode);

                  focusNode.insertBefore(headingNode);

                }

                this.props.store.scriptInsertText = null;

              }
            } else {

              elementDOM.addEventListener('click', () => {

                //console.log("click1")

                editor.update(() => {

                  selection = $getSelection();
                  if (selection && this.props.store.scriptInsertText) {

                    const text = elementDOM.textContent
                    if (text) {
                      focusNode.select()
                      selection.anchor.offset = text.length
                      selection.focus.offset = text.length
                      editor.dispatchCommand(INSERT_PARAGRAPH_COMMAND)
                      //editor.focus()
                      selection = $getSelection();
                      focusNode = selection.focus.getNode();

                    }

                    if (this.props.store.generationSourceCategory === "generateLine") {

                      selection.toggleFormat('bold')
                      selection.insertText(this.props.store.scriptInsertText)
                      this.props.store.scriptInsertText = null;
                      this.props.store.switchedForInsert = false;

                    } else {

                      let textNode;
                      let headingNode;
                      let lineText;

                      for (let line of this.props.store.scriptInsertText) {

                        lineText = line[1];

                        if (lineText !== "FADE OUT" && lineText !== "FADE OUT.")  {

                          if (line[0] === "Parenthetical") {
                            if (!lineText.startsWith("(")) {
                              lineText = "(" + lineText;
                            }

                            if (!lineText.endsWith(")")) {
                              lineText = lineText + ")";
                            }
                          }

                          textNode = $createTextNode(lineText);

                          if (this.props.store.apiSource === "/rewriteLine" || this.props.store.apiSource === "/rewrite") {
                            textNode.toggleFormat('bold')

                          }

                          headingNode = $createHeadingNode(lineTypeToHeading[line[0]]);

                          headingNode.append(textNode);

                          //console.log(focusNode)
                          //console.log(focusNode.getParent())
                          focusNode.insertBefore(headingNode);
                        }

                      }
                      this.props.store.scriptInsertText = null;
                      this.props.store.switchedForInsert = false;
                    }
                  }
                });
              })

              if (this.props.store.scriptInsertText) {

                if (editorElem) {

                  editorElem.addEventListener('click', () => {

                    //console.log("click2")

                    editor.update(() => {
                      selection = $getSelection();
                      if (selection && this.props.store.scriptInsertText) {

                        const text = elementDOM.textContent
                        if (text) {
                          focusNode.select()
                          selection.anchor.offset = text.length
                          selection.focus.offset = text.length
                          editor.dispatchCommand(INSERT_PARAGRAPH_COMMAND)
                          //editor.focus()
                          selection = $getSelection();
                          focusNode = selection.focus.getNode();
                        }

                        if (this.props.store.generationSourceCategory === "generateLine") {

                          selection.toggleFormat('bold')
                          selection.insertText(this.props.store.scriptInsertText)
                          this.props.store.scriptInsertText = null;
                          this.props.store.switchedForInsert = false;

                        } else {

                          let textNode;
                          let headingNode;
                          let lineText;

                          for (let line of this.props.store.scriptInsertText) {

                            lineText = line[1];

                            if (lineText !== "FADE OUT" && lineText !== "FADE OUT.")  {

                              if (line[0] === "Parenthetical") {
                                if (!lineText.startsWith("(")) {
                                  lineText = "(" + lineText;
                                }

                                if (!lineText.endsWith(")")) {
                                  lineText = lineText + ")";
                                }

                              }

                              textNode = $createTextNode(lineText);

                              if (this.props.store.apiSource === "/rewriteLine" || this.props.store.apiSource === "/rewrite") {
                                textNode.toggleFormat('bold')

                              }

                              headingNode = $createHeadingNode(lineTypeToHeading[line[0]]);

                              headingNode.append(textNode);

                              //console.log(focusNode)
                              //console.log(focusNode.getParent())
                              focusNode.insertBefore(headingNode);
                            }

                          }
                          this.props.store.scriptInsertText = null;
                          this.props.store.switchedForInsert = false;

                        }
                      }
                    });
                  })
                }
              }
            };
          } catch (err) {
            console.log(err)
          }
        }
      }
    })
  }


  render() {

    return (
      <LexicalComposer initialConfig={this.editorConfig}>
        <div className="editor-container">
          <ToolbarPlugin />
           
          <ComponentStyles/> 
          <div className="editor-inner" >

            <RichTextPlugin
              contentEditable={<ContentEditable className="editor-input" />}
              placeholder={<Placeholder />}
              ErrorBoundary={LexicalErrorBoundary}
            />
            <HistoryPlugin />
            <AutoFocusPlugin />
            <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
            {/* <DraggableBlockPlugin /> */}
            <FloatingTextFormatToolbarPlugin streaming={this.props.store.streaming} />
            { !this.props.store.voiceMode ? <ActionsPlugin /> : null }
            <SpeechToTextPlugin />
            <SetEditorPlugin setEditor={this.props.setEditor} />
            <MentionsPlugin />
            <OnChangePlugin onChange={this.onChange} />
            <TabIndentationPlugin />
            <HandleEnterPlugin />
            <KeyboardShortcutsPlugin />
            <div>{this.props.store.showTableOfContents && <TableOfContentsPlugin />}</div>
          </div>
        </div>
      </LexicalComposer>
    );
  }
}

export default LexicalScene;