import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import {
  $getSelection,
  $isRangeSelection,
  COMMAND_PRIORITY_EDITOR,
  INDENT_CONTENT_COMMAND,
  KEY_TAB_COMMAND,
  OUTDENT_CONTENT_COMMAND
} from "lexical"

import {
  $createHeadingNode,

} from "@lexical/rich-text";

import {
  $wrapNodes,

} from "@lexical/selection";
import { useEffect } from "react"

/**
 * This plugin adds the ability to indent content using the tab key. Generally, we don't
 * recommend using this plugin as it could negatively affect acessibility for keyboard
 * users, causing focus to become trapped within the editor.
 */



const tabMap = {
  "editor-paragraph-script": "h3",
  "editor-text-heading": "h2",
  "editor-text-action": "h3",
  "editor-text-character": "h4",
  "editor-text-parens": "h5",
  "editor-text-dialogue": "h6",
  "editor-text-transition": "h6"
}

const tabShiftMap = {
  "editor-paragraph-script": "h1",
  "editor-text-heading": "h1",
  "editor-text-action": "h1",
  "editor-text-character": "h2",
  "editor-text-parens": "h3",
  "editor-text-dialogue": "h4",
  "editor-text-transition": "h5"
}

export function TabIndentationPlugin() {
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    return editor.registerCommand(
      KEY_TAB_COMMAND,
      event => {
        editor.update(() => {
          const selection = $getSelection()

          if (!$isRangeSelection(selection)) {
            return false
          }

          const focusNode = selection.focus.getNode();
          const element =
            focusNode.getKey() === "root"
              ? focusNode
              : focusNode.getTopLevelElementOrThrow();
          const elementKey = element.getKey();

          const elementDOM = editor.getElementByKey(elementKey);
          const currClass = elementDOM.classList.value.replace(' ltr', '')

          let domText = elementDOM.textContent
          let text = selection.getTextContent()

          if (event.shiftKey) {

            if (currClass === "editor-text-dialogue") {


              if (text) {
                //selection.anchor.offset = selection.anchor.offset - (selection.anchor.offset + 1);
                selection.removeText()
                console.log(selection.getTextContent())
                selection.insertText("(" + text + ")")
                console.log(selection.getTextContent())

              } else {


                if (domText) {

                  selection.anchor.offset = 0
                  selection.focus.offset = domText.length

                  selection.deleteLine()
                  selection.insertText("(" + domText + ")")

                } else {
                  selection.insertText("()")
                }

              }
              $wrapNodes(selection, () => $createHeadingNode("h4"));

              selection.focus.offset = selection.focus.offset - 1;
              selection.anchor.offset = selection.anchor.offset - 1;



            }

          } else {

            if (currClass === "editor-text-character") {


              if (text) {
                //selection.anchor.offset = selection.anchor.offset - (selection.anchor.offset + 1);
                selection.removeText()
                console.log(selection.getTextContent())
                selection.insertText("(" + text + ")")
                console.log(selection.getTextContent())

              } else {


                if (domText) {

                  selection.anchor.offset = 0
                  selection.focus.offset = domText.length

                  selection.deleteLine()
                  selection.insertText("(" + domText + ")")

                } else {
                  selection.insertText("()")
                }

              }
              $wrapNodes(selection, () => $createHeadingNode("h4"));

              selection.focus.offset = selection.focus.offset - 1;
              selection.anchor.offset = selection.anchor.offset - 1;



            }
          }

          if (currClass === "editor-text-parens") {
            console.log(selection.anchor)
            //selection.anchor.offset = selection.anchor.offset - (selection.anchor.offset + 1);
            if (text) {
              if (text[0] === "(" && text[text.length - 1] === ")") {
                text = text.substring(1, text.length - 1)
                selection.removeText()
                selection.insertText(text)

              }
            } else {
              const focusNode = selection.focus.getNode();
              const element =
                focusNode.getKey() === "root"
                  ? focusNode
                  : focusNode.getTopLevelElementOrThrow();
              const elementKey = element.getKey();

              const elementDOM = editor.getElementByKey(elementKey);

              text = elementDOM.textContent
              if (text) {

                if (text[0] === "(" && text[text.length - 1] === ")") {
                  text = text.substring(1, text.length - 1)
                  selection.anchor.offset = 0
                  selection.focus.offset = text.length + 2
                  selection.deleteLine()
                  selection.insertText(text)
                }


              }
            }
          }



          $wrapNodes(selection, () => $createHeadingNode(event.shiftKey ? tabShiftMap[currClass] : tabMap[currClass]));

          if (event.shiftKey) {
            if (currClass === "editor-text-dialogue") {
              selection.focus.offset = selection.focus.offset - 1;
              selection.anchor.offset = selection.anchor.offset - 1;

            }


          } else {
            if (currClass === "editor-text-character") {
              selection.focus.offset = selection.focus.offset - 1;
              selection.anchor.offset = selection.anchor.offset - 1;

            }
          }

        })

        event.preventDefault()

        return true
      },
      COMMAND_PRIORITY_EDITOR
    )
  })

  return null
}
