import { observer, inject } from 'mobx-react'

import React, { useEffect } from "react";

import { $getSelection, $createTextNode, $createParagraphNode, INSERT_PARAGRAPH_COMMAND, } from 'lexical';

import ExampleTheme from "../Lexical/themes/NotesTheme";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import ToolbarPlugin from "../Lexical/plugins/NotesToolbarPlugin";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ListItemNode, ListNode } from "@lexical/list";
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import { TRANSFORMERS } from "@lexical/markdown";

import ListMaxIndentLevelPlugin from "../Lexical/plugins/ListMaxIndentLevelPlugin";
import CodeHighlightPlugin from "../Lexical/plugins/CodeHighlightPlugin";
import AutoLinkPlugin from "../Lexical/plugins/AutoLinkPlugin";

import { StickyNode } from "../Lexical/nodes/StickyNode";

import CollapsiblePlugin from "../Lexical/plugins/CollapsiblePlugin";

import { CollapsibleContainerNode } from "../Lexical/nodes/CollapsibleContainerNode";
import { CollapsibleContentNode } from "../Lexical/nodes/CollapsibleContentNode";
import { CollapsibleTitleNode } from "../Lexical/nodes/CollapsibleTitleNode";

import DraggableBlockPlugin from '../Lexical/plugins/DraggableBlockPlugin';

import YouTubePlugin from '../Lexical/plugins/YouTubePlugin';
import { YouTubeNode } from '../Lexical/nodes/YouTubeNode';

import CommentPlugin from '../Lexical/plugins/CommentPlugin';
import FloatingTextFormatToolbarPlugin from '../Lexical/plugins/FloatingTextFormatToolbarPlugin';

import { MarkNode } from '@lexical/mark';

import { TabIndentationPlugin } from "../Lexical/plugins/TabIndentationPlugin";


import ActionsPlugin from "../Lexical/plugins/ActionsPlugin";
import SpeechToTextPlugin from "../Lexical/plugins/SpeechToTextPlugin";


import { ImageNode } from "../Lexical/nodes/ImageNode";
import ImagesPlugin from '../Lexical/plugins/ImagesPlugin';


import { $generateHtmlFromNodes, } from '@lexical/html';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { $getRoot } from 'lexical';


import SetEditorPlugin from "../Lexical/plugins/SetEditorPlugin";

import { MentionNode } from '../Lexical/nodes/MentionNode.js';


function Placeholder() {
    //return <div className="notes-placeholder">Add script notes, random ideas, character sketches, video clips ...</div>;
    return <div className="notes-placeholder">Add script notes, random ideas, character sketches ...</div>;
}

const ComponentStyles = () => {
    useEffect(() => {
      const editorInput = document.querySelector('.editor-input');
  
      const handleScroll = () => {
        editorInput.classList.add('scrolling');
        clearTimeout(editorInput.scrollEndTimeout);
        editorInput.scrollEndTimeout = setTimeout(() => {
          editorInput.classList.remove('scrolling');
        }, 200); // Adjust the timeout value as desired (in milliseconds)
      };
  
      editorInput.addEventListener('scroll', handleScroll);
  
      return () => {
        clearTimeout(editorInput.scrollEndTimeout);
        editorInput.removeEventListener('scroll', handleScroll);
      };
    }, []);
  
    return (
      <style jsx global>{`
        .editor-input::-webkit-scrollbar {
          width: 7px;
          max-height: 3px;
        }
  
        .editor-input::-webkit-scrollbar:hover {
          width: 10px;
          max-height: 3px;
        }
  
        .editor-input::-webkit-scrollbar-thumb {
          background-color: #b8b8b8;
          border-radius: 35px;
          max-height: 3px;
          height: 3px;
          display: none; 
        }
  
        .editor-input::-webkit-scrollbar-thumb:hover {
          display: block;
          width: 15px;
        }
  
        .editor-input.scrolling::-webkit-scrollbar-thumb {
          display: block;
        }
  
       
      `}</style>
    );
  };


@inject('store')
@observer
class LexicalNotes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            floatingAnchorElem: null,
        };
        this.onChange = this.onChange.bind(this);
    }


    editorConfig = {

        // The initial state of the editor
        editorState: this.props.store.initialNotesState,

        // The editor theme
        theme: ExampleTheme,
        // Handling of errors during update
        onError(error) {
            throw error;
        },
        // Any custom nodes go here
        nodes: [
            HeadingNode,
            ListNode,
            ListItemNode,
            QuoteNode,
            CodeNode,
            CodeHighlightNode,
            TableNode,
            TableCellNode,
            TableRowNode,
            AutoLinkNode,
            LinkNode,
            StickyNode,
            CollapsibleContainerNode,
            CollapsibleContentNode,
            CollapsibleTitleNode,
            YouTubeNode,
            MarkNode,
            MentionNode,
            ImageNode,
        ]
    };


    componentDidMount() {
        this.interval = setInterval(this.saveNotesToDatabase, 2500);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    saveNotesToDatabase = async () => {
        if (this.props.store.notesText !== null) {


            const notes = JSON.stringify(this.props.store.notesJSON);

            if (notes === this.props.store.lastNotesDatabaseUpdate) {
                return;
            }

            console.log("saving notes to database")

            try {
                await this.props.store.api.post('/user/notes/update', { notes: notes, documentId: window.location.href.split('/').pop() });
                this.props.store.lastNotesDatabaseUpdate = notes
            } catch (err) {
                console.log(err);
                console.log("failed to save notes to database");
            }

        }
    };

    onRef = (_floatingAnchorElem) => {
        if (_floatingAnchorElem !== null) {
            this.setState({ floatingAnchorElem: _floatingAnchorElem });
        }
    };


    /*  handleClick(editor, selection) {
         editor.update(() => {
         editor.focus()
         })
       } */


    onChange(editorState, editor) {
        editor.update(() => {
            const rawHTML = $generateHtmlFromNodes(editor, null)
            const editorStateTextString = editorState.read(() => $getRoot().getTextContent());

            // store json
            this.props.store.notesJSON = editorState
            this.props.store.initialNotesState = editorState

            // store html
            this.props.store.notesHTML = rawHTML

            // store text
            this.props.store.notesText = editorStateTextString

            let selection = $getSelection();

            if (selection) {
                let focusNode = selection.focus.getNode();
                let element =
                    focusNode.getKey() === "root"
                        ? focusNode
                        : focusNode.getTopLevelElementOrThrow();
                let elementKey = element.getKey();

                const elementDOM = editor.getElementByKey(elementKey);

                const editorElem = document.querySelector('.editor-inner');

                if (this.props.store.notesInsertText) {

                    try {

                        if (!this.props.store.switchedForInsert) {


                            const text = elementDOM.textContent
                            if (text) {
                                selection.anchor.offset = text.length
                                selection.focus.offset = text.length
                                editor.dispatchCommand(INSERT_PARAGRAPH_COMMAND)
                                //editor.focus()
                                selection = $getSelection();
                                focusNode = selection.focus.getNode();
                            }


                            let textNode;
                            let paragraphNode;


                            for (let line of this.props.store.notesInsertText.split('\n')) {

                                textNode = $createTextNode(line);
                                paragraphNode = $createParagraphNode();

                                paragraphNode.append(textNode);

                                focusNode.insertBefore(paragraphNode);




                            }
                            this.props.store.notesInsertText = null;

                        } else {


                            //editorElem.click();
                            elementDOM.addEventListener('click', () => {

                                editor.update(() => {

                                    selection = $getSelection();
                                    if (selection && this.props.store.notesInsertText) {

                                        const text = elementDOM.textContent
                                        if (text) {
                                            focusNode.select()
                                            selection.anchor.offset = text.length
                                            selection.focus.offset = text.length
                                            editor.dispatchCommand(INSERT_PARAGRAPH_COMMAND)
                                            //editor.focus()
                                            selection = $getSelection();
                                            focusNode = selection.focus.getNode();
                                        } 

                                        let textNode;
                                        let paragraphNode;


                                        for (let line of this.props.store.notesInsertText.split('\n')) {

                                            textNode = $createTextNode(line);
                                            paragraphNode = $createParagraphNode();

                                            paragraphNode.append(textNode);

                                            focusNode.insertBefore(paragraphNode);

                                        }
                                        this.props.store.notesInsertText = null;
                                        this.props.store.switchedForInsert = false;

                                    }
                                });

                            })

                             if (this.props.store.notesInsertText) {

                                if (editorElem) {
                                    //editorElem.addEventListener('click', this.handleClick);

                                    editorElem.addEventListener('click', () => {

                                        editor.update(() => {
                                            selection = $getSelection();
                                            if (selection && this.props.store.notesInsertText) {
                                                
                                                const text = elementDOM.textContent
                                                if (text) {
                                                    focusNode.select()
                                                    selection.anchor.offset = text.length
                                                    selection.focus.offset = text.length
                                                    editor.dispatchCommand(INSERT_PARAGRAPH_COMMAND)
                                                    //editor.focus()
                                                    selection = $getSelection();
                                                    focusNode = selection.focus.getNode();
                                                } 

                                                let textNode;
                                                let paragraphNode;

                                                if (focusNode) (
                                                    console.log(focusNode)
                                                )

                                                for (let line of this.props.store.notesInsertText.split('\n')) {

                                                    textNode = $createTextNode(line);
                                                    paragraphNode = $createParagraphNode();

                                                    paragraphNode.append(textNode);

                                                    focusNode.insertBefore(paragraphNode);

                                                }
                                                this.props.store.notesInsertText = null;
                                                this.props.store.switchedForInsert = false;



                                            }
                                        });

                                    })
                                }

                            } else {


                                  if (editorElem) {
                                     editorElem.removeEventListener('click', this.handleClick);
                                 } 
                            } 

                        }

                    } catch (err) {
                        console.log(err)
                    }

                }
            }
        });
    }

    render() {
        const { floatingAnchorElem } = this.state;

        return (
            <LexicalComposer initialConfig={this.editorConfig}>
                <div className="editor-container">
                    <ToolbarPlugin />

                    <ComponentStyles/> 
                    <div className="editor-inner">
                        <RichTextPlugin
                            contentEditable={<ContentEditable className="editor-input" />}
                            placeholder={<Placeholder />}
                            ErrorBoundary={LexicalErrorBoundary}
                        />
                        <HistoryPlugin />
                        <AutoFocusPlugin />
                        <CodeHighlightPlugin />
                        <ListPlugin />
                        <LinkPlugin />
                        <AutoLinkPlugin />
                        <ListMaxIndentLevelPlugin maxDepth={7} />
                        <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
                        {/* <DraggableBlockPlugin /> */}
                        <YouTubePlugin />
                        <TabIndentationPlugin />
                        <SetEditorPlugin setEditor={this.props.setEditor} />
                        <OnChangePlugin onChange={this.onChange} />
                        <ActionsPlugin />
                        <SpeechToTextPlugin />
                        <ImagesPlugin />
                        {/* <MentionsPlugin /> */}


                        {/*
            <CommentPlugin
            
            providerFactory={undefined}
          />
          */}

                        <FloatingTextFormatToolbarPlugin floatingAnchorElem={floatingAnchorElem} />
                    </div>
                <CollapsiblePlugin />
                </div>
            </LexicalComposer>
        );
    }
}

export default LexicalNotes;