import "../css/audioElement.css";


import React, { useEffect } from "react";
import { observer, inject } from 'mobx-react'

import { NavLink } from 'react-router-dom'


import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Tooltip from '@mui/material/Tooltip';

import screenplay from '../Components/Assets/screenplay.png'
import acting from '../Components/Assets/conflict.png'


import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Padding } from "../../node_modules/@mui/icons-material/index";

import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';




function LinearProgressWithLabel(props) {
  return (
    <Box sx={{  width: '100%', justifyContent: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 50 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
  
}


const DrawerMenuNovelAdapter = inject('store')(({
  store,
}) => {


  const [state, setState] = React.useState({
    left: false
  });

  const [currentAdaption, setCurrentAdaption] = React.useState(window.location.href.split('/').pop())
  const [adaptationData, setAdaptationData] = React.useState([])
  const [checkedForAdaptations, setCheckedForAdaptations] = React.useState(false)


  useEffect(() => {
    getAdaptationHistory()

  }, [])

  const toggleDrawer = (anchor, open) => (event) => {
    getAdaptationHistory()
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };


  const getAdaptationHistory = async () => {
    setCheckedForAdaptations(true)
    let response = await store.api.post('/user/adaptation/viewCreated')
    if (response.data.length > 0) {
      const adaptations = [...response.data]
      const adaptationData = adaptations
        .map(({adaptationId, title, completedChunks, totalChunks, created }) => {

          const percent = Math.round((completedChunks / totalChunks) * 100);

          const dateObj = new Date(created);

          const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
          const day = ("0" + dateObj.getDate()).slice(-2);
          const year = dateObj.getFullYear().toString().slice(-2);
          let hours = dateObj.getHours();
          const minutes = ("0" + dateObj.getMinutes()).slice(-2);
          let ampm = "am";

          if (hours > 12) {
            hours -= 12;
            ampm = "pm";
          } else if (hours === 12) {
            ampm = "pm";
          } else if (hours === 0) {
            hours = 12;
          }

          const formattedDate = `${month}/${day}/${year}`;
          const formattedTime = `${hours}:${minutes}${ampm}`;
        
          return {
            adaptationId,
            title,
            percent,
            created: `[${formattedDate}, ${formattedTime}]`
          };
        });




      setAdaptationData(adaptationData)


    }

  }



  const LinearWithValueLabel = ({ progress }) => {

		return (
			<Box sx={{ width: '100%' }}>
				
				<LinearProgressWithLabel value={progress > 1 ? progress : 1} />
			</Box>
		);
	}


  const list = (anchor) => (
    <Box
      role="presentation"
      onKeyDown={() => toggleDrawer(anchor, false)}

    >
      <div 
      style={{ fontFamily: 'Coal' }}
      className="px-auto text-center text-[21px] text-indigo-800   mt-2 mb-3">Adaptations</div>
      <div id="audio-container"></div>
      {adaptationData.length > 0 ?
      
        <List>
          {adaptationData.map((data, index) => (

            data.adaptationId !== currentAdaption ?
            <React.Fragment key={data.adaptationId}>
               <NavLink to={`/ai/adapt/${data.adaptationId}`} 
               onClick={() => {setCurrentAdaption(data.adaptationId); store.currentAdaptationId = data.adaptationId}}
               >
              <ListItem className="text-black mr-2 hover:bg-indigo-50">



                  <ListItem style={{ backgroundColor: 'transparent', paddingLeft: '0px', paddingRight: '6px', marginBottom: "0px", paddingBottom: "0px" }}>
                    <ListItemText secondary={<span><span className="text-gray-600">{data.created}</span> <br></br> <span className="text-gray-900">{data.title} {' ...'}</span></span>} />
                  </ListItem>

                  <div>
                  <LinearWithValueLabel progress={data.percent} />
                  </div>
                 
                </ListItem>
                </NavLink>
               
                 
                
                <Divider style={{ marginBottom: "0px" }} />

            </React.Fragment>
            :
            null

          ))}


        </List>
        :

        checkedForAdaptations && adaptationData.length == 0 ?

          <List>


            <ListItem key={0} index={0} className="text-slate-700  mr-1">

              <ListItemText primary={"Looks like you haven't generated any adaptations yet."} />

            </ListItem>
            



          </List>
          :
          null

      }



    </Box>
  );



  return (
    <div >
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          <div className="container  flex select-none"></div>
          <div
            onClick={toggleDrawer(anchor, true)}
            activeClassName="bg-gray-100 hover:bg-gray-200 text-gray-800 transition"
            className="drawer-button border active:shadow-inner active:shadow-indigo-300 active:border-none bg-white border rounded-bl-3xl xl:w-46  drop-shadow shadow-sm hover:shadow-md hover:border-t-white hover:shadow-indigo-500 hover:border hover:border-rose-400 shadow-indigo-600 text-md flex flex-col py-1.5 lg:py-1 px-2  lg:px-3 xl:px-4 cursor-pointer font-medium transition items-center"
          >
            < img className="brightness-100 mt-0.5" src={screenplay} style={{ height: 47, width: 50 }}></img>
            <div style={{fontFamily: "coal"}} className="text-center text-slate-600 mt-1 text-[13px] xl:text-[13.9px] 2xl:text-[14.2px]">Previous Adaptations</div>
            <div />
            <div className="hidden lg:block"></div>
          </div>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >

            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
)

export default DrawerMenuNovelAdapter;