import * as React from 'react';
import { styled, keyframes, css } from '@mui/system';
import Snackbar from '@mui/base/Snackbar';


const blue = {
  50: '#F0F7FF',
  400: '#3399FF',
  600: '#0072E5',
  900: '#003A75',
};

const grey = {
  200: '#E0E3E7',
};

const snackbarInRight = keyframes`
  from {
    transform: translateY(75%);
  }

  to {
    transform: translateX(0);
  }
`;

const StyledSnackbar = styled(Snackbar)(
  ({ theme }) => css`
    position: fixed;
    z-index: 5500;
    opacity: .9;
    display: flex;
    right: -40px;
    bottom: -65px;
    left: auto;
    justify-content: start;
    max-width: 460px;
    min-width: 140px;
    background-color: ${theme.palette.mode === 'dark' ? blue[900] : blue[50]};
    border-radius: 8px;
    border: 1.3px solid ${theme.palette.mode === 'dark' ? blue[700] : blue[500]};
    box-shadow: ${theme.palette.mode === 'dark'
      ? `0 5px 13px -3px rgba(0,0,0,0.4)`
      : `0 5px 13px -3px ${grey[200]}`};
    padding: 0.5rem;
    color: ${theme.palette.mode === 'dark' ? '#fff' : blue[900]};
    font-size: 15px;
    font-family: IBM Plex Sans, sans-serif;
    font-weight: 500;
    animation: ${snackbarInRight} 400ms;
    transition: transform 0.1s ease-out;
  `,
);

export default StyledSnackbar