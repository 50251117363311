import feedback from './feedback';
import convertChapter from './convertChapter';
import openingScene from './openingScene';
import generateScene from './generateScene';
import outline from './outline';
import beatsheet from './beatsheet';
//import feedbackGeneral from './feedbackGeneral';
import notes from './notes';
import characterArc from './characterArc';
import rewrite from './rewrite';
import rewriteLine from './rewriteLine';
import autowrite from './autowrite';




const TOOLS = [

	openingScene,
	generateScene,
	convertChapter,
	outline,
	beatsheet,
	feedback,
	//feedbackGeneral,
	notes,
	characterArc,
	rewrite,
	rewriteLine,

]

export default TOOLS
