import React, { Component } from 'react';
import { observer, inject, } from 'mobx-react'
import { reaction } from 'mobx';

import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';

import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';

import DownloadIcon from '@mui/icons-material/Download';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import Loader from './Loader'


@inject('store')
@observer
class ImageListTitleBar extends Component {

  constructor(props) {
    super(props);
    this.state = {
      itemData: ["empty"],
    };
    this.mounted = false;

    // Initialize a MobX reaction to listen for changes to isImageLoading
    this.imageLoadingReaction = reaction(
      () => this.props.store.isImageLoading,
      isLoading => {
        if (!isLoading) {
          this.refreshImages();
        }
      }
    );
  };

  componentDidMount() {
    this.mounted = true;
    this.init();
  }

  componentWillUnmount() {
    this.mounted = false;
    this.imageLoadingReaction();
  }


  init = async () => {
    this.refreshImages()
  }






  refreshImages = async () => {
    let response = await this.props.store.api.post('/user/images/view', { documentId: window.location.href.split('/').pop() })
    if (response.data.length > 0) {
      //console.log( response.data)
      const imageData = [...response.data]
      const itemData = imageData.map(({ imageURL, prompt, imageType, deleted }) => ({ imageURL, prompt, imageType, deleted }))

      //console.log(itemData)

      this.setState({ itemData });



    } else {
      this.setState({ itemData: [] });
    }

  }

  handleDownload = (imageUrl) => {
    // Use the fetch API to download the image and create a blob URL for it
    fetch(imageUrl)
      .then(response => response.blob())
      .then(blob => {
        const url = URL.createObjectURL(blob);

        // Create a new anchor element with the blob URL as the href attribute
        const link = document.createElement('a');
        link.href = url;
        link.download = 'next_scene_image.jpg';
        link.click();

        // Release the blob URL when the download is complete
        URL.revokeObjectURL(url);
      });
  };

  handleDeleteImage = async (imageURL) => {

    //console.log(String(imageURL))
    await this.props.store.api.post('/user/images/delete', { imageURL: String(imageURL) });
    this.refreshImages();

  }


  actions = [
    { icon: <DownloadIcon />, name: 'Download', onClick: this.handleDownload },
    { icon: <DeleteForeverIcon />, name: 'Delete', onClick: this.handleDeleteImage },
  ];


  render() {

    const { itemData } = this.state;
    //console.log(itemData)

    return (
      <div>
        {

          itemData[0] === "empty" ? null

            :

            itemData.length < 1 && !this.props.store.isImageLoading ?

              <div className="pl-14 text-slate-800"><div className="pt-0">You haven't generated any images yet</div> <br></br> <div>- Use the menu that pops up when you select text in your Script for generating</div> <br></br> <div>- A sentence or two works best (min of 3 words, max of 50) </div> <br></br> <div>- Specificity helps:</div> <div>&nbsp;&nbsp;&nbsp;Instead of "Jack walks into the room," try "A young man with brown hair walks into the room"</div>
               <br></br> <div> Here's an example of "A mans looks out at the neon signage" using the <em>medium shot</em> option:</div>
               <br></br>
               <img 
               src={`https://replicate.delivery/pbxt/VrMMj9XVImLgJ1F1uljkwfS1C3b6sSp1zFTyS5QePhUZnN5QA/out_0.png?w=512&fit=crop&auto=format&dpr=2`}
               className="rounded-lg h-96 w-96 ml-20"
               >
               </img>
               </div>

              :

              <ImageList sx={{ height: itemData.length > 2 ? '80vh' : '512px', maxWidth: '1024px' }}>
                {this.props.store.isImageLoading ? <div className="flex justify-center mt-14">
                  <Loader active={this.props.store.isImageLoading} className="w-10 h-10" />
                </div> : <>

                </>}

                {this.props.store.imageError ? <div className="ml-2 mt-4">
                  <div>Sorry, it looks like something went wrong.</div>
                  <br></br>
                  <div>Try generating again.</div>

                </div> : <>

                </>}

                {itemData.map((item) => (
                  !item.deleted ?
                    <ImageListItem key={item.imageURL} sx={{ marginLeft: "4px", marginBottom: "4px", ':hover': { '& .MuiImageListItemBar-root': { opacity: 1 } } }}>
                      <img
                        src={`${item.imageURL}?w=512&h=512&fit=crop&auto=format`}
                        srcSet={`${item.imageURL}?w=512&fit=crop&auto=format&dpr=2`}
                        alt={item.prompt}
                        loading="lazy"
                        className="rounded-lg"
                      />
                      <ImageListItemBar
                        className="rounded-b-lg"
                        subtitle={item.imageType ? `[${item.imageType}] ` + item.prompt.toLowerCase() : item.prompt.toLowerCase()}
                        actionIcon={
                          <SpeedDial
                            ariaLabel="SpeedDial"
                            sx={{ position: 'absolute', bottom: 46, right: 10, '& .MuiFab-primary': { backgroundColor: 'rgba(240, 244, 250,.8)', color: 'rgba(10, 10, 10,.8)', width: 40, height: 40, '& .MuiSpeedDialIcon-icon': { fontSize: 25 }, '&:hover': { backgroundColor: '#afc9f0' } } }}
                            icon={<SpeedDialIcon />}
                          >
                            {this.actions.map((action) => (
                              <SpeedDialAction
                                key={action.name}
                                icon={action.icon}
                                tooltipTitle={action.name}
                                onClick={() => action.onClick(item.imageURL)}
                              />
                            ))}
                          </SpeedDial>
                        }
                        sx={{ opacity: 0, transition: 'opacity 0.2s' }}
                      />
                    </ImageListItem>
                    : null
                ))}

              </ImageList>
        }
      </div>
    );



  }
}


/* const itemData = [
  {
    img: 'https://replicate.delivery/pbxt/0RFIN8YBxj7UPpwlLaEu7bVMdKU9QYSrqd28OVgw5LtANTNE/out_0.png',
    text: 'a beautiful portrait of a screenplay being written with a blue typewriter, trending on Artstation | 50: a beautiful portrait of movie scenes coming to life, trending on Artstation '
  },
  {
    img: 'https://replicate.delivery/pbxt/8yVRG05IbubPMRIUfhdPCOnSIwiYTeLrFX0k6H8SjJEPnM1QA/out-0.png',
    text: 'TROY MASON, '
  },
  {
    img: 'https://replicate.delivery/pbxt/0RFIN8YBxj7UPpwlLaEu7bVMdKU9QYSrqd28OVgw5LtANTNE/out_0.png',
    text: 'a beautiful portrait of a screenplay being written with a blue typewriter, trending on Artstation | 50: a beautiful portrait of movie scenes coming to life, trending on Artstation '
  },
  {
    img: 'https://replicate.delivery/pbxt/8yVRG05IbubPMRIUfhdPCOnSIwiYTeLrFX0k6H8SjJEPnM1QA/out-0.png',
    text: 'TROY MASON, '
  },
  
 
];
 */


export default ImageListTitleBar;