import { ThemeProvider } from 'styled-components'
import React, { Component } from 'react';

import { Provider  } from 'mobx-react'
import { observer,  } from 'mobx-react'

import AppStore from './store'
import colors from 'tailwindcss/colors' 
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
} from "react-router-dom";


// 1. import `NextUIProvider` component
import { NextUIProvider } from '@nextui-org/react';


import Header from './Header'
import Search from './Search'

import Dashboard from './Dashboard'


import Tool from './Core/Tool'
import Chat from './Core/Chat'

import Login from './Login/Login'

import Profile from './Profile/'
import LoginSuccess from './Login/Success'

import Home from './LandingPage/pages/Home';

import ConvertBook from './ConvertBook';


import 'aos/dist/aos.css';


import AOS from 'aos';


import './App.scss'
import './css/scrollbar.css';

if(!window.store){
  window.store = new AppStore();
}


@observer
class App extends Component {
  constructor(props) {
    super(props);
    this.location = window.location;
    };
  
  

  componentDidMount() {
    AOS.init({
      once: true,
      disable: 'phone',
      duration: 700,
      easing: 'ease-out-cubic',
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.location && this.props.location.pathname) {
      if (this.props.location.pathname !== prevProps.location.pathname) {
        document.querySelector('html').style.scrollBehavior = 'auto'
        window.scroll({ top: 0 })
        document.querySelector('html').style.scrollBehavior = ''
      }
    }
  }

  render() {
    return (
    <NextUIProvider>
    <ThemeProvider theme={colors}>
        <Provider store={window.store}>
          <Router>
           {window.store.redirect ? <Redirect to={window.store.redirect} /> : null }
            {window.store.isLoggedIn ? <>
            {window.store.profile.status ? <>  {/*  Logged in with plan */}
                <Switch>
                    <Route path="/writing/document"><div/></Route>
                    <Route component={Header} />
                </Switch>
               
                <Switch>
                      
                      <Route path="/" exact component={Dashboard} />

                      <Route path="/ai/" >
                        <Switch>

                          <Route path="/ai/code/debugging" component={Chat} />
                          <Route path="/ai/adapt" component={ConvertBook} />
                          <Route component={Tool} />

                        </Switch>
                      </Route>
                      <Route path="/my-profile" component={Profile} />
                      <Route path="/signup/failed" component={Profile} />
                      <Route path="/signup/success" component={LoginSuccess} />
                      <Route path="/signup/success" component={LoginSuccess} />
                      <Route path="/landing" component={Home} />
                    
                  </Switch>
                </> : <> {/* Logged in but no plan */}
               
                </>} </> : <> {/*  Not Logged In */}
                <Switch>
  <Route exact path="/" component={Home} />
  <Route path="/login" component={Login} />
  <Route path="/signup" component={Login} />
  <Redirect to="/" />
</Switch>

            </>}
           </Router>
        </Provider>
      </ThemeProvider>
      </NextUIProvider>
    )
  }
}

export default App