import * as React from "react";
import { observer, inject } from 'mobx-react'

import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeIcon from "@mui/icons-material/Home";
import KeyboardIcon from '@mui/icons-material/Keyboard';
import MapIcon from '@mui/icons-material/Map';


import { NavLink } from 'react-router-dom'
import typewriter from '../Components/Assets/logo-typewriter-frontpage3.png'







const DrawerMenu = inject('store')(({
    store,
  }) => {


  const [state, setState] = React.useState({
    left: false
  });

  const [openShortcuts, setOpenShortcuts] = React.useState(false);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      role="presentation"
      onKeyDown={toggleDrawer(anchor, false)}
     
    >
      <List>
        {["Home"].map((text, index) => (
         <NavLink to="/"
         exact
         tabIndex={-1}
         onClick={() => store.toolsKeyword = ""}
         >
          <ListItem key={text} disablePadding className="hover:bg-indigo-50 text-black  mr-2">
            
            <ListItemButton
            style={{ backgroundColor: 'transparent' }}
            >
              <ListItemIcon>
                
               
                <HomeIcon className="text-blue-600" />
                
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
            
          </ListItem>
          </NavLink> 
        ))}
        <ListItem key={"Features Tour"} disablePadding className="hover:bg-indigo-50  mr-2"
        
        onClick={() => {store.runFeaturesTour = true; toggleDrawer(anchor, false)}}
        >
            
            <ListItemButton
            style={{ backgroundColor: 'transparent' }}
            
            >
              <ListItemIcon>
                
               
                <MapIcon className="text-green-600" />
                
              </ListItemIcon>
              <ListItemText primary={"Features Tour"} />
            </ListItemButton>
            
          </ListItem>

      </List>
      <Divider />
      <List>
        {[
          "Script Keyboard Shortcuts",
          "⌘ + ctrl : Focus Mode",
          "⌘ + right arrow  :  Autowrite",
          "tab : → cycle through line types" ,
          "tab + shift : ← cycle through line types",
          "⌘ + alt/option : Display line shortcuts",
          "⌘ + 1  :  Scene Heading",
          "⌘ + 2  :  Action",
          "⌘ + 3  :  Character",
          "⌘ + 4  :  Parenthetical",
          "⌘ + 5  :  Dialogue",
          "⌘ + 6  :  Transition",

        ].map((text, index) => (
            <div>
            {text === "Script Keyboard Shortcuts" ? 
          <ListItem key={text} disablePadding className="hover:bg-indigo-50 mr-2">
          
           <ListItemButton 
           style={{ backgroundColor: 'transparent' }}
           disableRipple = {true}
           onClick = {() => !openShortcuts ? setOpenShortcuts(true) : setOpenShortcuts(false)} 
            >
            

           <ListItemIcon>
                < KeyboardIcon className="text-gray-700" />
            </ListItemIcon>
            
            <ListItemText 
             primary={!openShortcuts ? <span className="text-gray-800"> {text}{"  ▸"} </span>:  <span className="text-gray-800"> {text}{" ▾"}</span>}
              sx={{
            
                textDecoration: text === "underline", 
                textUnderlineOffset: "5px",
                textDecorationThickness: "1px"  
              }}
            
            />
            </ListItemButton>
            </ListItem>
            :  openShortcuts ? 
            <ListItem 
            key={text}
            >
            <ListItemText 
             secondary={<span className="text-slate-800 ml-14">{text}</span>}
            
            />
            </ListItem>
        
            : null}

            </div>
          
        ))}
      </List>
    </Box>
  );

  return (
    <div >
      {["left"].map((anchor) => (
        <React.Fragment key={anchor}>
        <div className="container flex px-0 md:px-0 flex select-none"></div>
        <div
        onClick={toggleDrawer(anchor, true)}
        activeClassName="bg-gray-100 hover:bg-gray-200 text-gray-800 transition"
        className="drawer-button active:shadow-inner active:shadow-slate-400 bg-indigo-50 border  drop-shadow  rounded-br-2xl shadow-md shadow-gray-400 hover:drop-shadow hover:shadow-gray-600 text-md flex py-2 px-2 lg:py-2 lg:px-2.5 xl:px-3 2xl:px-4 cursor-pointer hover:bg-indigo-100  font-medium transition items-center"
        >
        < img className="brightness-[1.10]" src={typewriter} style={{ height: 30, width: 47 }}></img>
        <div/>
        <div className="hidden lg:block"></div>
        </div>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
)

export default DrawerMenu;