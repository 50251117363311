import React, { Component } from "react";
import { observer, inject } from 'mobx-react'
import { computed } from 'mobx'

import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import DescriptionIcon from '@mui/icons-material/Description';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import SummarizeIcon from '@mui/icons-material/Summarize';
import NotesIcon from '@mui/icons-material/Notes';
import WorkIcon from '@mui/icons-material/Work';
import SaveIcon from '@mui/icons-material/Save';
import GradingIcon from '@mui/icons-material/Grading';
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
import CameraRollIcon from '@mui/icons-material/CameraRoll';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';

const docTypesMap = {
  0: 'Script',
  1: 'Notes',
  2: 'Images',
  3: 'Videos',
  4: 'Feedback',
  5: 'Summary',

  /* 4: 'Cards', */
  /* 6: 'Pitch', */
  6: 'Memory',

};

const indexToDocMap = {
  'Script': 0,
  'Notes': 1,
  'Images': 2,
  'Videos': 3,
  'Feedback': 4,
  'Summary': 5,
  /* 'Cards' : 4, */

  /* 'Pitch': 6, */
  'Memory': 6,

};


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}

    >
      {value === index && (
        <Box sx={{
          p: 0,

        }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};


@inject('store')
@observer
class VerticalTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isScreenWidth1380OrLess: window.innerWidth <= 1380,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleResize = this.handleResize.bind(this);
  }


  @computed get currValue() {
    return indexToDocMap[this.props.store.selectedDocType];
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize() {
    this.setState({
      isScreenWidth1380OrLess: window.innerWidth <= 1380,
    });
  }

  handleChange(event, newValue) {
    const selectedDocType = docTypesMap[newValue];
    this.props.store.selectedDocType = selectedDocType;
  }

  handleChange(event, newValue) {
    this.setState({
      value: newValue,
    });
    this.props.store.selectedDocType = docTypesMap[newValue];
  }
  render() {
    const { isScreenWidth1380OrLess } = this.state;
    return (

      <div className="flex flex-col justify-center items-center lg:ml-4  md:ml-0">
        <Tabs className='vertical-tabs mt-24 lg:ml-12 2xl:ml-[8vh] 2xl:p-[2vh] md:ml-0 rounded-lg shadow lg:mr-6 md:mr-2 bg-white'
          orientation="vertical"
          value={this.currValue || 0}
          onChange={this.handleChange}
          aria-label="Vertical tabs example"
          sx={{

            '@media (max-width:1370px)': {
              orientation: 'vertical',

              marginLeft: "20px",
              paddingRight: "0px",
              paddingLeft: "0px",
              maxWidth: 90
            },
            '@media (max-width:1330px)': {
              orientation: 'vertical',

              marginLeft: "14px",
            },
            '@media (max-width:1279px)': {
              orientation: 'vertical',
              maxWidth: 120
            },
            '@media (max-width:1165px)': {
              orientation: 'vertical',
              maxWidth: 90,
              marginLeft: "0px",
              marginRight: "0px",
            }
          }}
        >

          <Tab
            sx={{
              fontSize: isScreenWidth1380OrLess ? "13px" : null
            }}
            icon={<DescriptionIcon />} label="Script" />

          <Tab
            sx={{
              fontSize: isScreenWidth1380OrLess ? "13px" : null
            }}
            icon={<StickyNote2Icon />} label="Notes" />

          <Tab
            sx={{
              fontSize: isScreenWidth1380OrLess ? "13px" : null
            }}
            icon={<CameraRollIcon />} label="Images" />

          <Tab
            sx={{
              fontSize: isScreenWidth1380OrLess ? "13px" : null
            }}
            icon={<OndemandVideoIcon/>} label="Videos" />


          <Tab
            sx={{
              fontSize: isScreenWidth1380OrLess ? "13px" : null
            }}
            icon={<GradingIcon />} label="Feedback" />

          <Tab
            sx={{
              fontSize: "13px",
            }}
            icon={<SummarizeIcon />} label="Summary" />


          {/* <Tab icon={<NotesIcon />} label="Outline" /> */}

          {/* <Tooltip title="Coming Soon" placement="right">
      <div className="flex justify-center">
      <Tab
          icon={<SwitchAccountIcon />} 
          label="Cards"
          disabled={true}
        />
      </div>
      </Tooltip> */}

        {/*   <Tooltip title="Coming Soon" placement="right">
            <div className="flex justify-center">
              <Tab
                sx={{
                  fontSize: isScreenWidth1380OrLess ? "13px" : null
                }}
                icon={<WorkIcon />}
                label="Pitch"
                disabled={true}
              />
            </div>
          </Tooltip> */}

          <Tab
            sx={{
              fontSize: isScreenWidth1380OrLess ? "13px" : null
            }}
            icon={<SaveIcon />} label="Memory" />

        </Tabs>
      </div>

    );
  }
}

export default VerticalTabs;