import React, { Component } from 'react';
import styled from 'styled-components'
import { observer, inject } from 'mobx-react'
import { computed, } from 'mobx'
import { NavLink } from 'react-router-dom'
import {
	SwitchHorizontalIcon, ScaleIcon, UserIcon, UserCircleIcon, ReplyIcon,
} from '@heroicons/react/outline'
import {
	DatabaseIcon,
} from '@heroicons/react/solid'

import { IconDashboard, } from './Icons'

import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import { useLocation } from 'react-router-dom'
import Body from './Components/Body'
import { withRouter } from "react-router-dom";
//import typewriter from './Components/Assets/logo-typewriter-frontpage3.png'

/* import logo from './Components/Assets/typewriter-new.png'

const Logo = () => (
	< img src={logo} className="brightness-125" style={{ height: 72, width: 89 }}></img>

) */

function HeaderExpand(props) {
	const location = useLocation();
	
	return  location.pathname === "/" ? <SuperHeader
		active={location.pathname === "/" ? true : false}

	>{props.children}</SuperHeader>

	: null
}
/* function SubHeaderExpand(props) {
	const location = useLocation();
	return <SubHeader
		active={location.pathname.startsWith("/ai/") ? true : false}
	></SubHeader>
} */

@inject('store')
@observer
class SidebarCompontent extends Component {

	constructor(props) {
		super(props);
		if (this.props.location.pathname === "/signup") {
			this.props.history.push('/')
		}
		if (this.props.location.pathname === "/login") {
			this.props.history.push('/')
		}

	}
	componentDidMount() {
		document.addEventListener('keydown', this.shortcutHandler);
	}
	componentWillUnmount() {
		document.removeEventListener('keydown', this.shortcutHandler);
	}
	shortcutHandler = e => {
		if (e.keyCode === 75 && e.ctrlKey) {
			e.preventDefault();
			// select all text in input with id q
			document.getElementById("q").focus();
			document.getElementById("q").select();
		}
	}

	onKeyUp = (e) => {
		if (this.props.location.pathname !== "/search") {
			this.props.history.push('/search')
		}
		if (e.keyCode === 8) {
			if (this.props.store.toolsKeyword === "") {
				this.props.history.push('/')
			}
		}
	}

	@computed get fromColor() {
		if (this.props.store.profile.credits <= 0) {
			return "bg-red-200 text-red-600"
		}
		if (this.props.store.profile.status === "trialing") {
			return ""
		}
		if (this.props.store.profile.status === "active") {
			return ""
		}
		if (this.props.store.profile.status === "incomplete") {
			return ""
		}
		return ""
	}

	render() {
		return (
			<>
				<Textarea readOnly name="copy-textarea" id="copy-textarea" value={this.props.store.copyToClipboardText} />
				<HeaderExpand>
					<Body className="px-4 md:px-3 lg:px-24 xl:px-28   flex items-center flex-1 ">
					{/* <div className="mr-[7px] mb-[47px] rounded-full ">
                           <Logo/> 
                        </div> */}

						<div>
							<div style={{ fontFamily: 'Coal' }} className="brightness-125 text-[42px]  font-light relative text-transparent bg-clip-text bg-gradient-to-l from-blue-800 to-sky-900 inline-block h-12 mt-2 " >Next Scene</div>
							<div className="hidden font-light md:block text-l ml-0.5 text-transparent bg-clip-text bg-black inline-block" >Screenwriting Reimagined</div>

						</div>
						<div className="absolute top-0 right-0 px-6 py-3">
							<NavLink to="/my-profile"
								exact
								tabIndex="-1"
								activeClassName="bg-green-100 hover:bg-green-200 text-green-800 transition"
								className={`border border-neutral-300 mr-4 text-lg bg-neutral-50 flex py-2 px-5 xl:py-2 xl:px-8 cursor-pointer ${this.fromColor} hover:shadow-sm hover:shadow-gray-400 active:shadow-inner rounded-3xl font-light transition items-center`}><AccountCircleIcon style={{ height: "29px", width: "29px" }} className="w-6 h-6 lg:mr-2 transition text-sky-700" />
								<div className="hidden font-medium lg:block text-sky-700"> Account </div>
							</NavLink>
						</div>

					</Body>
				</HeaderExpand>
				{/* <SubHeaderExpand>
				</SubHeaderExpand> */}
				{/* <div className="mt-5">
					<div className="container flex px-0 md:px-0 flex select-none">
						<NavLink to="/"
							exact
							tabIndex={-1}
							onClick={() => this.props.store.toolsKeyword = ""}
							activeClassName="bg-gray-100 hover:bg-gray-200 text-gray-800 transition"
							className="bg-indigo-50 border rounded-r-lg shadow-md text-md flex py-2 px-0 lg:py-2 lg:px-6 cursor-pointer hover:bg-indigo-100  font-medium transition items-center">
							< img src={typewriter} style={{ height: 29, width: 52 }}></img>
							<div className="hidden lg:block"></div>
						</NavLink>



						
						


					</div>


				</div> */}

				{this.props.children}
			</>
		)
	}
}

/* const Logo = () => (
	< img src={typewriter} style={{ height: 42, width: 88 }}></img>

) */


const Input = styled.input`
	
`

const Textarea = styled.textarea`
	position: fixed;
	right:-9990px;
	top:-9990px;
`

// transition:ease-in-out 0.5s margin-top;
// 	transition-delay:0.05s;

const SuperHeader = styled.div`
	height:10vh;
background: #fafafc;
	
	
	background-size:auto 30%;
	background-position: 20px 20px;
	background-repeat:no-repeat;
	position:relative;
	
`
/* const SubHeader = styled.div`
margin-top:${props => props.active ? "0px" : "-90px"};
display:${props => props.hidden ? "hidden" : "flex"};
background: #fafafc;
` */



export default withRouter(SidebarCompontent)