import React, { useState } from 'react';
import { observer, inject } from 'mobx-react'


import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';


import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';


import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';


import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const images = 

[
   
 
    "16nm__8ulwMUfly6tbhTOXVgQ2CkMREzY",
    "1gCGEmDP2FUu5pHT3u2yzQZNWckjbZl4M", "1Uo0Bcr1VuiZTnC5rCQauRuqQC4-zpMrm", "1rbs2JX3srkPd62JYQxa7AInI61IzOfVf", "1_o5TK3o-yJi-O2z1b4cbAIlk9Fy6cQtB", "1R6mpFCDO6AfGoIoTsPMWPTFXDV7DQVvG",
    "1vMF0guEIRAp_R-RUnRPaxQyGeyUrMLgq","1P55EAvODrd2fc5InHvdUz-Ua7mRmkcU3", "1oxuqRZE5OFAuhkVFOTLo5XATtvJQWSk1", "13mMZPyj0ebVNvKBpiOjnYjqID4yWnURc", "1hy3SzdEjxgfshvZgOP3I0d84v5NEODbn",
    "1hlvj7PX0rDNzWp5p8YY-cRvfNo4ECXGT", "1lW-YlB3mClgx3p5hRVV6Qh-xsbH4-ej6", "1osGq4v95DQFVxZL6DqyPblErTUNSkIi9", "1HapgafZtmRhRLQfzqdoY_vSIhwUAenST",
    "1HxeFDkUN-VsW8L4vsyGwl3onm1WV_CDJ", "1akCVHFUNkE9htz9Y-Bui8k6ZWCowYBfq", "1UmcBuYiI5b1dJnKvibm-wnioz7ahNB4F", "18aVQvRR9B7vnLLXpGZlDuk3LqE8uIfex", "1B-5Zj4sj7sz5xCbXviaDvUFg2M4dmDCU",
    "1ih9jaVHj44j2PBsSENdQ6sqOhVdGsGvp", "1GsnHgCwP2pw1pTS-k7Id1ZVmcz7N2f5m", "1X_2tMbs9_90Pb1XKYrmizrwhv7UleeSx", "19WmqL8PSJF6akG4HxkxRNq2J7VMW_usH", "199AdJxeMquaWkItynXjvwbRgWKo09qtJ",
    "1Es-8Dd9UeQPDr5UVjuQgmzAYZKl6BemG", "1bnqKzYQILsY68teGHZYqd_-W5o4ljmub", "1P-dl-Tgw8PJjs20V4DhUxEZikUAUjw_p", "18TxQS8i7POk5hoUQXDQprnV4oyrxRtGp", "1BE-utmQaC-IH78_4iYt10_aP5533MLsw"

]


const voices = require('../Voices/voiceData.js');


const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 30,
    height: 17,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 17,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(12px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(13px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#2b58c4',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 13,
        height: 13,
        borderRadius: 6,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : '#2772b8',
        boxSizing: 'border-box',
    },
}));


export const VoiceList = inject('store')(observer(({ store }) => {


    const [checked, setChecked] = React.useState(false);
    const [traitList, setTraitList] = React.useState(["Male #", "Male (British)"]);

    const handleChange = (event) => {
        setChecked(event.target.checked);
        if (event.target.checked) {
            setTraitList(["Female #", "Female (British)"])

        } else {
            setTraitList(["Male #", "Male (British)"])
        }
    };


    const playSample = (sampleID) => {
        // Perform a POST request to your server endpoint
        fetch(store.baseURL_AI + '/voiceGet', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json', // Replace with the appropriate content type for your request
            },
            body: JSON.stringify({ sampleId: sampleID }),
        })
            .then((response) => response.blob())
            .then((blob) => {
                const audioElement = new Audio(URL.createObjectURL(blob));
                audioElement.play();
            })
            .catch((error) => {
                console.error('Request error:', error);
            });
    };

    const [hoveredItem, setHoveredItem] = useState(null);

    const handleMouseEnter = (qualities) => {
        const tooltipContent = Object.entries(qualities)
            .map(([key, value]) => <div>{key}: <span style={{ color: "#2772b8" }}>{value}</span></div>)

        setHoveredItem(tooltipContent);
    };

    const handleMouseLeave = () => {
        setHoveredItem(null);
    };

    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip arrow placement="left" {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: 'white',
            color: 'rgba(0, 0, 0, 0.87)',
            maxWidth: 220,
            fontSize: theme.typography.pxToRem(14),
            border: '1px solid #dadde9',
        },
    }));

    return (

        <Box className="shadow-lg shadow-indigo-200 rounded-xl mt-2 lg:mt-0 border-2 border-slate-200  border-b-indigo-50 mb-1" sx={{ width: '100%', minWidth: "min-content", maxWidth: "25vw", bgcolor: 'background.paper' }}>


            <List className="bg-white rounded-t-xl border-b border-b-blue-500" >



                <ListItem>

                    <ListItemIcon>
                        <RecordVoiceOverIcon className="text-blue-700" />
                    </ListItemIcon>
                    <ListItemText
                        primary={
                            <span>
                                {"Explore Voice "} 
                                <span style={{ fontFamily: 'Coal', color: "#3645c9", fontSize: "15.5px" }} >
                                    
                                      Samples
                                </span> 

                            </span>
                        }
                    />

                </ListItem>
                <div className='flex ml-1 sm:ml-4 md:ml-6 lg:ml-0 lg:justify-center pb-2'>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Typography className="text-gray-700" sx={{ fontSize: '15px', }}>Male Voices</Typography>
                        <AntSwitch checked={checked}
                            onChange={handleChange} inputProps={{ 'aria-label': 'ant design' }} />
                        <Typography className="text-gray-700 " sx={{ fontSize: '15px', }}>Female Voices</Typography>
                    </Stack>
                </div>



            </List>


            {traitList.map((trait) => (
                <>
                    <Divider />
                    <List>
                        {voices.map((voice,index) => (
                            <>
                                {voice.name.includes(trait) ?
                                    <HtmlTooltip
                                        title={<React.Fragment>{hoveredItem}</React.Fragment>}

                                    >
                                        <div

                                            onMouseEnter={() => handleMouseEnter(voice.qualities)}
                                            onMouseLeave={handleMouseLeave}
                                        >
                                            <div onClick={() => playSample(voice.samples[0])}>

                                                <ListItem key={voice.voice_id} button>

                                                <img className={`${[5].includes(index) ? "brightness-150" : [1].includes(index) ? "brightness-[1.17]": [22,24,25].includes(index) ? "brightness-[1.10]" : [9,10,11,14 ].includes(index) ? "brightness-[1.05]" :  "brightness-100"} rounded-full mr-3 shadow-sm shadow-slate-400`} src={"https://drive.google.com/uc?id=" + images[index]} style={{ height: 57, width: 57 }}></img>

                                                    <ListItemText primary={voice.name} />
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            cursor: 'pointer',
                                                        }}

                                                    >
                                                        <PlayCircleOutlineIcon className="text-blue-900 ml-1" />
                                                    </div>



                                                </ListItem> </div>
                                        </div>
                                    </HtmlTooltip> : null

                                }
                            </>
                        ))}
                    </List>

                </>
            ))}

        </Box >
    );
}));

export default VoiceList;